import {Staff} from 'types/staffs';

export function getSingleName(staff: Staff) {
    if (!staff) {
        return '';
    }

    if (staff.nickname) {
        return staff.nickname;
    }
    return staff.name;
}

export function getUnionSingleName(staffs: Staff[]) {
    if (!staffs || staffs.length === 0) {
        return '';
    }

    let usernames = '';
    staffs.forEach((staff) => {
        if (usernames !== '') {
            usernames += '、';
        }
        usernames += getSingleName(staff);
    });

    return usernames;
}

export function getFullname(staff: Staff) {
    if (!staff) {
        return '';
    }
    let fullname;
    if (staff.nickname) {
        fullname = staff.nickname + '(' + staff.name + ')';
    } else {
        fullname = staff.name;
    }

    return fullname;
}
