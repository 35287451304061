import React, {useCallback, useState} from 'react';
import {ColorChangeHandler, TwitterPicker} from 'react-color';
import {Popover} from 'antd';

import Icon from 'components/icon';
import {BASE_COLOR} from 'utils/constants/custom_field';

type Props = {
    shape?: string
    color: string
    size?: number
    disabled?: boolean
    onChange?: (color: string) => void
};
const ColorSelect: React.FC<Props> = ({
    shape = '',
    color,
    size = 16,
    disabled,
    onChange
}) => {
    const [visible, setVisible] = useState(false);

    const onColorChange: ColorChangeHandler = useCallback((color) => {
        onChange?.(color.hex);
        setVisible(false);
    }, [onChange]);

    const renderIndicator = () => {
        if (shape) {
            return (
                <Icon
                    type={shape}
                    color={color}
                    size={size}
                />
            );
        } else {
            return (
                <span
                    style={{
                        display: 'inline-block',
                        width: size,
                        height: size,
                        padding: '3px',
                    }}
                >
                    <span style={{display: 'inline-block', height: '100%', width: '100%', backgroundColor: color}}/>
                </span>
            );
        }
    };

    const renderPicker = () => {
        return (
            <TwitterPicker
                color={color}
                colors={BASE_COLOR}
                onChangeComplete={onColorChange}
                triangle='hide'
                styles={{
                    default: {
                        card: {
                            boxShadow: 'none'
                        },
                        swatch: {
                            height: '20px',
                            width: '20px'
                        },
                        hash: {
                            height: '20px',
                            width: '20px'
                        },
                        input: {
                            height: '18px'
                        }
                    }
                }}
            />
        );
    };

    const content = renderIndicator();
    const baseStyle = {
        display: 'inline-block',
        lineHeight: 0,
        marginRight: '2px',
    };
    if (disabled) {
        return <div style={baseStyle}>{content}</div>;
    }
    return (
        <Popover
            overlayClassName='no-arrow-popover compact-popover'
            open={visible}
            onOpenChange={setVisible}
            content={renderPicker()}
            trigger='click'
            placement='bottomRight'
        >
            <div
                style={{
                    ...baseStyle,
                    cursor: 'pointer'
                }}
            >
                {content}
            </div>
        </Popover>
    );
};

export default ColorSelect;

