import React, {useMemo} from 'react';
import {Form, InputNumber} from 'antd';

import {
    CUSTOM_FIELD_TYPES,
    CUSTOM_FIELD_V_R_MAX_LENGTH,
    CUSTOM_FIELD_V_R_MIN_LENGTH,
} from 'utils/constants/custom_field';
import {getEditable, updateValueRules} from '../utils';
import type {NormalRuleProps} from '../utils';

const TextRule: React.FC<NormalRuleProps> = ({
    fieldConfig,
    onModify,
    disabled
}) => {
    const {value_rules, form_type} = fieldConfig;

    const maxInput = useMemo(() => {
        switch (form_type) {
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT:
                return 128;
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_LONGTEXT:
                return 1024;
            default:
                return 1000;
        }
    }, [form_type]);

    let minLength; let maxLength;
    value_rules?.forEach((item) => {
        if (item.type === 'minLength') {
            minLength = item.value;
        }
        if (item.type === 'maxLength') {
            maxLength = item.value;
        }
    });

    let formContent;
    if (!getEditable(fieldConfig, 'value_rules') || disabled) {
        formContent = (
            <span className='i-field-f-item-preview'>
                {`${minLength || '0'} - ${maxLength || maxInput}`}
            </span>
        );
    } else {
        formContent = (
            <React.Fragment>
                <InputNumber
                    controls={false}
                    onChange={(value) => {
                        onModify({
                            ...fieldConfig,
                            value_rules: updateValueRules(CUSTOM_FIELD_V_R_MIN_LENGTH, value, value_rules),
                        });
                    }}
                    value={minLength}
                    min={0}
                    max={maxLength || maxInput}
                />
                <span style={{margin: '0px 16px'}}>-</span>
                <InputNumber
                    controls={false}
                    onChange={(value) => {
                        onModify({
                            ...fieldConfig,
                            value_rules: updateValueRules(CUSTOM_FIELD_V_R_MAX_LENGTH, value, value_rules),
                        });
                    }}
                    value={maxLength}
                    min={minLength}
                    max={maxInput}
                />
            </React.Fragment>
        );
    }
    return (
        <Form.Item label='限制字数'>
            {formContent}
        </Form.Item>
    );
};

export default TextRule;
