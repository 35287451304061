import React, {useRef, useCallback, useState, useEffect} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {Input} from 'antd';
import type {Identifier, XYCoord} from 'dnd-core';
import type {FC} from 'react';

import Icon from 'components/icon';
import {changeOpacity} from 'utils/style_utils';

import ColorSelect from './color_select';

type OptionProps = {
    id: string
    text: string
    index: number
    className?: string
    groupId?: string
    colorIcon?: string
    color?: string
    needFocusId?: string

    onContinueAdd?: () => void

    // 传递什么方法表示有什么权限
    deleteOption?: (index: number, groupId: string) => void,
    resetOption?: (index: number, groupId: string) => void, // 提供给某些不希望删除的选项 -> 触发重置的回调
    changeOption?: (index: number, value: string, groupId: string) => void,
    moveOption?: (dragIndex: number, hoverIndex: number, groupId: string) => void,
    onColorChange?: (index: number, color: string, groupId: string) => void
};

type DragItem = {
    index: number
    id: string
    type: string
};
const ACCEPT_KEY = 'draggable_option';

const DraggableOption: FC<OptionProps> = ({
    id, text, index, className,
    colorIcon, groupId = '',
    color,
    needFocusId,
    onContinueAdd,
    deleteOption, resetOption,
    changeOption, moveOption,
    onColorChange
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<any>(null);
    const [focus, setFocus] = useState(false);
    const acceptKey = ACCEPT_KEY + groupId;

    useEffect(() => {
        if (id === needFocusId) {
            inputRef.current.focus();
        }
    }, [needFocusId]);

    const onFocus = useCallback(() => {
        setFocus(true);
    }, []);

    const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    {handlerId: Identifier | null}
    >({
        accept: acceptKey,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveOption?.(dragIndex, hoverIndex, groupId);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const canDrag = Boolean(!focus && (moveOption !== undefined));
    const [{isDragging}, drag] = useDrag({
        type: acceptKey,
        item: () => {
            return {id, index};
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag
    });

    const handleSave = (e: any, isPressEnter?: boolean) => {
        const value = e.target.value;
        if (!value) {
            if (isPressEnter) {
                return;
            }
            if (deleteOption) {
                deleteOption(index, groupId);
            } else {
                resetOption?.(index, groupId);
            }
        }
        setFocus(false);

        inputRef.current?.blur();
        if (isPressEnter) {
            onContinueAdd?.();
        }
    };

    const handlePressEnter = (e: any) => {
        handleSave(e, true);
    };

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let dragBtn;
    if (canDrag) {
        dragBtn = (
            <Icon
                className='draggable-o-operations'
                type='drag-bar'
                style={{left: '8px'}}
                color='var(--gray-color)'
            />
        );
    }

    let deleteBtn;
    if (deleteOption) {
        deleteBtn = (
            <Icon
                type='delete'
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    e.preventDefault();
                    deleteOption(index, groupId);
                }}
                style={{right: '8px'}}
                color='var(--danger-color)'
                className='draggable-o-operations'
            />
        );
    }

    let backgroundColor;
    let colorSetting;
    if (color) {
        backgroundColor = changeOpacity(color, 0.1);
        colorSetting = (
            <ColorSelect
                color={color}
                shape={colorIcon}
                disabled={onColorChange === undefined}
                onChange={(value) => {
                    onColorChange?.(index, value, groupId);
                }}
            />
        );
    }

    return (
        <div
            ref={ref}
            className={`draggable-option${className ? ` ${className}` : ''}${canDrag ? '' : ' disable-drag'}`}
            style={{
                opacity,
                backgroundColor
            }}
            data-handler-id={handlerId}
        >
            {dragBtn}
            {colorSetting}
            <div className='draggable-o-value'>
                {(changeOption) ? (
                    <Input
                        ref={inputRef}
                        value={text}
                        onChange={(e) => {
                            const value = e.target.value;
                            changeOption(index, value, groupId);
                        }}
                        onFocus={onFocus}
                        onBlur={handleSave}
                        onPressEnter={handlePressEnter}
                        style={{width: '100%', backgroundColor: 'transparent'}}
                        bordered={false}
                        maxLength={32}
                        placeholder='请输入'
                    />
                ) : (
                    <span>{text}</span>
                )}
            </div>
            {deleteBtn}
        </div>
    );
};

export default DraggableOption;
