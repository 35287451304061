import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Input, Spin, message} from 'antd';
import {NavLink, Route, useRouteMatch, Prompt, useHistory, useLocation, Redirect, Switch, generatePath} from 'react-router-dom';
import {useMemoizedFn} from 'ahooks';
import classnames from 'classnames';

import {createCollaborationDataMeta, deleteCollaborationDataMeta, loadCollaborationDataMeta, loadDefaultCollaboration, modifyCollaborationDataMeta} from 'server/actions/custom_field';
import Icon from 'components/icon';
import Switcher, {SwitcherTab} from 'components/role_management/settting/switcher';
import {CUSTOM_FIELD_TYPES, FIELD_MODULE} from 'utils/constants/custom_field';
import {BACKEND_MANAGEMENT} from 'utils/constants/permission';
import type {AccessRuleType, CollaborationDataMeta, CustomFieldConfigType, ModifyCollaborationDataMetaParams} from 'types/custom_field';
import {FIELD_NAME_MAX_LENGTH, ScrollTableLayout, scrollToBottom} from 'components/custom_field_setting/utils';

import PropertiesContent from './properties_content';
import AuthContent from './auth_content';
import TypeItem from './type_item';
import IconSelector from './icon_selector';

const editingFlag = 'e';

const DataContext = createContext<{
    list?: CollaborationDataMeta[]
    setList?: React.Dispatch<React.SetStateAction<CollaborationDataMeta[] | undefined>>
    setSaving?: React.Dispatch<React.SetStateAction<boolean>>
    title?: string
    module?: FIELD_MODULE
    totalTypeNames?: Map<string, number>
}>({});

const PARAMS_KEY = 'params';
const tabs: SwitcherTab[] = [
    {key: PARAMS_KEY, label: '列设置'},
    {key: 'auth', label: '权限设置'},
];
const SettingContent = () => {
    const {list, setList, title, module, totalTypeNames, setSaving} = useContext(DataContext);
    const match = useRouteMatch<{typeId: string}>();
    const location = useLocation();
    const history = useHistory();
    const defaultFields = useRef<CustomFieldConfigType[]>();
    const [propertyCache, setPropertyCache] = useState<ModifyCollaborationDataMetaParams>();

    const [editing, setEditing] = useState(false);
    const [activeKey, setActiveKey] = useState<string>(PARAMS_KEY);
    const [changed, setChanged] = useState(false);
    const [nameErrorText, setNameError] = useState<string>();

    useEffect(() => {
        // 使用 beforeunload + Prompt
        const listener = (event: BeforeUnloadEvent) => {
            if (changed) {
                event.preventDefault();
                event.returnValue = '还未保存';
            }
        };

        window.addEventListener('beforeunload', listener);
        return () => {
            window.removeEventListener('beforeunload', listener);
        };
    }, [changed]);

    useEffect(() => {
        // 不要和下面的 effect 写在一起，避免 list依赖 的影响
        if (match.params.typeId === 'create' && module) {
            // 设置一个默认的
            const initDefaultData = (baseFields: CustomFieldConfigType[]) => {
                const fields: CustomFieldConfigType[] = JSON.parse(JSON.stringify(baseFields));
                setPropertyCache({
                    fields,
                    name: '',
                    icon: 'a-1',
                    application: 'Collaboration',
                    module,
                });
            };
            if (defaultFields.current) {
                initDefaultData(defaultFields.current);
            } else {
                loadDefaultCollaboration().then(({data}) => {
                    if (data) {
                        defaultFields.current = data.filter((item) => (!item.systemic || item.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF));
                        initDefaultData(defaultFields.current);
                    }
                });
            }
            setEditing(true);
        }
    }, [match.params.typeId, module]);

    useEffect(() => {
        if (match.params.typeId && match.params.typeId !== 'create' && list && module) {
            const settingId = parseInt(match.params.typeId);
            if (!isNaN(settingId)) {
                const settingItem = list.find((item) => item.id === settingId);
                if (settingItem) {
                    setPropertyCache(JSON.parse(JSON.stringify(settingItem)));
                    return;
                }
            }

            // 找不到 settingItem （大多出现在删除后）
            setChanged(false);
            if (module === FIELD_MODULE.Projects) {
                history.replace(`/${BACKEND_MANAGEMENT.PROJECT_SETTINGS}`);
            } else if (module === FIELD_MODULE.Tasks) {
                history.replace(`/${BACKEND_MANAGEMENT.TASK_SETTINGS}`);
            }
        }
    }, [match.params.typeId, list, module, history]);

    useEffect(() => {
        // 用户强制跳转后 -> 重置“变更”标识
        setChanged(false);
        setEditing(false);
        setNameError(undefined);
    }, [match.params.typeId]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const urlFlag = params.get(editingFlag);
        if (urlFlag === 'true') {
            setEditing(true);
        }
    }, [location]);

    const preventRoute = useMemo(() => {
        // 处理类型被删除后，作为 Prompt 的附加条件
        if (match.params.typeId === 'create') {
            return true;
        } else if (list) {
            const settingId = parseInt(match.params.typeId);
            for (const listItem of list) {
                if (listItem.id === settingId) {
                    return true;
                }
            }
        }
        return false;
    }, [match.params.typeId, list]);

    const onIconChange = useCallback((value: string) => {
        setPropertyCache((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    icon: value
                };
            }
        });
        setChanged(true);
    }, []);

    const onNameChange = useMemoizedFn((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        if (propertyCache) {
            setPropertyCache({
                ...propertyCache,
                name: value
            });
        }
        if (!value) {
            setNameError('类型名称不允许为空');
        } else if (value && totalTypeNames && totalTypeNames.has(value) && totalTypeNames.get(value) !== propertyCache?.id) {
            setNameError('类型名称不允许重复');
        } else {
            setNameError(undefined);
        }
        setChanged(true);
    });

    const onFieldsChange = useMemoizedFn((fields: CustomFieldConfigType[]) => {
        if (propertyCache) {
            setPropertyCache({
                ...propertyCache,
                fields
            });
        }
        setChanged(true);
    });

    const onDeleteAuthChange = useMemoizedFn((baseAccessRules: AccessRuleType[]) => {
        if (propertyCache) {
            setPropertyCache({
                ...propertyCache,
                access_rules: baseAccessRules
            });
        }
        setChanged(true);
    });

    const handleSave = useMemoizedFn(() => {
        if (propertyCache && (changed || match.params.typeId === 'create')) {
            if (propertyCache.name) {
                setSaving?.(true);
                if (propertyCache.id && propertyCache.version) {
                    modifyCollaborationDataMeta(
                        propertyCache.id,
                        propertyCache,
                    ).then(({data}) => {
                        if (data && list) {
                            const nextList = [...list];
                            const index = nextList.findIndex((item) => item.id === data.id);
                            if (index !== -1) {
                                nextList[index] = data;
                                setList?.(nextList);
                                setChanged(false);
                                setEditing(false);

                                message.success('保存成功');
                            }
                        } else {
                            message.error('保存失败');
                        }

                        setSaving?.(false);
                    });
                } else {
                    createCollaborationDataMeta(propertyCache).then(({data}) => {
                        if (data && list) {
                            const nextList = [...list, data];
                            setList?.(nextList);
                            message.success('新建成功');

                            setChanged(false);
                            history.replace(generatePath(match.path, {typeId: data.id}));
                        } else {
                            message.error('新建失败');
                        }

                        setSaving?.(false);
                    });
                }
            } else {
                setNameError('类型名称不允许为空');
            }
        } else {
            // 未做任何修改的情况
            setEditing(false);
        }
    });

    if (!propertyCache) {
        return null;
    }

    let content;
    if (activeKey === PARAMS_KEY) {
        content = (
            <PropertiesContent
                editing={editing}
                properties={propertyCache.fields}
                onFieldsChange={onFieldsChange}
            />
        );
    } else {
        content = (
            <AuthContent
                title={title}
                editing={editing}
                collaborationData={propertyCache}
                onFieldsChange={onFieldsChange}
                onDeleteAuthChange={onDeleteAuthChange}
            />
        );
    }

    let name;
    let nameError;
    let bottomBtn;
    if (editing) {
        name = (
            <Input
                key={propertyCache.id}
                autoFocus
                onChange={onNameChange}
                value={propertyCache.name}
                status={nameErrorText? 'error' : undefined}
                maxLength={FIELD_NAME_MAX_LENGTH}
            />
        );
        if (nameErrorText) {
            nameError = <span className='property-definition_name-error'>{nameErrorText}</span>;
        }
        bottomBtn = (
            <Button type='primary' disabled={Boolean(nameErrorText)} onClick={handleSave}>保存</Button>
        );
    } else {
        name = propertyCache.name;
        bottomBtn = (
            <Button type='primary' onClick={() => {
                setEditing(true);
                scrollToBottom(ScrollTableLayout);
            }}>编辑</Button>
        );
    }

    return (
        <div className='main-content-layout-1'>
            <Prompt
                when={preventRoute && changed}
                message={`您已操作到其它区域，还未保存“${propertyCache.name}”的设置`}
            />
            <div className='layout1-header'>
                <IconSelector
                    value={propertyCache.icon}
                    onChange={onIconChange}
                    isPreview={!editing}
                />
                <div className={classnames('main-title', {
                    'has-error': nameErrorText
                })}>
                    {name}
                    {nameError}
                </div>
            </div>
            <Switcher
                tabs={tabs}
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
            />
            <div className='layout1-content'>
                <div className={ScrollTableLayout}>
                    {content}
                </div>
            </div>
            <div
                className='layout1-footer'
                style={{textAlign: 'right'}}
            >
                {bottomBtn}
            </div>
        </div>
    );
};

type Props = {
    module: FIELD_MODULE,
};
const TypeDefinition: React.FC<Props> = ({
    module
}) => {
    const history = useHistory();
    const match = useRouteMatch();
    const [list, setList] = useState<CollaborationDataMeta[]>();
    const [saving, setSaving] = useState(false);
    const [totalTypeNames, setTotalTypeNames] = useState<Map<string, number>>();

    useEffect(() => {
        loadCollaborationDataMeta().then(({data}) => {
            if (data) {
                const result: CollaborationDataMeta[] = [];
                const nameMap = new Map<string, number>();
                data.forEach((item) => {
                    nameMap.set(item.name, item.id);
                    if (item.module === module) {
                        result.push(item);
                    }
                });
                setList(result);
                setTotalTypeNames(nameMap);
            }
        });
    }, [module]);

    const redirectUrl = useMemo(() => {
        if (list && list.length) {
            return `${match.path}/${list[0].id}`;
        }
    }, [list, match.path]);

    const handleEditItem = useCallback((id: number) => {
        history.push(`${match.path}/${id}?${editingFlag}=true`);
    }, [history, match.path]);

    const handleDeleteItem = useMemoizedFn((id: number) => {
        deleteCollaborationDataMeta(id).then(({data}) => {
            if (data && list) {
                const nextList = [...list];
                const index = nextList.findIndex((item) => item.id === data.id);
                if (index !== -1) {
                    nextList.splice(index, 1);
                    setList?.(nextList);
                    message.success('删除成功');
                }
            }
        });
    });

    const addTypeItem = useCallback(() => {
        history.push(`${match.path}/create?${editingFlag}=true`);
    }, [history, match.path]);

    let title;
    let tip;
    if (module === FIELD_MODULE.Projects) {
        title = '项目';
        tip = (
            <Icon
                type='help'
                title='项目不能有上级工作项'
                color='#C9CDD4'
                style={{marginLeft: '2px'}}
            />
        );
    } else {
        title = '任务';
    }

    let redirect;
    if (redirectUrl) {
        redirect = (
            <Redirect to={redirectUrl}/>
        );
    }

    let mask;
    if (saving) {
        mask = (
            <div style={{
                position: 'absolute',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                inset: 0,
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            }}>
                <Spin size='large'/>
            </div>
        );
    }

    return (
        <DataContext.Provider
            value={{
                list, setList,
                title, module, totalTypeNames,
                setSaving
            }}
        >
            <div className='property-definition-container'>
                <div className='second-menu-sidebar property-definition-sidebar'>
                    <div className='second-menu-header'>
                        {`${title}类型`}
                        {tip}
                    </div>
                    <div className='second-menu-list'>
                        {list?.map((item) => (
                            <NavLink
                                key={item.id}
                                to={`${match.path}/${item.id}`}
                                className='backend-menu_item second-menu-item'
                            >
                                <TypeItem
                                    data={item}
                                    handleEdit={handleEditItem}
                                    handleDelete={handleDeleteItem}
                                />
                            </NavLink>
                        ))}

                    </div>
                    <Button
                        className='add-new-btn'
                        icon={<Icon type='plus'/>}
                        onClick={addTypeItem}
                    >{'新建类型'}</Button>
                </div>
                <Switch>
                    <Route
                        path={`${match.path}/:typeId`}
                        component={SettingContent}
                    />
                    {redirect}
                </Switch>
                {mask}
            </div>
        </DataContext.Provider>
    );
};

export default TypeDefinition;
