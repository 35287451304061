import React, {ReactNode} from 'react';
import {Avatar} from 'antd';

import Icon from 'components/icon';
import {concatImagePrefix} from 'utils/image_utils';
import {Staff} from 'types/staffs';
import {CallBackFunc} from 'types/general';
import noDataPng from 'images/noData.png';

export interface MemberRowProps {
    member: Staff,
    fullName: ReactNode,
    onMemberRemove: (memberIds: string[], onSuccess?: CallBackFunc) => void,
}

const MemberRow: React.FC<MemberRowProps> = (props) => {
    const {member, fullName} = props;

    const handleRemove = () => {
        props.onMemberRemove([props.member.user_id]);
    };

    const renderContent = () => {
        if (member) {
            return (
                <>
                    <div className={'backend-role-setting-scroll'}>
                        <div className='member-table-item mx-16'>
                            {/* 姓名 */}
                            <div className='cell-normal-content'>
                                <div
                                    className='single-normal-user-container'
                                    style={{padding: 0, height: 'auto'}}
                                >

                                    <Avatar
                                        src={concatImagePrefix(member.avatar?.small)}
                                        size={24}
                                    />
                                    <div style={{marginLeft: 8, flex: 1}}>
                                        <div className='single-normal-user-info'>
                                            { fullName }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 操作 */}
                            <div className='cell-operation'>
                                <Icon
                                    type='delete'
                                    size={18}
                                    color='var(--danger-color)'
                                    title='删除成员'
                                    onClick={handleRemove}
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <div className='empty-content-container'>
                <img
                    className='empty-content-img'
                    alt='no-data'
                    src={noDataPng}
                />
            </div>
        );
    };

    return (
        renderContent()
    );
};

export default MemberRow;
