import React, {useEffect, useImperativeHandle, useRef} from 'react';
import {Form, FormInstance, Input, Select, Switch} from 'antd';
import uuid from 'react-uuid';

import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';
import type {CustomFieldConfigType, FieldOptionType} from 'types/custom_field';

import {FieldTypeItem} from '../field_type_select';
import {FIELD_NAME_MAX_LENGTH, OptionError, getEditable, initNewFieldProps} from '../utils';

import TextRule from './text_rule';
import NumberRule from './number_rule';
import OptionRule, {OptionRuleRef} from './option_rule';
import DateRule from './date_rule';
import OptionStatusRule, {OptionStatusRuleRef} from './option_status_rule';
import PriorityRule from './option_priority_rule';
import MemberRule from './member_rule';

export type CustomFieldRef = {
    getOptionErrors: () => OptionError | undefined;
};

type Props = {
    typeOptions: CUSTOM_FIELD_TYPES[]
    colorfulOptions?: boolean
    totalCustomFields?: CustomFieldConfigType[]
    basicNames?: string[]
    fieldConfig: CustomFieldConfigType
    onModify: (config: CustomFieldConfigType) => void
    getFormInstance?: (field: FormInstance) => void
    typeMutable?: boolean
    showUsageSetting?: boolean
    disabled?: boolean
};

const FIELD_NAME = 'name';
const FIELD_USAGE = 'usage';

const CustomFieldForm = React.forwardRef<CustomFieldRef, Props>(({
    typeOptions,
    colorfulOptions,
    totalCustomFields,
    basicNames,
    fieldConfig,
    onModify,
    getFormInstance,
    typeMutable,
    showUsageSetting,
    disabled,
}, ref) => {
    const optionRuleRef = useRef<OptionRuleRef>(null);
    const statusRuleRef = useRef<OptionStatusRuleRef>(null);
    const originDataCopy = useRef<CustomFieldConfigType>();
    const [formField] = Form.useForm();

    useImperativeHandle(ref, () => ({
        getOptionErrors: () => {
            // 提供给外部识别 -> 阻止Drawer的关闭
            return optionRuleRef.current?.optionErrors.current || statusRuleRef.current?.optionErrors.current;
        },
    }), []);

    useEffect(() => {
        originDataCopy.current = fieldConfig;
    }, [fieldConfig]);

    useEffect(() => {
        if (getFormInstance) {
            getFormInstance(formField);
        }
    }, [getFormInstance, formField]);

    useEffect(() => {
        const optionRuleInstance = optionRuleRef.current;
        const statusRuleInstance = statusRuleRef.current;
        return () => {
            // 借用组件的卸载回调，将选项的设定值回写到真正的数据上
            const configCopy = originDataCopy.current;
            if (disabled !== true && configCopy) {
                if (configCopy.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT ||
                    configCopy.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT
                ) {
                    if (configCopy.id !== 'priority') {
                        const temp = new Set();
                        const result: FieldOptionType[] = [];
                        optionRuleInstance?.optionsRef.current?.forEach((i) => {
                            if (!temp.has(i.text)) {
                                result.push({label: i.text, value: i.text, color: i.color});
                                temp.add(i.text);
                            }
                        });
                        onModify({
                            ...configCopy,
                            props: {
                                ...configCopy.props,
                                options: result,
                                colorful: colorfulOptions ? optionRuleInstance?.colorfulRef.current : false
                            },
                        });
                    }
                } else if (configCopy.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STATUS) {
                    const status = statusRuleInstance?.getOptions();
                    if (status !== configCopy.props?.options) {
                        onModify({
                            ...configCopy,
                            props: {
                                ...configCopy.props,
                                options: status,
                            },
                        });
                    }
                }
            }
        };
    }, [disabled, colorfulOptions]);

    const validateName = (rule: any, value: string) => {
        if (value) {
            if (totalCustomFields) {
                for (const item of totalCustomFields) {
                    if (fieldConfig.id !== item.id && value === item.name) {
                        return Promise.reject('列名称不能重复');
                    }
                }
            }
            if (basicNames) {
                for (const name of basicNames) {
                    if (value === name) {
                        return Promise.reject('列名称不能重复');
                    }
                }
            }
        }
        return Promise.resolve();
    };

    const onTypeChange = (value: CUSTOM_FIELD_TYPES) => {
        if (
            fieldConfig.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT ||
            fieldConfig.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT
        ) {
            // 特殊的 “状态”、“优先级”不会触发这个交互
            if (optionRuleRef.current?.optionErrors.current) {
                // 清除选项的校验错误
                optionRuleRef.current.optionErrors.current = undefined;
            }
        }
        const newItem: CustomFieldConfigType = {
            id: fieldConfig.id,
            name: fieldConfig.name,
            form_type: value,
        };
        initNewFieldProps(newItem, {colorfulOptions});
        onModify(newItem);
        if (
            fieldConfig.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT ||
            fieldConfig.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT
        ) {
            const newOptions = newItem.props?.options?.map((o) => ({id: uuid(), text: o.value, color: o.color})) || [];
            // 组件初始化
            optionRuleRef.current?.setOptions(newOptions);
            if (colorfulOptions) {
                optionRuleRef.current?.setColorSetting(newItem.props?.colorful !== undefined ? newItem.props.colorful : true);
            }
        }
    };

    const renderName = () => {
        if (!getEditable(fieldConfig, 'name') || disabled) {
            return <span className='i-field-f-item-preview'>{fieldConfig.name}</span>;
        }
        return (<Input
            autoFocus
            placeholder='请输入列名称'
            maxLength={FIELD_NAME_MAX_LENGTH}
        />);
    };

    const renderType = () => {
        if (typeMutable && disabled !== true) {
            const options = typeOptions.map((i) => {
                return (
                    <Select.Option
                        key={i}
                        value={i}
                    >
                        <FieldTypeItem
                            type={i}
                        />
                    </Select.Option>
                );
            });
            return (
                <Select
                    defaultValue={fieldConfig.form_type}
                    onChange={onTypeChange}
                    style={{width: '100%'}}
                >
                    {options}
                </Select>
            );
        }
        return (
            <div
                className='i-field-f-item-preview'
                style={{display: 'flex'}}
            >{/* 完全为了与label对齐 */}
                <FieldTypeItem type={fieldConfig.form_type}/>
            </div>
        );
    };

    const  renderItems = () => {
        switch (fieldConfig.form_type) {
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT:
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_LONGTEXT:
                return (
                    <TextRule
                        fieldConfig={fieldConfig}
                        onModify={onModify}
                        disabled={disabled}
                    />
                );
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_NUMBER:
                return (
                    <NumberRule
                        fieldConfig={fieldConfig}
                        onModify={onModify}
                        disabled={disabled}
                    />
                );
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT:
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT:
                if (fieldConfig.id === 'priority') {
                    return (
                        <PriorityRule
                            fieldConfig={fieldConfig}
                            onModify={onModify}
                            disabled={disabled}
                        />
                    );
                }
                return (
                    <OptionRule
                        ref={optionRuleRef}
                        fieldConfig={fieldConfig}
                        colorfulOptions={colorfulOptions}
                        disabled={disabled}
                    />
                );
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STATUS: {
                if (fieldConfig.id === 'status_ex') {
                    return (
                        <OptionStatusRule
                            ref={statusRuleRef}
                            fieldConfig={fieldConfig}
                            disabled={disabled}
                        />
                    );
                }
                break;
            }
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_DATE: {
                return (
                    <DateRule
                        fieldConfig={fieldConfig}
                        onModify={onModify}
                        disabled={disabled}
                    />
                );
            }
            case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF: {
                return (
                    <MemberRule
                        fieldConfig={fieldConfig}
                        onModify={onModify}
                        disabled={disabled}
                    />
                );
            }
            default:
                return null;
        }
    };

    const renderUsageSetting = () => {
        if (showUsageSetting) {
            return (
                <Form.Item
                    label='是否使用'
                    name={FIELD_USAGE}
                    initialValue={!(fieldConfig.disabled)}
                    valuePropName='checked'
                >
                    <Switch disabled={!getEditable(fieldConfig, 'disabled') || disabled}/>
                </Form.Item>
            );
        }
    };

    return (
        <Form
            labelAlign='left'
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            requiredMark={false}
            colon={false}
            onValuesChange={(changedValues) => {
                for (const [key, value] of Object.entries(changedValues)) {
                    if (key === FIELD_NAME) {
                        onModify({
                            ...fieldConfig,
                            name: value as string,
                        });
                    } else if (key === FIELD_USAGE) {
                        onModify({
                            ...fieldConfig,
                            disabled: !value
                        });
                    }
                }
            }}
            form={formField}
        >
            <Form.Item
                label='列名称'
                name={FIELD_NAME}
                initialValue={fieldConfig.name}
                rules={[
                    {required: true, message: '请输入列名称'},
                    {max: FIELD_NAME_MAX_LENGTH, message: `列名称不超过${FIELD_NAME_MAX_LENGTH}个字`},
                    {validator: validateName},
                ]}
            >
                {renderName()}
            </Form.Item>
            <Form.Item label='类型'>
                {renderType()}
            </Form.Item>
            {renderItems()}
            {renderUsageSetting()}
        </Form>
    );
});

CustomFieldForm.displayName = 'CustomFieldForm';
export default CustomFieldForm;
