import {OSyncStatus, UserType, WecomSSOParams} from 'types/users';

import {IAIClient} from './index';

export default {
    getLingYangCorpIdByCode: async (code: string, isvAppType: string, type: string | null) => {
        return IAIClient.doFetch<{corp_id: string, user_id: string}>(
            `${IAIClient.getBaseRoute()}/open_platform/lingyang/code/${code}${'/' + isvAppType}${type ? ('/' + type) : ''}`,
            {method: 'get'},
        );
    },

    loginByLingYangOauth: async (params: WecomSSOParams) => {
        return IAIClient.doFetch<UserType>(
            `${IAIClient.getBaseRoute()}/open_platform/lingyang/sso`,
            {method: 'post', body: JSON.stringify(params)},
        );
    },

    loadLingYangSyncStatus: async (corpId: string) => {
        // 拉取企业架构初始化状态
        return IAIClient.doFetch<OSyncStatus>(
            `${IAIClient.getBaseRoute()}/open_platform/lingyang/${corpId}/syncstatus`,
            {method: 'get'},
        );
    },

    loadLingYangEnterpriseId: async (corpId: string) => {
        return IAIClient.doFetch<{enterprise_id: string}>(
            `${IAIClient.getBaseRoute()}/open_platform/lingyang/${corpId}`,
            {method: 'get'},
        );
    }
};
