import React, {useContext, useEffect, useState} from 'react';
import {message, Modal} from 'antd';

import {addRoleUsers, loadRoleUsers, removeRoleUsers} from 'server/actions/roles';
import {IAIClient} from 'server/client';
import {Staff} from 'types/staffs';
import {Role, RoleSettingProps} from 'types/roles';
import {CallBackFunc} from 'types/general';

import GlobalContext from 'context/global_context';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
import {RoleNames} from 'utils/constants/general';
import {isDingtalkEnvironment} from 'utils/utils';
import StaffInfo from 'components/organization_components/staff_info';

import RoleSetting from './settting/role_setting';
import GoToWrapper from './goto_wrapper';

export type RoleConfig = {
    SettingComponent: React.FC<RoleSettingProps>;
    minAuthsCount: number;
    removeCall?: (removeId: string) => void,
};

const roleConfigMap: Map<string, RoleConfig> = new Map([
    [RoleNames.Admin, {
        minAuthsCount: 1,
        SettingComponent: RoleSetting,
        removeCall: (removeId: string) => {
            const currentUserId = IAIClient.getUserId();
            if (currentUserId === removeId) {
                EventEmitter.emit(EventTypeEnum.CLOSE_BACKEND);
            }
        },
    }],
]);

const roleConfigDefault: RoleConfig = {
    minAuthsCount: 0,
    SettingComponent: RoleSetting,
};

function getRoleConfig(name: string): RoleConfig {
    let config = roleConfigMap.get(name);
    if (!config) {
        config = roleConfigDefault;
    }

    return config;
}

export interface RoleControllerProps {
    role: Role,
}

const RoleController: React.FC<RoleControllerProps> = (props) => {
    const [memberIds, setMemberIds] = useState<string[]>();

    const {role} = props;
    const {staffs, loadStaffsIfNeeded} = useContext(GlobalContext);

    const config: RoleConfig = getRoleConfig(role.name);

    useEffect(() => {
        loadRoleUsers(role.id).then((response) => {
            const {error, data} = response;
            if (error) {
                message.error(`获取${role.alias_name}成员失败`);
            } else if (data) {
                setMemberIds(data);
                loadStaffsIfNeeded(data).then();
            }
        });
    }, [role]);

    const handleMemberRemove = (removeIds: string[], onSuccess?: CallBackFunc) => {
        if (config.minAuthsCount && memberIds && memberIds.length <= config.minAuthsCount) {
            message.warning(`至少保留一个${role.alias_name}`);
            return;
        }

        const content = (
            <div className={'remove-member-confirm'}>
                <div className={'message'}>{`请确认从"${role.alias_name}"中移除下列成员?`}</div>
                <div className={'members'}>
                    {
                        removeIds.map((removeId) => {
                            const staff = staffs[removeId];
                            let name: React.ReactNode = removeId;
                            if (staff) {
                                name = <StaffInfo staff={staff}/>;
                            }

                            return (
                                <div key={removeId} className={'member'}>{name}</div>
                            );
                        })
                    }
                </div>
            </div>
        );

        Modal.confirm({
            title: '移除成员?',
            content,
            cancelText: '取消',
            okText: '移除',
            onOk: () => doMemberRemove(removeIds, onSuccess),
        });
    };

    const doMemberRemove = (removeIds: string[], onSuccess?: CallBackFunc) => {
        removeRoleUsers(role.id, removeIds).then(({error}) => {
            if (error) {
                message.error('成员移除失败');
            } else {
                message.success('成员移除成功');

                if (memberIds) {
                    const removeIdsSet = new Set(removeIds);
                    const nextMemberIds = [];

                    for (const memberId of memberIds) {
                        if (removeIdsSet.has(memberId)) {
                            config.removeCall && config.removeCall(memberId);
                            continue;
                        }
                        nextMemberIds.push(memberId);
                    }

                    setMemberIds(nextMemberIds);
                }

                if (onSuccess) {
                    onSuccess();
                }
            }
        });
    };

    const handleMemberSelected = (membersMap: Map<string, Staff>) => {
        const userIds: string[] = [];
        for (const key of membersMap.keys()) {
            userIds.push(key);
        }

        addRoleUsers(role.id, userIds).then(({error}) => {
            let msgPrefix = '成员添加';
            const isRoleBoss = role.name === RoleNames.Boss;
            if (isRoleBoss) {
                msgPrefix = `更换${role.alias_name}`;
            }

            if (error) {
                message.error(`${msgPrefix}失败`);
            } else {
                message.success(`${msgPrefix}成功`);
                if (isRoleBoss) {
                    setMemberIds(userIds);
                } else {
                    setMemberIds((memberIds) => {
                        if (memberIds) {
                            return memberIds.concat(userIds);
                        }
                        return userIds;
                    });
                }
            }
        });
    };

    const renderCustomDescription = (text: string) => {
        return (
            <div className={'description'}>
                {text}
                <GoToWrapper text=' 修改组织架构'/>
            </div>
        );
    };

    const renderDescription = () => {
        if (role.name === RoleNames.Manager) {
            if (isDingtalkEnvironment()) {
                return <div className='description'>自动同步组织架构中的主管角色，如需调整请前往钉钉管理后台</div>;
            }
            return renderCustomDescription('自动同步组织架构中的主管角色，调整成员需前往');
        }

        if (role.name === RoleNames.Hrg) {
            if (isDingtalkEnvironment()) {
                return <div className='description'>自动同步部门的人事专员角色，如需调整请前往组织管理</div>;
            }
            return renderCustomDescription('自动同步部门的人事专员角色，如需修改请前往');
        }

        if (role.description) {
            return <div className={'description'}>{role.description}</div>;
        }
    };

    const renderSetting = () => {
        if (!config) {
            return null;
        }

        const SettingComponent: React.FC<RoleSettingProps> = config.SettingComponent;
        return (
            <SettingComponent
                role={role}
                memberIds={memberIds}
                onMemberSelected={handleMemberSelected}
                onMemberRemove={handleMemberRemove}
            />
        );
    };

    return (
        <div className='role-setting-controller'>
            <div className={'name mx-16'}>
                {role.alias_name}
                {renderDescription()}
            </div>
            {renderSetting()}
        </div>
    );
};

export default RoleController;
