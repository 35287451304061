import React, {useCallback, useState} from 'react';
import classnames from 'classnames';

import Icon from 'components/icon';

type Props = {
    title: string,
    content?: React.ReactNode,
};

const CollapseItem: React.FC<Props> = ({
    title,
    content
}) => {
    const [active, setActive] = useState(true);

    const handleCollapse = useCallback(() => {
        setActive((value) => !value);
    }, []);

    return (
        <div className={classnames('collapse-item', {
            'collapse-item-active': active
        })}>
            <div className='collapse-header'>
                <Icon
                    type='solid_arrow-left'
                    className='collapse-icon'
                    onClick={handleCollapse}
                />
                <span className='collapse-title'>{title}</span>
            </div>
            {active ? content : null}
        </div>
    );
};

export default CollapseItem;
