import React from 'react';
import {Form, Input, InputNumber} from 'antd';

import {
    CUSTOM_FIELD_V_R_MAX,
    CUSTOM_FIELD_V_R_MIN,
} from 'utils/constants/custom_field';

import {getEditable, updateValueRules} from '../utils';
import type {NormalRuleProps} from '../utils';

const NumberRule: React.FC<NormalRuleProps> = ({
    fieldConfig,
    onModify,
    disabled
}) => {
    const renderUnit = () => {
        if (!getEditable(fieldConfig, 'unit') || disabled) {
            if (fieldConfig.props?.unit) {
                return <span className='i-field-f-item-preview'>{fieldConfig.props?.unit}</span>;
            }
            return (
                <span className='i-field-f-item-preview'>&nbsp;</span>
            );
        }
        return (
            <Input
                value={fieldConfig.props?.unit}
                onChange={(e) => {
                    const value = e.target.value;
                    onModify({
                        ...fieldConfig,
                        props: {
                            ...fieldConfig.props,
                            unit: value,
                        },
                    });
                }}
                maxLength={10}
            />
        );
    };

    const renderRange = () => {
        const {value_rules} = fieldConfig;
        let min; let max;
        value_rules?.forEach((item) => {
            if (item.type === 'min') {
                min = item.value;
            }
            if (item.type === 'max') {
                max = item.value;
            }
        });

        let content;
        if (!getEditable(fieldConfig, 'value_rules') || disabled) {
            let minString;
            if (min === undefined) {
                minString = fieldConfig.id === 'progress' ? '0' : '/';
            } else {
                minString = String(min);
            }

            let maxString;
            if (max === undefined) {
                maxString = '/';
            } else {
                maxString = String(max);
            }
            content = (
                <span className='i-field-f-item-preview'>
                    {`${minString} - ${maxString}`}
                </span>
            );
        } else {
            content = (
                <>
                    <InputNumber
                        controls={false}
                        onChange={(value) => {
                            onModify({
                                ...fieldConfig,
                                value_rules: updateValueRules(CUSTOM_FIELD_V_R_MIN, value, value_rules),
                            });
                        }}
                        value={min}
                        max={max}
                    />
                    <span style={{margin: '0px 16px'}}>-</span>
                    <InputNumber
                        controls={false}
                        onChange={(value) => {
                            onModify({
                                ...fieldConfig,
                                value_rules: updateValueRules(CUSTOM_FIELD_V_R_MAX, value, value_rules),
                            });
                        }}
                        value={max}
                        min={min}
                    />
                </>
            );
        }

        return (
            <Form.Item label='数据范围'>
                {content}
            </Form.Item>
        );
    };

    const renderPrecision = () => {
        const precision = fieldConfig.props?.precision;

        let content;
        if (!getEditable(fieldConfig, 'precision') || disabled) {
            if (precision !== undefined) {
                content = (
                    <span className='i-field-f-item-preview'>
                        {`限制${precision}位`}
                    </span>
                );
            }
        } else {
            content = (
                <>
                    <span>限制</span>
                    <InputNumber
                        style={{margin: '0 8px'}}
                        controls={false}
                        value={precision}
                        onChange={(value) => {
                            onModify({
                                ...fieldConfig,
                                props: {
                                    ...fieldConfig.props,
                                    precision: value || undefined,
                                },
                            });
                        }}
                        min={0}
                        max={10}
                    />
                    <span>位</span>
                </>
            );
        }
        return (
            <Form.Item label='允许小数'>
                {content}
            </Form.Item>
        );
    };

    return (
        <>
            <Form.Item label='单位'>
                {renderUnit()}
            </Form.Item>
            {renderRange()}
            {renderPrecision()}
        </>
    );
};

export default NumberRule;
