import {IAIClient} from 'server/client';
import {Enterprise} from 'types/enterprise';

export async function loadClientConfig() {
    let data: any;
    try {
        data = await IAIClient.loadClientConfig();
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadUser() {
    let data;
    try {
        data = await IAIClient.loadUser();
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadEnterprisesByUserId() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data: Enterprise[];
    try {
        data = await IAIClient.loadEnterprisesByUserId();
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadUserRoles(enterpriseId: string) {
    if (!enterpriseId) {
        return {data: false};
    }
    let data;
    try {
        data = await IAIClient.loadUserRoles(enterpriseId);
    } catch (error) {
        return {error};
    }

    if (!data) {
        return {data: true};
    }

    return {data};
}

export function logs(msg: string) {
    try {
        IAIClient.logs(msg);
    } catch {

    }
}
