import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tag, Popover} from 'antd';
import debounce from 'lodash/debounce';

import {DEPARTMENT_KEY, STAFF_KEY} from 'components/organization_tree/tree_utils';
import DepartmentInfo from 'components/organization_components/department_info';
import StaffInfo from 'components/organization_components/staff_info';

import Search from './search.jsx';

export default class ItemsSelected extends React.PureComponent {
    static propTypes = {
        departmentSelectable: PropTypes.bool.isRequired,
        staffs: PropTypes.object.isRequired,
        departmentFullPath: PropTypes.object,
        selectedItems: PropTypes.array.isRequired,
        selectedItemIds: PropTypes.array.isRequired,
        itemIdsDisabled: PropTypes.array,
        onItemSelected: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        excludeNoIsv: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            term: '',
            itemIdsDisabled: [],
            itemIdsDisabledSet: new Set(),
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {itemIdsDisabled} = props;
        if (itemIdsDisabled !== state.itemIdsDisabled) {
            return {
                itemIdsDisabled,
                itemIdsDisabledSet: new Set(itemIdsDisabled),
            };
        }

        return null;
    }

    getInputRef = (element) => {
        this.inputRef = element;
    };

    isDisabled = (id) => {
        const {itemIdsDisabledSet} = this.state;
        return itemIdsDisabledSet.has(id);
    };

    deleteItem = (item) => {
        if (!this.isDisabled(item.id)) {
            this.props.onItemSelected(item, true);
        }
    };

    addItem = (item) => {
        if (!this.isDisabled(item.id)) {
            this.props.onItemSelected(item, false);
        }
    };

    handleDeleteIcon = (e, item) => {
        e.preventDefault();
        e.stopPropagation();

        this.deleteItem(item);
    };

    termChange = debounce((e) => {
        const value = e.target.value.trim();
        this.setState({term: value});
    }, 300);

    onInputKeyDown = (e) => {
        if (e.keyCode === 8 && e.target.value.length === 0) {
            const lastItem = [...this.props.selectedItems].pop();
            if (lastItem) {
                this.deleteItem(lastItem);
            }
        }
    };

    onSearchFocus = () => {
        if (this.delayClear) {
            clearTimeout(this.delayClear);
        }
    };

    onSearchBlur = (e) => {
        if (e.target.value) {
            this.delayClear = setTimeout(() => {
                if (this.inputRef) {
                    this.inputRef.value = '';
                }
                this.setState({term: ''});
            }, 200);
        }
    };

    handleCloseSearch = () => {
        if (this.delayClear) {
            clearTimeout(this.delayClear);
        }
        if (this.inputRef) {
            this.inputRef.value = '';
            this.setState({term: ''});
            this.inputRef.focus();
        }
    };

    renderSearch = () => {
        const {term, itemIdsDisabledSet} = this.state;
        const {departmentSelectable, departmentFullPath, selectedItems, selectedItemIds, excludeNoIsv} = this.props;

        return (
            <Search
                departmentSelectable={departmentSelectable}
                departmentFullPath={departmentFullPath}
                term={term}
                selectedItems={selectedItems}
                selectedItemIds={selectedItemIds}
                itemIdsDisabledSet={itemIdsDisabledSet}
                onChooseItem={(item) => this.addItem(item)}
                onDeleteItem={(item) => this.deleteItem(item)}
                onCloseSearch={this.handleCloseSearch}
                excludeNoIsv={excludeNoIsv}
            />
        );
    };

    renderTag = (item) => {
        const {staffs, departmentFullPath} = this.props;

        let content;
        if (item.type === STAFF_KEY) {
            const staff = staffs[item.id];
            if (staff) {
                content = (<StaffInfo
                    staff={staff}
                    plain={true}
                />);
            }
        } else if (item.type === DEPARTMENT_KEY) {
            const departmentPath = departmentFullPath[item.id];
            if (departmentPath) {
                content = <DepartmentInfo departmentName={departmentPath.data.name}/>;
            }
        }

        if (!content) {
            return null;
        }

        return (
            <Tag
                key={item.id}
                closable={true}
                onClose={(e) => this.handleDeleteIcon(e, item)}
            >
                {content}
            </Tag>
        );
    };

    render() {
        const {term} = this.state;
        const {selectedItems} = this.props;
        return (
            <div className='search-side'>
                <div className='members-search-container'>
                    <div className='selected_members-tag'>
                        {
                            selectedItems.map((item) => {
                                return this.renderTag(item);
                            })
                        }
                        <input
                            ref={this.getInputRef}
                            placeholder='搜索名字'
                            onChange={this.termChange}
                            onKeyDown={this.onInputKeyDown}
                            onFocus={this.onSearchFocus}

                            // onBlur={this.onSearchBlur}
                        />
                    </div>
                    <Popover
                        getPopupContainer={() => {
                            return document.getElementById('Members_Selector');
                        }}
                        placement='bottom'
                        visible={Boolean(term)}
                        content={(
                            <div
                                style={{
                                    width: 368,
                                    maxHeight: 200,
                                    overflow: 'auto',
                                }}
                            >{this.renderSearch()}</div>
                        )}
                        destroyTooltipOnHide={true}
                        arrowPointAtCenter={true}
                        autoAdjustOverflow={false}
                    >
                        <div className='search-result-placeholder'/>
                    </Popover>
                </div>
                <div className='button-group'>
                    <Button
                        className='button_small'
                        onClick={this.props.onClose}
                    >{'取消'}</Button>
                    <Button
                        className='button_small'
                        type='primary'
                        onClick={this.props.onConfirm}
                        disabled={selectedItems.length <= 0}
                    >{'确定'}</Button>
                </div>
            </div>
        );
    }
}
