import {CustomFieldConfigType, ValueRule, ValueRuleType} from 'types/custom_field';
import {CUSTOM_FIELD_DATE, CUSTOM_FIELD_TYPES, BASE_COLOR} from 'utils/constants/custom_field';
import {addInOrder} from 'utils/utils';

export const FIELD_NAME_MAX_LENGTH = 32;

export type NormalRuleProps = {
    fieldConfig: CustomFieldConfigType
    onModify: (config: CustomFieldConfigType) => void
    disabled?: boolean
};

type ExtraParams = {
    colorfulOptions?: boolean
};
export const initNewFieldProps: (
    field: CustomFieldConfigType, params?: ExtraParams
) => void = (field, params = {}) => {
    if (
        field.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT ||
        field.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT
    ) {
        const newOption = addInOrder([], '选项');
        let color: string | undefined;
        if (params.colorfulOptions) {
            color = BASE_COLOR[0];
        }
        field.props = {colorful: params.colorfulOptions, options: [{label: newOption, value: newOption, color}]};
    } else if (field.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_DATE) {
        field.props = {format: CUSTOM_FIELD_DATE.DAY};
    } else if (field.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF) {
        field.props = {member_role: field.id, messagable: true, multiple: true, multiple_modifiable: true};
    }
};

export const getEditable = (fieldDefinition: CustomFieldConfigType, propsKey: string) => {
    if (fieldDefinition.basic) {
        if (fieldDefinition.modifiability?.[propsKey]) {
            return true;
        }
        return false;
    }
    return true;
};

export const updateValueRules = (type: ValueRuleType, value: any, valueRules?: ValueRule[]): ValueRule[] => {
    let prevRules = valueRules;
    const updateItem: ValueRule = {type, value};
    if (prevRules) {
        const i = prevRules.findIndex((item) => item.type === type);
        if (i === -1) {
            prevRules = [...prevRules, updateItem];
        } else {
            prevRules[i] = updateItem;
            prevRules = [...prevRules];
        }
    } else {
        prevRules = [updateItem];
    }
    return prevRules;
};


export type OptionError = Record<string, '选项不能重复' | null>;
export type PrivateOption = {
    id: string
    text: string
    color?: string
    defaultFlag?: boolean
};
export const repeatCheck = (totalOptions: PrivateOption[]): OptionError => {
    // text做key，重复的id数组做value
    const repeatCache: Record<string, string[] | undefined> = {};
    totalOptions.forEach((optionItem) => {
        const cacheItem = repeatCache[optionItem.text];
        if (cacheItem) {
            cacheItem.push(optionItem.id);
        } else {
            repeatCache[optionItem.text] = [optionItem.id];
        }
    });
    const result: OptionError = {};
    for (const temp of Object.values(repeatCache)) {
        if (temp) {
            if (temp.length === 1) {
                result[temp[0]] = null;
            } else if (temp.length > 1) {
                temp.forEach((id) => result[id] = '选项不能重复');
            }
        }
    }
    return result;
};

export const ScrollTableLayout = 'layout1-scroll-wrapper';

export const scrollToBottom = (className: string) => {
    const scrollLayout = document.querySelector(`.${className}`);
    if (scrollLayout && scrollLayout.scrollHeight) {
        setTimeout(() => {
            scrollLayout.scrollTop = scrollLayout?.scrollHeight;
        });
    }
};
