import React from 'react';
import {Button, message, Popover} from 'antd';

import {setDepartmentMain} from 'server/actions/staffs';

const StaffDepartmentMainPopover: React.FC<{
    children: React.ReactNode,
    departmentId: number,
    userId: string,
    successCallback: () => void,
}> = ({
    children,
    departmentId,
    userId,
    successCallback,
}) => {

    const changeMainUnit = (e: React.MouseEvent, departmentId: number) => {
        e.stopPropagation();
        e.preventDefault();
        setDepartmentMain(userId, departmentId).then(({error}) => {
            if (error) {
                message.error('设置主部门失败');
            } else {
                message.success('设置主部门成功');
                successCallback();
            }
        });
    };

    return (
        <Popover
            key={departmentId}
            overlayClassName='compact-popover'
            trigger='click'
            placement='bottom'
            content={
                <Button
                    className='button_small'
                    type='primary'
                    onClick={(e) => changeMainUnit(e, departmentId)}
                >
                    {'设为主部门'}
                </Button>
            }
        >
            {children}
        </Popover>
    );
};

export default StaffDepartmentMainPopover;
