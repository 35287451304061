import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Input, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {useMemoizedFn} from 'ahooks';

import Icon from 'components/icon';
import {useClickOutsideRef} from 'utils/hooks';
import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';
import {guid} from 'utils/utils';
import type {CustomFieldConfigType} from 'types/custom_field';

import DraggableRow, {MoveRowParams} from './draggable_row';
import FieldTypeSelect, {FieldTypeItem} from './field_type_select';
import {FIELD_NAME_MAX_LENGTH, ScrollTableLayout, initNewFieldProps, scrollToBottom} from './utils';

type NameCellProps = {
    propertyItem: CustomFieldConfigType
    totalNames: string[]
    editing?: boolean
    setEditingId: (id?: string) => void
    handleNameChange?: (item: CustomFieldConfigType, continueAdd?: boolean) => void
};
const NameCell: React.FC<NameCellProps> = ({
    propertyItem,
    totalNames,
    editing,
    setEditingId,
    handleNameChange,
}) => {
    const [nameError, setNameError] = useState('');

    const onTextChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const value = event.target.value.trim();
        if (totalNames.indexOf(value) !== -1) {
            setNameError('名称重复');
        } else {
            setNameError('');
        }
    };

    const onFinishInput = (value: string, continueAdd?: boolean) => {
        const name = value.trim();
        if (name && name !== propertyItem.name && !nameError) {
            handleNameChange?.({
                ...propertyItem,
                name,
            }, continueAdd);
        }
        setNameError('');
        setEditingId();
    };

    const handleBlur = useMemoizedFn((event: React.ChangeEvent<HTMLInputElement>) => {
        onFinishInput(event.target.value);
    });

    const handlePressEnter = useMemoizedFn((event: React.KeyboardEvent<HTMLInputElement>) => {
        onFinishInput(event.currentTarget.value);
    });

    const handleEdit = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
        setEditingId(propertyItem.id);
    }, [propertyItem, setEditingId]);

    if (editing) {
        return (
            <React.Fragment>
                <Input
                    autoFocus
                    size='small'
                    status={nameError? 'error' : undefined}
                    bordered={nameError? true : false}
                    defaultValue={propertyItem.name}
                    onClick={(e) => e.stopPropagation()}
                    onChange={onTextChange}
                    onBlur={handleBlur}
                    onPressEnter={handlePressEnter}
                    style={{paddingLeft: 0, paddingRight: 0, width: '100%'}}
                    maxLength={FIELD_NAME_MAX_LENGTH}
                />
                <div className='error-text'>{nameError}</div>
            </React.Fragment>
        );
    }
    return (
        <div className='property-name-cell'>
            <span onClick={handleEdit}>{propertyItem.name}</span>
            <Icon type='edit' onClick={handleEdit}/>
        </div>
    );
};

type PropertyAddItemProps = {
    defaultType: CUSTOM_FIELD_TYPES
    typeOptions: CUSTOM_FIELD_TYPES[]
    totalNames: string[]
    onSave: (name: string, type: CUSTOM_FIELD_TYPES, continueAdd?: boolean) => void
    onCancel: () => void
    addRowHash: string
};
const PropertyAddItem: React.FC<PropertyAddItemProps> = ({
    defaultType,
    typeOptions,
    totalNames,
    onSave,
    onCancel,
    addRowHash,
}) => {
    const [name, setName] = useState<string>();
    const [nameError, setNameError] = useState('');
    const [type, setType] = useState(defaultType);
    const addRow = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        setName(undefined);
    }, [addRowHash]);
    const onTextChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const value = event.target.value.trim();
        setName(value);
        if (totalNames.indexOf(value) !== -1) {
            setNameError('名称重复');
        } else {
            setNameError('');
        }
    };

    const handleEvent = useMemoizedFn(() => {
        if (name && !nameError) {
            onSave(name, type);
        } else {
            onCancel();
        }
    });

    const handlePressEnter = useMemoizedFn((continueAdd?: boolean) => {
        if (name && !nameError) {
            onSave(name, type, continueAdd);
        }
    });

    useClickOutsideRef(addRow, handleEvent);

    return (
        <tr ref={addRow}>
            <Table.Summary.Cell index={0}>
                <Input
                    autoFocus
                    size='small'
                    status={nameError? 'error' : undefined}
                    bordered={nameError? true : false}
                    value={name}
                    onChange={onTextChange}
                    onPressEnter={() => handlePressEnter(true)}
                    maxLength={FIELD_NAME_MAX_LENGTH}
                />
                <div className='error-text'>{nameError}</div>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} colSpan={2}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <FieldTypeSelect
                        value={type}
                        typeOptions={typeOptions}
                        triggerStyle={{
                            padding: 4,
                            display: 'inline-flex',
                            alignItems: 'center',
                            border: '1px solid #E5E6EB',
                            borderRadius: 4,
                        }}
                        onChange={setType}
                    />
                    <Button
                        onClick={onCancel}
                        size='small'
                        style={{
                            marginLeft: '16px',
                            marginRight: '8px',
                            borderRadius: 4
                        }}
                    >
                        <span>{'取消'}</span>
                    </Button>
                    <Button
                        type='primary'
                        onClick={handleEvent}
                        size='small'
                        style={{borderRadius: 4}}
                    >
                        <span>完成<Icon type='wancheng' style={{marginLeft: '4px'}}/></span>
                    </Button>
                </div>
            </Table.Summary.Cell>
        </tr>
    );
};

type PropertiesTableProps = {
    tableId?: string
    dataSource: CustomFieldConfigType[]
    basicNames?: string[]
    disabled?: boolean
    extendedField?: ColumnsType<CustomFieldConfigType>
    typeOptions: CUSTOM_FIELD_TYPES[]
    colorfulOptions?: boolean
    addText?: string
    onAddItem?: (item: CustomFieldConfigType, tableId: string) => void
    onModifyItem?: (item: CustomFieldConfigType, tableId: string) => void
    onMoveItem?: (params: MoveRowParams, tableId: string) => void
    triggerModifyItem?: (item: CustomFieldConfigType, tableId: string) => void
    columnNames?: string[]
};
const PropertiesTable: React.FC<PropertiesTableProps> = ({
    tableId = '',
    dataSource,
    basicNames,
    disabled,
    extendedField = [],
    typeOptions,
    colorfulOptions,
    addText,
    onAddItem,
    onModifyItem,
    onMoveItem,
    triggerModifyItem,
    columnNames
}) => {
    const [addingType, setAddingType] = useState<CUSTOM_FIELD_TYPES>();
    const [editingId, setEditingId] = useState<string>();
    const [addRowHash, setAddRowHash] = useState(`${new Date().getTime()}`);
    const totalNames = useMemo(() => {
        let names: string[];
        if (basicNames) {
            names = [...basicNames];
        } else {
            names = [];
        }
        dataSource.forEach((item) => {
            names.push(item.name);
        });

        return names;
    }, [dataSource, basicNames]);

    const onCancelAdd = useCallback(() => {
        setAddingType(undefined);
    }, []);

    const handleAddItem = useMemoizedFn((name: string, type: CUSTOM_FIELD_TYPES, continueAdd?: boolean) => {
        const newItem = {id: guid(), name, form_type: type};
        initNewFieldProps(newItem, {colorfulOptions});
        onAddItem?.(newItem, tableId);
        setAddRowHash(`${new Date().getTime()}`);
        if (!continueAdd) {
            onCancelAdd();
        }
        scrollToBottom(ScrollTableLayout);
    });

    const handleNameChange = useCallback((item: CustomFieldConfigType) => {
        onModifyItem?.(item, tableId);
    }, [onModifyItem, tableId]);

    const onMoveRow = useCallback((params: MoveRowParams) => {
        onMoveItem?.(params, tableId);
    }, [onMoveItem, tableId]);

    const renderNameCell = (value: string, record: CustomFieldConfigType) => {
        if (disabled || record.basic) {
            return record.name;
        }

        const editing = record.id === editingId;
        let dragIcon;
        if (!editing) {
            dragIcon = <div className='draggable-row-bar'><Icon type='drag-bar'/></div>;
        }
        return (
            <React.Fragment>
                <NameCell
                    propertyItem={record}
                    totalNames={totalNames}
                    handleNameChange={handleNameChange}
                    editing={editing}
                    setEditingId={setEditingId}
                />
                {dragIcon}
            </React.Fragment>
        );
    };

    const renderSummary = () => {
        if (onAddItem && !disabled) {
            if (addingType) {
                return (
                    <PropertyAddItem
                        defaultType={addingType}
                        typeOptions={typeOptions}
                        totalNames={totalNames}
                        onSave={handleAddItem}
                        onCancel={onCancelAdd}
                        addRowHash={addRowHash}
                    />
                );
            } else {
                return (
                    <tr onClick={() => setAddingType(CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT)}>
                        <Table.Summary.Cell index={0} colSpan={3}>
                            <div className='add-property-btn'>
                                <Icon
                                    type='plus'
                                    style={{marginRight: '4px'}}
                                />
                                <Button
                                    type='text'
                                    size='small'
                                    className='add-property-item-btn'
                                >{addText || '新建自定义列'}</Button>
                                <FieldTypeSelect
                                    typeOptions={typeOptions}
                                    onChange={setAddingType}
                                />
                            </div>
                        </Table.Summary.Cell>
                    </tr>
                );
            }
        }
    };

    return (
        <Table
            className='properties-table'
            bordered={false}
            rowKey='id'
            components={{body: {row: DraggableRow}}}
            columns={[
                {
                    title: columnNames?.[0] || '属性名称',
                    dataIndex: 'name',
                    render: renderNameCell,
                }, {
                    title: '类型',
                    dataIndex: 'form_type',
                    width: '124px',
                    render: (value) => <FieldTypeItem type={value}/>,
                },
                ...extendedField
            ]}
            dataSource={dataSource}
            pagination={false}
            onRow={(record, index) => {
                return ({
                    tableId,
                    data: record,
                    index,
                    disabled: disabled || (record.id === editingId),
                    style: {cursor: 'pointer'},
                    moveItem: onMoveRow,
                    onClick: () => {
                        triggerModifyItem?.(record, tableId);
                    }
                });
            }}
            summary={renderSummary}
        />
    );
};

export default PropertiesTable;
