import React, {useContext, useEffect, useState} from 'react';

import {CallBackFunc} from 'types/general';
import {Staff} from 'types/staffs';

import GlobalContext from 'context/global_context';

import DepartmentMemberSelect from '../department_member_select/department_member_select.jsx';
import LoadingScreen from 'components/loading_screen';
import {STAFF_KEY} from 'components/organization_tree/tree_utils';

export interface MemberSelectProps {
    singleChoice?: boolean,
    preprocessing?: boolean,
    originMemberIds?: string[],
    onSelected: (members: Map<string, Staff>) => void,
    onClose: CallBackFunc,
}

export interface SelectItem {
    type: string,
    id: string,
}

export interface TypeOriginItem extends SelectItem {
    disabled?: boolean,
    selected?: boolean,
}

// 组件单独处理preprocessing；
// 在此处拉取数据，与“上层需要依照所有userId处理preprocessing”的特殊逻辑有关
const MemberSelect: React.FC<MemberSelectProps> = (props) => {
    const [originItems, setOriginItems] = useState<TypeOriginItem[]>();

    const {singleChoice, preprocessing, originMemberIds, onSelected, onClose} = props;
    const {staffs, loadStaffsIfNeeded, departmentFullPath} = useContext(GlobalContext);

    useEffect(() => {
        if (originMemberIds && originMemberIds.length) {
            const items: TypeOriginItem[] = [];
            for (const memberId of originMemberIds) {
                items.push({
                    type: STAFF_KEY,
                    id: memberId,
                    selected: true,
                    disabled: true,
                });
            }
            setOriginItems(items);
        }
    }, [originMemberIds]);

    if (preprocessing) {
        return <LoadingScreen/>;
    }

    const handleSelected = (items: SelectItem[]) => {
        const members = new Map();
        items.forEach(({type, id}) => {
            if (type !== STAFF_KEY) {
                return;
            }

            if (staffs[id]) {
                members.set(id, staffs[id]);
            }
        });
        onSelected(members);
    };

    return (
        <DepartmentMemberSelect
            staffs={staffs}
            loadStaffsIfNeeded={loadStaffsIfNeeded}
            departmentFullPath={departmentFullPath}

            originItems={originItems}

            singleChoice={singleChoice}
            onSelected={handleSelected}
            onClose={onClose}
        />
    );
};

export default MemberSelect;
