import React from 'react';

import {IAIClient} from 'server/client';
import {envEnum} from 'utils/constants/general';

const GoToWrapper: React.FC<{text?: string}> = ({text}) => {
    const handleGoTo = () => {
        window.top?.window?.postMessage?.('navigateToOrganization', '*');
    };

    const appEnvironment: string | undefined = IAIClient.getAppType() || envEnum.ONLINE;
    const inOnline = appEnvironment === envEnum.ONLINE;

    const renderText = text || '去调整';

    if (inOnline) {
        return (
            <span
                className={'location-text'}
                onClick={handleGoTo}
            >
                {renderText}
            </span>
        );
    }
    return <span>{renderText}</span>;
};

export default GoToWrapper;
