import React, {useState, useEffect, useCallback} from 'react';
import {message} from 'antd';

import {loadEnterpriseInfoById} from 'server/actions/organization';
import {loadClientConfig, loadUser} from 'server/actions/general';
import {addRole, loadFeatureList, loadRoleList, modifyRole, removeRole} from 'server/actions/roles';
import {EnterpriseAll} from 'utils/constants/general';

import {ApplicationFeatures, Feature, ModuleFeatures} from 'types/feature';
import {DepartmentPath} from 'types/departments';
import {Staff} from 'types/staffs';
import {DepartmentNode, EnterpriseInfo, EnterpriseSummary} from 'types/organization';
import {EnterpriseRoles, Role, RoleForm} from 'types/roles';
import {UserType} from 'types/users';
import {CallBackFunc} from 'types/general';


import useOrganization from './organization_hook';
interface ProviderProps {
    children: React.ReactNode,
}
export interface GlobalState {
    clientConfig?: any,
    userInfo?: UserType,
    setUserInfo: CallBackFunc,
    managerList?: string[],
    receivedManagerList: CallBackFunc,
    organization?: DepartmentNode,
    oExcludeNoIsv?: DepartmentNode,
    loadOrganizationData: CallBackFunc,
    loadOExcludeNoIsv?: () => void,
    enterpriseInfo?: EnterpriseInfo,
    loadEnterpriseInfo: CallBackFunc,
    enterpriseSummary?: EnterpriseSummary,
    setEnterpriseSummary: CallBackFunc,
    departmentFullPath: DepartmentPath,
    totalUserIds: string[],
    staffs: {[key: string]: Staff | undefined},
    loadStaffsIfNeeded: ((userIds: string[]) => Promise<{data?: any, error?: any}>),
    enterpriseRoles?: EnterpriseRoles,
    loadEnterpriseRoles: CallBackFunc,
    addEnterpriseRole: (role: RoleForm, onSuccess: () => void, onFail: () => void) => void,
    removeEnterpriseRole: CallBackFunc,
    modifyEnterpriseRole: (id: number, role: RoleForm, onSuccess: () => void, onFail: () => void) => void,
    enterpriseFeatures?: ApplicationFeatures[],
    loadEnterpriseFeatures: CallBackFunc,
}

const voidFunction = () => {
    //
};

const defaultContextState = {
    setUserInfo: voidFunction,
    receivedManagerList: voidFunction,
    loadOrganizationData: voidFunction,
    loadEnterpriseInfo: voidFunction,
    setEnterpriseSummary: voidFunction,
    loadStaffsIfNeeded: () => Promise.resolve({data: false}),
    departmentFullPath: {},
    totalUserIds: [],
    staffs: {},
    enterpriseRoles: undefined,
    loadEnterpriseRoles: voidFunction,
    addEnterpriseRole: () => Promise.resolve({data: undefined, error: undefined}),
    removeEnterpriseRole: () => Promise.resolve({error: undefined}),
    modifyEnterpriseRole: () => Promise.resolve({error: undefined}),
    enterpriseFeatures: undefined,
    loadEnterpriseFeatures: voidFunction,
};
const GlobalContext = React.createContext<GlobalState>(defaultContextState);

export const Provider: React.FC<ProviderProps> = (props: ProviderProps): JSX.Element => {
    // 全局配置信息
    const [initialized, setInitialized] = useState(false);
    const [clientConfig, setClientConfig] = useState(null);
    const [userInfo, setUserInfo] = useState<UserType>();

    const [managerList, setManagerList] = useState<undefined | string[]>(undefined);
    const {
        organization,
        oExcludeNoIsv,
        departmentFullPath,
        totalUserIds,
        staffs,
        loadOrganizationData,
        loadOExcludeNoIsv,
        loadStaffsIfNeeded,
    } = useOrganization();
    const [enterpriseInfo, setEnterpriseInfo] = useState<undefined | EnterpriseInfo>(undefined);
    const [enterpriseSummary, setEnterpriseSummary] = useState<undefined | EnterpriseSummary>(undefined);
    const [enterpriseRoles, setEnterpriseRoles] = useState<EnterpriseRoles | undefined>(undefined);
    const [enterpriseFeatures, setEnterpriseFeatures] = useState<ApplicationFeatures[] | undefined>(undefined);

    const loadEnterpriseInfo = useCallback(() => {
        loadEnterpriseInfoById().then(({data}) => {
            if (data) {
                setEnterpriseInfo(data);
            }
        });
    }, []);

    const loadEnterpriseRoles = useCallback(() => {
        loadRoleList().then(({data, error}) => {
            if (error) {
                message.error('获取角色列表失败');
            } else if (data) {
                const enterpriseRoles: EnterpriseRoles = {
                    enterpriseRoles: [],
                    systemRoles: [],
                    rolesMap: new Map(),
                };

                if (data.length) {
                    for (const role of data) {
                        enterpriseRoles.rolesMap.set(role.id, role);
                        if (role.enterprise_id === EnterpriseAll) {
                            enterpriseRoles.systemRoles.push(role);
                        } else {
                            enterpriseRoles.enterpriseRoles.push(role);
                        }
                    }
                }

                setEnterpriseRoles(enterpriseRoles);
            }
        });
    }, []);

    const addEnterpriseRole = useCallback((role: RoleForm, onSuccess: () => void, onFail: () => void) => {
        addRole(role).then(({data, error}) => {
            if (error) {
                if (error.server_error_id === 'invalid_alias_name_exist') {
                    message.error('角色名称重复');
                } else {
                    message.error('新增角色失败');
                }
                onFail();
            } else if (data) {
                setEnterpriseRoles((originRoles) => {
                    if (!originRoles) {
                        return;
                    }

                    const {enterpriseRoles, systemRoles, rolesMap} = originRoles;
                    rolesMap.set(data.id, data);
                    return {
                        enterpriseRoles: [...enterpriseRoles, data],
                        systemRoles,
                        rolesMap,
                    };
                });

                onSuccess();
            }
        });
    }, []);

    const removeEnterpriseRole = useCallback((roleId: number, onSuccess: () => void, onFail: () => void) => {
        removeRole(roleId).then(({error}) => {
            if (error) {
                message.error('删除角色失败');
                onFail();
            } else {
                setEnterpriseRoles((originRoles) => {
                    if (!originRoles) {
                        return;
                    }

                    const {enterpriseRoles, systemRoles, rolesMap} = originRoles;
                    rolesMap.delete(roleId);
                    return {
                        enterpriseRoles: enterpriseRoles.filter((role) => role.id !== roleId),
                        systemRoles,
                        rolesMap,
                    };
                });

                onSuccess();
            }
        });
    }, []);

    const modifyEnterpriseRole = useCallback((id: number, form: RoleForm, onSuccess: () => void, onFail: () => void) => {
        modifyRole(id, form).then(({error}) => {
            if (error) {
                if (error.server_error_id === 'invalid_alias_name_exist') {
                    message.error('角色名称重复');
                } else {
                    message.error('修改角色失败');
                }
                onFail();
            } else {
                setEnterpriseRoles((originRoles) => {
                    if (!originRoles) {
                        return;
                    }

                    const {enterpriseRoles, systemRoles, rolesMap} = originRoles;
                    const newEnterpriseRoles: Role[] = [];
                    for (let role of enterpriseRoles) {
                        if (role.id === id) {
                            role = {
                                ...role,
                                ...form,
                            };

                            if (form.alias_name) {
                                role.name = form.alias_name;
                            }

                            rolesMap.set(id, role);
                        }

                        newEnterpriseRoles.push(role);
                    }

                    return {
                        enterpriseRoles: newEnterpriseRoles,
                        systemRoles,
                        rolesMap,
                    };
                });

                onSuccess();
            }
        });
    }, []);

    const loadEnterpriseFeatures = useCallback(() => {
        loadFeatureList().then(({data, error}) => {
            if (error) {
                message.error('获取权限列表失败');
            } else if (data) {
                const enterpriseFeatures: ApplicationFeatures[] = [];
                const applicationMap: Map<string, ApplicationFeatures> = new Map();
                const moduleMap: Map<string, ModuleFeatures> = new Map();
                if (data.length) {
                    for (const feature of data) {
                        let applicationFeatures: ApplicationFeatures | undefined = applicationMap.get(feature.application);
                        if (!applicationFeatures) {
                            const modules: ModuleFeatures[] = [];
                            applicationFeatures = {
                                application: feature.application,
                                application_name: feature.application_name,
                                modules,
                            };

                            applicationMap.set(feature.application, applicationFeatures);
                            enterpriseFeatures.push(applicationFeatures);
                        }

                        let moduleFeatures: ModuleFeatures | undefined = moduleMap.get(`${feature.application}-${feature.module}`);
                        if (!moduleFeatures) {
                            const features: Feature[] = [];
                            moduleFeatures = {
                                module: feature.module,
                                module_name: feature.module_name,
                                features,
                            };
                            moduleMap.set(`${feature.application}-${feature.module}`, moduleFeatures);
                            applicationFeatures.modules.push(moduleFeatures);
                        }

                        moduleFeatures.features.push(feature);
                    }
                }

                setEnterpriseFeatures(enterpriseFeatures);
            }
        });
    }, []);

    useEffect(() => {
        // 初始化拉取配置信息
        loadClientConfig().then(({error, data}) => {
            if (error) {
                message.error('身份登录过期');
            } else if (data) {
                setClientConfig(data);
                setInitialized(true);
            }
        });
        loadUser().then(({data}) => {
            if (data) {
                setUserInfo(data);
            }
        });
    }, []);

    const receivedManagerList = (managerList: Array<string>) => {
        setManagerList(managerList);
    };

    if (!initialized) {
        return <></>;
    }

    return (
        <GlobalContext.Provider
            value={{
                clientConfig,
                userInfo,
                setUserInfo,
                managerList,
                receivedManagerList,
                organization,
                loadOrganizationData,
                oExcludeNoIsv,
                loadOExcludeNoIsv,
                enterpriseInfo,
                loadEnterpriseInfo,
                enterpriseSummary,
                setEnterpriseSummary,
                departmentFullPath,
                totalUserIds,
                staffs,
                loadStaffsIfNeeded,
                enterpriseRoles,
                loadEnterpriseRoles,
                addEnterpriseRole,
                removeEnterpriseRole,
                modifyEnterpriseRole,
                enterpriseFeatures,
                loadEnterpriseFeatures,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalContext;
