import {Button, Modal, Form, Input} from 'antd';

import React, {useEffect, useState} from 'react';

import Icon from 'components/icon';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';

export type DeleteConfirmParams = {
    title: string,
    subTitle?: string,
    placeholder?: string,
    successCallback: () => void,
};

const DeleteConfirm: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [confirming, setConfirming] = useState(false);

    const [paramsCache, setParamsCache] = useState<DeleteConfirmParams>();

    useEffect(() => {
        EventEmitter.addListener(EventTypeEnum.DELETE_CONFIRM, openDialog);
        return () => {
            EventEmitter.removeListener(EventTypeEnum.DELETE_CONFIRM, openDialog);
        };
    }, []);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        onValueChange(value);
    };

    const onValueChange = (value: string) => {
        setValue(value);
        if (value !== paramsCache?.title) {
            setErrorMsg(`请输入正确的${paramsCache?.placeholder || '名称'}`);
        } else {
            setErrorMsg('');
        }
    };

    const openDialog = (params: DeleteConfirmParams) => {
        setVisible(true);
        setParamsCache(params);
    };

    const closeDialog = () => {
        setVisible(false);
        setValue('');
        setErrorMsg('');
        setParamsCache(undefined);
    };

    const onConfirm = async () => {
        setConfirming(true);
        if (paramsCache?.successCallback) {
            try {
                await paramsCache.successCallback();
            } catch {
                setConfirming(false);
                return;
            }
        }
        setConfirming(false);
        closeDialog();
    };

    if (!paramsCache) {
        return null;
    }

    return (
        <Modal
            className='delete-comfirm-dialog'
            open={visible}
            footer={false}
            closable={false}
            maskClosable={false}
            width={500}
            centered={true}
        >
            <div className='delete-comfirm-dialog-wrapper'>
                <Icon
                    type='warning'
                    size={25}
                />
                <div className='delete-comfirm-dialog-content'>
                    <div className='delete-comfirm-title'>
                        <div className='highlight-title'>{`你确定要删除“${paramsCache.title}”吗？`}</div>
                        <div>{paramsCache.subTitle || '删除后无法恢复，请务必谨慎操作。'}</div>
                    </div>
                    <div className='delete-comfirm-input'>
                        <Form>
                            <Form.Item
                                validateStatus={errorMsg ? 'error' : undefined}
                                help={errorMsg}
                            >
                                <Input
                                    value={value}
                                    onChange={onInputChange}
                                    placeholder={`请输入${paramsCache.placeholder || '名称'}以确认操作`}
                                />
                            </Form.Item>
                        </Form>
                        <span
                            className='delete-confirm-shortcut'
                            onClick={() => onValueChange(paramsCache.title)}
                        >{'一键填入'}</span>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <Button
                            onClick={closeDialog}
                            style={{marginRight: 12, width: 80, height: 32}}
                        >{'取消'}</Button>
                        <Button
                            type='primary'
                            danger={true}
                            disabled={!value || Boolean(errorMsg)}
                            onClick={onConfirm}
                            style={{width: 80, height: 32}}
                            loading={confirming}
                        >{'删除'}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteConfirm;
