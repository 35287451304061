import {IAIClient} from 'server/client';
import {StatusOK} from 'server/types/client';
import {DepartmentWithMembers} from 'types/departments';

export async function modifyDepartment(departmentData: object, department_id: number, modifyMode: string) {
    let data: StatusOK;
    try {
        data = await IAIClient.modifyDepartment(departmentData, department_id, modifyMode);
    } catch (error) {
        return {error};
    }
    return {data};
}

export async function removeHrg(departmentId: number) {
    let data: StatusOK;
    try {
        data = await IAIClient.removeHrg(departmentId);
    } catch (error) {
        return {error};
    }
    return {data};
}

/**
 *
 * @param userIds
 * @returns 返回结构类型--非标准
 */
export async function loadStaffsDepartments(userIds: string[]) {
    let data: {
        enterprise_id: string,
        departments: {
            [departmentId: string]: DepartmentWithMembers,
        },
        staffs_departments: {
            user_id: string,
            department_ids: number[],
            main_department_id: number,
        }[]
    };
    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        data = await IAIClient.loadStaffsDepartments(enterpriseId, userIds);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadStaffsDepartmentFullPaths(userId: string) {
    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        const data = await IAIClient.loadStaffsDepartmentFullPaths(enterpriseId, userId);
        return {data};
    } catch (error) {
        return {error};
    }
}
