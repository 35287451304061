import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Button, Form} from 'antd';
import {useMemoizedFn} from 'ahooks';
import uuid from 'react-uuid';

import {addInOrder} from 'utils/utils';
import {BASE_COLOR} from 'utils/constants/custom_field';
import {repeatCheck} from '../utils';
import type {FieldOptionType} from 'types/custom_field';
import type {NormalRuleProps, OptionError, PrivateOption} from '../utils';

import DraggableOption from './draggable_option';

export type OptionStatusRuleRef = {
    getOptions: () => FieldOptionType[] | undefined
    optionErrors: React.MutableRefObject<OptionError | undefined>
};

type Props = Omit<NormalRuleProps, 'onModify'>;

const OptionStatusRule = React.forwardRef<OptionStatusRuleRef, Props>(({
    fieldConfig,
    disabled
}, ref) => {
    // 组件将原数据转换成 “PrivateOption”进行渲染、交互
    // 表单提交时 父组件通过 getOptions 获取完整的 FieldOptionType（原数据格式）
    const [labelNames, setLabelNames] = useState<string[]>([]);
    const [options, setOptions] = useState<Array<PrivateOption[]>>();
    const optionErrors = useRef<OptionError>();

    // 判定为无效数据源后，不允许进行任何的选项操作
    const [invalid, setInvalid] = useState(false);

    const [needFocusId, setNeedFocusId] = useState<string>();

    useEffect(() => {
        if (fieldConfig.props?.options && fieldConfig.props.options.length) {
            if (fieldConfig.props.options.length === 3) {
                setInvalid(false);
            } else {
                setInvalid(true);
            }
            const names: string[] = [];
            const items: Array<PrivateOption[]> = [];
            fieldConfig.props.options.forEach((option) => {
                names.push(option.label);

                const statusOptions: PrivateOption[] = [];
                let defaultValue: string | undefined;
                if (option.child_default_value_modifiable && option.child_default_value) {
                    defaultValue = option.child_default_value;
                }
                option.children?.forEach((status) => {
                    let defaultFlag: boolean | undefined;
                    if (defaultValue) {
                        defaultFlag = status.value === defaultValue;
                    }
                    statusOptions.push({id: uuid(), text: status.value, color: status.color, defaultFlag});
                });
                items.push(statusOptions);
            });
            setLabelNames(names);
            setOptions(items);
        }
    }, [fieldConfig]);

    const getOptions = useMemoizedFn(() => {
        const originalData = fieldConfig.props?.options;
        if (originalData) {
            if (invalid) {
                // 无效 -> 返回原数据
                return originalData;
            }
            const result: FieldOptionType[] = originalData.map((item, index) => {
                const settingOptions = options?.[index];
                let defaultValue: string | undefined;
                const children = settingOptions?.map((option) => {
                    if (option.defaultFlag) {
                        defaultValue = option.text;
                    }
                    return ({
                        label: option.text,
                        value: option.text,
                        color: option.color
                    });
                });

                // 以下均为容错逻辑
                if (item.child_default_value_modifiable) {
                    if (defaultValue === undefined && children?.[0]) {
                        defaultValue = children[0].value;
                    }
                } else if (defaultValue) {
                    // 默认值不允许修改时[child_default_value_modifiable] -> defaultValue 异常有值（订正掉）
                    defaultValue = undefined;
                }
                return {
                    ...item,
                    child_default_value: defaultValue || item.child_default_value,
                    children,
                };
            });
            return result;
        }
    });

    useImperativeHandle(ref, () => {
        return {
            getOptions,
            optionErrors,
        };
    }, [getOptions]);

    const addNewOption = (groupIndex: number) => {
        if (groupIndex && options) {
            const nextOptions = [...options];
            const id = uuid();
            const newOptions = [...nextOptions[groupIndex], {id: id, text: '', color: BASE_COLOR[0]}];

            nextOptions[groupIndex] = newOptions;
            setOptions(nextOptions);
            setNeedFocusId(id);
        }
    };

    const privateRepeatCheck = (changedOptions: Array<PrivateOption[]>) => {
        const checkResult = repeatCheck(changedOptions.flat());
        optionErrors.current = checkResult;
    };

    const deleteOption = useMemoizedFn((index: number, groupIndex: string) => {
        const gIndex = parseInt(groupIndex);
        if (gIndex && options) {
            // 第一组（index = 0）均不允许增删
            const nextOptions = [...options];
            const newOptions = [...nextOptions[gIndex]];
            if (newOptions[index].defaultFlag && newOptions[0]) {
                // 当默认值被删除，重新选取第一个选项打上默认标识
                newOptions[0].defaultFlag = true;
            }
            newOptions.splice(index, 1);

            nextOptions[gIndex] = newOptions;
            privateRepeatCheck(nextOptions);

            setOptions(nextOptions);
        }
    });

    const resetOption = useMemoizedFn((index: number, groupIndex: string) => {
        const gIndex = parseInt(groupIndex);
        if (!isNaN(gIndex) && options) {
            const nextOptions = [...options];
            const newOptions = [...nextOptions[gIndex]];
            newOptions[index].text = addInOrder(nextOptions.flat().map((item) => item.text), '未命名');

            nextOptions[gIndex] = newOptions;
            setOptions(nextOptions);
        }
    });

    const changeOption = useMemoizedFn((index: number, value: string, groupIndex: string) => {
        const gIndex = parseInt(groupIndex);
        if (!isNaN(gIndex) && options) {
            const nextOptions = [...options];
            const newOptions = [...nextOptions[gIndex]];
            newOptions[index].text = value;

            nextOptions[gIndex] = newOptions;
            privateRepeatCheck(nextOptions);

            setOptions(nextOptions);
        }
    });

    const moveOption = useMemoizedFn((dragIndex: number, hoverIndex: number, groupIndex: string) => {
        const gIndex = parseInt(groupIndex);
        if (!isNaN(gIndex) && options) {
            const nextOptions = [...options];
            const newOptions = [...nextOptions[gIndex]];
            const dragItem = newOptions[dragIndex];
            newOptions.splice(dragIndex, 1);
            newOptions.splice(hoverIndex, 0, dragItem);

            nextOptions[gIndex] = newOptions;

            setOptions(nextOptions);
        }
    });

    const changeOptionColor = useMemoizedFn((index: number, color: string, groupIndex: string) => {
        const gIndex = parseInt(groupIndex);
        if (!isNaN(gIndex) && options) {
            const nextOptions = [...options];
            const newOptions = [...nextOptions[gIndex]];
            newOptions[index].color = color;

            nextOptions[gIndex] = newOptions;
            setOptions(nextOptions);
        }
    });

    const renderOption = (
        option: PrivateOption,
        index: number,
        groupIndex: number,
        editable: boolean,
        deletable?: boolean,
        moveable?: boolean,
    ) => {
        const error = optionErrors.current?.[option.id];
        let errorText;
        if (error) {
            errorText = (
                <span className='error-text'>
                    {error}
                </span>
            );
        }

        let changeItemAction;
        let changeColorAction;
        if (editable) {
            changeItemAction = changeOption;
            changeColorAction = changeOptionColor;
        }
        let deleteItemAction;
        if (deletable) {
            deleteItemAction = deleteOption;
        }
        let moveItemAction;
        if (moveable) {
            moveItemAction = moveOption;
        }

        return (
            <div
                key={option.id}
                className={`i-field-f-option-wrapper${error ? ' i-field-f-error-option' : ''}`}
            >
                <DraggableOption
                    index={index}
                    id={option.id}
                    text={option.text}
                    groupId={`${groupIndex}`}
                    color={option.color}
                    needFocusId={needFocusId}
                    deleteOption={deleteItemAction}
                    resetOption={resetOption}
                    changeOption={changeItemAction}
                    moveOption={moveItemAction}
                    onColorChange={changeColorAction}
                    onContinueAdd={() => addNewOption(groupIndex)}
                />
                {errorText}
            </div>
        );
    };

    const renderOptions = (groupIndex: number) => {
        const optionGroup = options?.[groupIndex];
        const groupName = labelNames[groupIndex];

        const editable = disabled !== true;
        if (optionGroup) {
            let addBtn;
            if (groupIndex !== 0 && editable) {
                addBtn = (
                    <Button
                        className='i-field-f-select-add_btn'
                        type='primary'
                        ghost={true}
                        onClick={() => addNewOption(groupIndex)}
                    >添加新选项</Button>
                );
            }
            return (
                <React.Fragment>
                    <div className='field-option-group'>
                        <div className='field-option-group-name'>{groupName}</div>
                        {optionGroup.map((statusOption, index) => {
                            let deletable = editable;
                            if (
                                groupIndex === 0 ||
                                (groupIndex === 2 && optionGroup.length === 1)
                            ) {
                                deletable = false;
                            }

                            let moveable = editable;
                            if (optionGroup.length === 1) {
                                moveable = false;
                            }

                            return renderOption(
                                statusOption,
                                index,
                                groupIndex,
                                editable,
                                deletable,
                                moveable
                            );
                        })}
                    </div>
                    {addBtn}
                </React.Fragment>
            );
        }
    };

    return (
        <Form.Item
            label='选项内容'
            className='i-field-f-select-options'
        >
            {/* 这里没有使用遍历渲染的原因： 使用key做渲染的列表再嵌套列表 渲染会出现异常 */}
            {renderOptions(0)}
            {renderOptions(1)}
            {renderOptions(2)}
        </Form.Item>
    );
});

OptionStatusRule.displayName = 'OptionStatusRule';
export default OptionStatusRule;
