import {DepartmentNode} from 'types/organization';
import {isLeafDepartment} from 'utils/department';

export const DEPARTMENT_KEY = 'department';
export const STAFF_KEY = 'staff';

export const getItemExisted = (key: string | number, existedKeys?: (string | number)[]) => {
    if (existedKeys) {
        const index = existedKeys.findIndex((item) => {
            // 根据特殊情况可将item和key转成string类型，再做判断
            return `${item}` === `${key}`;
        });
        return index !== -1;
    }
    return false;
};

/**
 * 计算antd-tree中节点的checkbox状态
 * 每个节点独立进行“选中”检查
 */
export const independentlyCheck = (tree: DepartmentNode, selectedKeys: (string | number)[]) => {
    const checked: string[] = [];

    const traverse = (node: DepartmentNode) => {
        const managerId = node.roles_map?.Manager;
        if (managerId) {
            if (getItemExisted(managerId, selectedKeys)) {
                checked.push(`${STAFF_KEY}/${managerId}/${node.id}-Manager`);
            }
        }
        node.member_ids.forEach((userId) => {
            if (getItemExisted(userId, selectedKeys)) {
                checked.push(`${STAFF_KEY}/${userId}/${node.id}-Member`);
            }
        });

        if (node.children) {
            node.children.forEach((child) => {
                traverse(child);
            });
        }

        if (getItemExisted(node.id, selectedKeys)) {
            checked.push(`${DEPARTMENT_KEY}/${node.id}/${node.parent_id}-${node.name}`);
        }
    };

    traverse(tree);
    return {checked, halfChecked: []};
};

/**
 * 计算antd-tree中节点的checkbox状态
 * “部门选中”受到“人员选中”的影响
 * PS: 如需修改disabledUserIds对返回值的影响，修改所有'disabled'开头的变量
 */
export const jointlyCheck = (tree: DepartmentNode, selectedUserIds: string[], disabledUserIds?: string[]) => {
    const checked: string[] = [];
    const halfChecked: string[] = [];

    const traverse = (node: DepartmentNode): -1 | 0 | 1 | 2 => {
        // -1:子部门不计算在内；0:不选；1:全选；2:半选
        let totalCount = 0;
        let memberCount = node.member_ids.length;
        const managerId = node.roles_map?.Manager;
        if (managerId) {
            const disabled = getItemExisted(managerId, disabledUserIds);
            if (!disabled) {
                memberCount += 1;
            }
            if (getItemExisted(managerId, selectedUserIds)) {
                checked.push(`${STAFF_KEY}/${managerId}/${node.id}-Manager`);
                if (!disabled) {
                    totalCount++;
                }
            }
        }
        node.member_ids.forEach((userId) => {
            const disabled = getItemExisted(userId, disabledUserIds);
            if (disabled) {
                memberCount--;
            }
            if (getItemExisted(userId, selectedUserIds)) {
                checked.push(`${STAFF_KEY}/${userId}/${node.id}-Member`);
                if (!disabled) {
                    totalCount++;
                }
            }
        });
        let subCount = 0;
        let hasHalfChecked = false;
        if (node.children) {
            node.children.forEach((child) => {
                if (isLeafDepartment(child)) {
                    // 叶子部门节点（无子部门 && 无成员）不计入
                    return;
                }
                const subStatus = traverse(child);
                if (subStatus === -1) {
                    return;
                }
                subCount++;
                if (subStatus === 1) {
                    totalCount++;
                } else if (subStatus === 2) {
                    hasHalfChecked = true;
                }
            });
        }

        let currentStatus: -1 | 0 | 1 | 2;
        if (totalCount !== 0 && totalCount === (subCount + memberCount)) {
            currentStatus = 1;
            checked.push(`${DEPARTMENT_KEY}/${node.id}/${node.parent_id}-${node.name}`);
        } else if (totalCount === 0 && !hasHalfChecked) {
            if (subCount === 0 && memberCount === 0) {
                // 部门人员存在，且全员被禁选
                return -1;
            }
            return 0;
        } else {
            currentStatus = 2;
            halfChecked.push(`${DEPARTMENT_KEY}/${node.id}/${node.parent_id}-${node.name}`);
        }

        return currentStatus;
    };

    traverse(tree);
    return {checked, halfChecked};
};
