import React, {useState, useCallback, useMemo} from 'react';
import {useMemoizedFn} from 'ahooks';

import PropertiesTable from 'components/custom_field_setting/properties_table';
import CustomFieldDrawer from 'components/custom_field_setting/custom_field_drawer';
import InputComponent from 'components/custom_field_setting/input_component';
import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';
import type {MoveRowParams} from 'components/custom_field_setting/draggable_row';
import type {CustomFieldConfigType} from 'types/custom_field';

const TypeList = [
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_NUMBER,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_LONGTEXT,
];

type Props = {
    basicNames?: string[],
    customFieldConfig: CustomFieldConfigType[],

    // 返回一个新构造的CustomFieldConfigType
    onModify: (config: CustomFieldConfigType) => void,
    onDelete: (id: string) => void,
    onMove: (dragIndex: number, hoverIndex: number) => void
};

const StaffProperties: React.FC<Props> = ({
    basicNames,
    customFieldConfig,
    onModify,
    onDelete,
    onMove,
}) => {
    const [editingId, setEditingId] = useState<string>();
    const [creatingIdsCahce, setCreatingIdsCahce] = useState<string[]>([]);


    const modifyingItem = useMemo(() => {
        if (editingId) {
            return customFieldConfig.find((item) => item.id === editingId);
        }
    }, [customFieldConfig, editingId]);

    const onAdd = (item: CustomFieldConfigType) => {
        setCreatingIdsCahce((ids) => {
            return [...ids, item.id];
        });
        onModify(item);
    };

    const triggerModifyItem = useCallback((item: CustomFieldConfigType) => {
        setEditingId(item.id);
    }, []);

    const onDrawerClose = useCallback(() => {
        setEditingId(undefined);
    }, []);

    const onMoveItem = useMemoizedFn(({dragIndex, hoverIndex}: MoveRowParams) => {
        onMove(dragIndex, hoverIndex);
    });

    let fieldDrawer;
    if (modifyingItem) {
        const typeMutable = creatingIdsCahce.indexOf(modifyingItem.id) !== -1;
        fieldDrawer = (
            <CustomFieldDrawer
                basicNames={basicNames}
                totalCustomFields={customFieldConfig}
                typeOptions={TypeList}

                modifyItem={modifyingItem}
                onModify={onModify}
                onDelete={onDelete}
                handleClose={onDrawerClose}
                typeMutable={typeMutable}
                editing={true}
            />
        );
    }

    return (
        <div className='editable-list-container'>
            <PropertiesTable
                dataSource={customFieldConfig}
                basicNames={basicNames}
                extendedField={[{
                    dataIndex: 'preview',
                    width: '250px',
                    onCell: () => ({
                        onClick: (e) => e.stopPropagation()
                    }),
                    render: (value, record) => <InputComponent fieldConfig={record}/>
                }]}
                typeOptions={TypeList}
                addText='新建员工属性'
                onAddItem={onAdd}
                onModifyItem={onModify}
                onMoveItem={onMoveItem}
                triggerModifyItem={triggerModifyItem}
            />
            {fieldDrawer}
        </div>
    );
};

export default StaffProperties;
