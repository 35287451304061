import React, {useContext, useEffect, useState} from 'react';
import {Modal} from 'antd';

import DepartmentMemberSelect from 'components/department_member_select/department_member_select.jsx';

import {IconWithHighlight} from 'components/icon';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
import GlobalContext from 'context/global_context';
import {SelectItem} from 'components/member_selects/member_select';

export interface DepartmentMemberSelectModalProps {
    title?: string,
    singleChoice?: boolean,
    departmentSelectable?: boolean,
}

const defaultProps: Partial<DepartmentMemberSelectModalProps> = {
    title: '部门人员选择',
    singleChoice: false,
    departmentSelectable: true,
};

export interface DepartmentMemberSelectTriggerProps {
    title?: string,
    singleChoice?: boolean,
    originItems?: SelectItem[], // 作为禁选项处理
    onSelected: (items: SelectItem[]) => void,
    onVisible?: (visible: boolean) => void,
}

const DepartmentMemberSelectModal: React.FC<DepartmentMemberSelectModalProps> = (props) => {
    const {title, singleChoice, departmentSelectable} = props;
    const [visible, setVisible] = useState(false);
    const [triggerProps, setTriggerProps] = useState<DepartmentMemberSelectTriggerProps | undefined>();
    const {staffs, loadStaffsIfNeeded, departmentFullPath} = useContext(GlobalContext);

    useEffect(() => {
        EventEmitter.addListener(EventTypeEnum.OPEN_DEPARTMENT_MEMBER_SELECT_MODAL, triggerVisible);

        return () => {
            EventEmitter.removeListener(EventTypeEnum.OPEN_DEPARTMENT_MEMBER_SELECT_MODAL, triggerVisible);
        };
    }, []);

    const triggerVisible = (o: DepartmentMemberSelectTriggerProps) => {
        setTriggerProps(o);
        if (o.onVisible) {
            o.onVisible(true);
        }
        setVisible(true);
    };

    const handleSelected = (items: SelectItem[]) => {
        if (triggerProps && items.length) {
            triggerProps.onSelected(items);
        }

        handleClose();
    };

    const handleClose = () => {
        setVisible(false);
        if (triggerProps && triggerProps.onVisible) {
            triggerProps.onVisible(false);
        }
        setTriggerProps(undefined);
    };

    const renderContent = () => {
        return (
            <div className='member_selector-container'>
                <DepartmentMemberSelect
                    departmentSelectable={departmentSelectable}
                    originItems={triggerProps?.originItems}
                    singleChoice={triggerProps?.singleChoice || singleChoice}
                    onSelected={handleSelected}
                    onClose={handleClose}

                    loadStaffsIfNeeded={loadStaffsIfNeeded}
                    staffs={staffs}
                    departmentFullPath={departmentFullPath}
                />
            </div>
        );
    };

    return (
        <Modal
            title={triggerProps?.title || title}
            open={visible}
            onCancel={handleClose}
            width={778}
            footer={null}
            closeIcon={
                <IconWithHighlight
                    type='close_normal'
                    size={16}
                    color='#333333'
                />
            }
            destroyOnClose={true}
        >
            {renderContent()}
        </Modal>
    );
};

DepartmentMemberSelectModal.defaultProps = defaultProps;

export default DepartmentMemberSelectModal;
