import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';

import {Staff} from 'types/staffs';
import {IconWithHighlight} from 'components/icon';
import MemberSelect from 'components/member_selects/member_select';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';

export interface MemberSelectModalProps {
    title?: string,
    singleChoice?: boolean,
    preprocessing?: boolean,
}

const defaultProps: Partial<MemberSelectModalProps> = {
    title: '人员选择',
    singleChoice: false,
    preprocessing: false,
};

export interface MemberSelectTriggerProps {
    title?: string,
    singleChoice?: boolean,
    preprocessing?: boolean,
    originMemberIds?: string[], // 作为禁选项处理
    onSelected: (members: Map<string, Staff>) => void,
    onVisible?: (visible: boolean) => void,
}

const MemberSelectModal: React.FC<MemberSelectModalProps> = (props) => {
    const {title, singleChoice, preprocessing} = props;
    const [visible, setVisible] = useState(false);
    const [triggerProps, setTriggerProps] = useState<MemberSelectTriggerProps | undefined>();

    useEffect(() => {
        EventEmitter.addListener(EventTypeEnum.OPEN_MEMBER_SELECT_MODAL, triggerVisible);

        return () => {
            EventEmitter.removeListener(EventTypeEnum.OPEN_MEMBER_SELECT_MODAL, triggerVisible);
        };
    }, []);

    const triggerVisible = (o: MemberSelectTriggerProps) => {
        setTriggerProps(o);
        if (o.onVisible) {
            o.onVisible(true);
        }
        setVisible(true);
    };

    const handleConfirm = (members: Map<string, Staff>) => {
        if (triggerProps && members.size !== 0) {
            triggerProps.onSelected(members);
        }

        handleClose();
    };

    const handleClose = () => {
        setVisible(false);
        if (triggerProps && triggerProps.onVisible) {
            triggerProps.onVisible(false);
        }
        setTriggerProps(undefined);
    };

    const renderContent = () => {
        return (
            <div className='member_selector-container'>
                <MemberSelect
                    singleChoice={triggerProps?.singleChoice || singleChoice}
                    preprocessing={triggerProps?.preprocessing || preprocessing}
                    originMemberIds={triggerProps?.originMemberIds}
                    onSelected={handleConfirm}
                    onClose={handleClose}
                />
            </div>
        );
    };

    return (
        <Modal
            title={triggerProps?.title || title}
            open={visible}
            onCancel={handleClose}
            width={778}
            footer={null}
            closeIcon={
                <IconWithHighlight
                    type='close_normal'
                    size={16}
                    color='#333333'
                />
            }
            destroyOnClose={true}
        >
            {renderContent()}
        </Modal>
    );
};

MemberSelectModal.defaultProps = defaultProps;

export default MemberSelectModal;
