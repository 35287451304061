import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {message} from 'antd';

import GlobalContext from 'context/global_context';
import {addRoleFeatures, loadRoleFeatureTokens, removeRoleFeatures} from 'server/actions/roles';
import LoadingScreen from 'components/loading_screen';
import {BACKEND_MANAGEMENT} from 'utils/constants/permission';
import {ApplicationFeatures} from 'types/feature';

import Application from './application';

export interface AuthorizationProps {
    roleId: number,
}

const Authorization: React.FC<AuthorizationProps> = (props) => {
    const {url} = useRouteMatch();
    const [authorizedTokens, setAuthorizedTokens] = useState<{[key:string]: Map<string, boolean>}>({});

    const {roleId} = props;
    const {enterpriseFeatures} = useContext(GlobalContext);

    useEffect(() => {
        const tokensMap = authorizedTokens[roleId];
        if (!tokensMap) {
            loadRoleFeatureTokens(roleId).then(({error, data}) => {
                if (error) {
                    message.error('获取已授权列表失败');
                } else {
                    const roleTokens: Map<string, boolean> = new Map();
                    if (data) {
                        for (const token of data) {
                            roleTokens.set(token, true);
                        }
                    }

                    setAuthorizedTokens((originTokens) => {
                        return {
                            ...originTokens,
                            [roleId]: roleTokens,
                        };
                    });
                }
            });
        }
    }, [roleId]);

    const applicationFeaturesData: ApplicationFeatures | undefined = useMemo(() => {
        if (url.includes(BACKEND_MANAGEMENT.ROLE_MANAGEMENT_WORK)) {
            return enterpriseFeatures?.find((item) => item.application === 'Task');
        } else if (url.includes(BACKEND_MANAGEMENT.ROLE_MANAGEMENT_PERF)) {
            return enterpriseFeatures?.find((item) => item.application === 'Performance');
        }
    }, [url, enterpriseFeatures]);

    const getNewRoleTokens = (originTokens: {[key:string]: Map<string, boolean>}) => {
        const roleTokens = originTokens[roleId];
        const newRoleTokens: Map<string, boolean> = new Map();

        for (const [key, value] of roleTokens.entries()) {
            newRoleTokens.set(key, value);
        }

        return newRoleTokens;
    };

    const handleAuthorizeTokens = (ids: number[], tokens: string[]) => {
        addRoleFeatures(roleId, ids).then(({error}) => {
            if (error) {
                message.error('增加权限失败');
            } else {
                setAuthorizedTokens((originTokens) => {
                    const newRoleTokens = getNewRoleTokens(originTokens);

                    for (const token of tokens) {
                        newRoleTokens.set(token, true);
                    }

                    return {
                        ...originTokens,
                        [roleId]: newRoleTokens,
                    };
                });
            }
        });
    };

    const handleCancelTokens = (ids: number[], tokens: string[]) => {
        removeRoleFeatures(roleId, ids).then(({error}) => {
            if (error) {
                message.error('删除权限失败');
            } else {
                setAuthorizedTokens((originTokens) => {
                    const newRoleTokens = getNewRoleTokens(originTokens);

                    for (const token of tokens) {
                        newRoleTokens.delete(token);
                    }

                    return {
                        ...originTokens,
                        [roleId]: newRoleTokens,
                    };
                });
            }
        });
    };

    let content;
    if (!applicationFeaturesData) {
        content = <LoadingScreen/>;
    } else {
        content = (
            <Application
                key={applicationFeaturesData.application}
                application={applicationFeaturesData}
                authorizedTokens={authorizedTokens[roleId]}
                onAuthorizeTokens={handleAuthorizeTokens}
                onCancelTokens={handleCancelTokens}
            />
        );
    }

    return (
        <div className={'backend-role-setting-scroll'}>
            <div className={'backend-authorization-setting mx-16'}>
                {content}
            </div>
        </div>
    );
};

export default Authorization;
