import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Button, Form, Switch} from 'antd';
import uuid from 'react-uuid';
import {useMemoizedFn} from 'ahooks';

import {addInOrder} from 'utils/utils';
import {BASE_COLOR} from 'utils/constants/custom_field';
import {getEditable, repeatCheck} from '../utils';
import type {NormalRuleProps, OptionError, PrivateOption} from '../utils';

import DraggableOption from './draggable_option';

export type OptionRuleRef = {
    optionsRef: React.MutableRefObject<PrivateOption[] | undefined>
    optionErrors: React.MutableRefObject<OptionError | undefined>
    setOptions: React.Dispatch<React.SetStateAction<PrivateOption[]>>
    setColorSetting: React.Dispatch<React.SetStateAction<boolean>>
    colorfulRef: React.MutableRefObject<boolean>
};

type Props = Omit<NormalRuleProps, 'onModify'> & {colorfulOptions?: boolean};

const OptionRule = React.forwardRef<OptionRuleRef, Props>(({
    fieldConfig,
    colorfulOptions,
    disabled
}, ref) => {
    const [options, setOptions] = useState<PrivateOption[]>(
        fieldConfig?.props?.options?.map((item) => ({id: uuid(), text: item.value, color: item.color})) || []
    );
    const [colorSetting, setColorSetting] = useState(() => {
        if (fieldConfig.props?.colorful === false) {
            return false;
        }
        return true;
    });
    const optionsRef = useRef<PrivateOption[]>();
    const optionErrors = useRef<OptionError>();
    const colorfulRef = useRef<boolean>(colorSetting);

    const [needFocusId, setNeedFocusId] = useState<string>();

    useImperativeHandle(ref, () => ({
        optionsRef,
        optionErrors,
        setOptions,
        setColorSetting,
        colorfulRef,
    }), []);

    useEffect(() => {
        optionsRef.current = options;
    }, [options]);
    useEffect(() => {
        colorfulRef.current = colorSetting;
    }, [colorSetting]);

    const addNewOption = () => {
        setOptions((prevOptions) => {
            let color: string | undefined;
            if (colorfulOptions && colorSetting) {
                color = BASE_COLOR[0];
            }
            const id = uuid();
            setNeedFocusId(id);
            return [...prevOptions, {id: id, text: '', color}];
        });
    };

    const deleteOption = useCallback((index: number) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions.splice(index, 1);
            if (newOptions.length === 0) {
                newOptions.push({id: uuid(), text: addInOrder([], '选项')});
            }
            const checkResult = repeatCheck(newOptions);
            optionErrors.current = checkResult;
            return newOptions;
        });
    }, []);

    const resetOption = useCallback((index: number) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            if (index === 0 && newOptions.length === 1) {
                newOptions[index].text = addInOrder([], '选项');
            }
            return newOptions;
        });
    }, []);

    const changeOption = useCallback((index: number, value: string) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index].text = value;
            if (newOptions.length > 1) {
                const checkResult = repeatCheck(newOptions);
                optionErrors.current = checkResult;
            }
            return newOptions;
        });
    }, []);

    const changeOptionColor = useCallback((index: number, color: string) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index].color = color;
            return newOptions;
        });
    }, []);

    const moveOption = useCallback((dragIndex: number, hoverIndex: number) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            const dragItem = newOptions[dragIndex];
            newOptions.splice(dragIndex, 1);
            newOptions.splice(hoverIndex, 0, dragItem);
            return newOptions;
        });
    }, []);

    const handleColorfulChange = useMemoizedFn((value: boolean) => {
        setColorSetting(value);
        const newOptions = options.map((item) => {
            return {
                ...item,
                color: value ? BASE_COLOR[0] : undefined
            };
        });
        setOptions(newOptions);
    });

    const renderOption = (option: PrivateOption, index: number, deletable: boolean, editable: boolean) => {
        const error = optionErrors.current?.[option.id];
        let errorText;
        if (error) {
            errorText = (
                <span className='error-text'>
                    {error}
                </span>
            );
        }

        let deleteAction;
        if (deletable) {
            deleteAction = deleteOption;
        }
        let resetAction;
        let changeAction;
        let moveAction;
        let changeColorAction;
        if (editable) {
            resetAction = resetOption;
            changeAction = changeOption;
            moveAction = moveOption;
            changeColorAction = changeOptionColor;
        }

        return (
            <div
                key={option.id}
                className={`i-field-f-option-wrapper${error ? ' i-field-f-error-option' : ''}`}
            >
                <DraggableOption
                    index={index}
                    id={option.id}
                    text={option.text}
                    color={option.color}
                    needFocusId={needFocusId}
                    deleteOption={deleteAction}
                    resetOption={resetAction}
                    changeOption={changeAction}
                    moveOption={moveAction}
                    onColorChange={changeColorAction}
                    onContinueAdd={addNewOption}
                />
                {errorText}
            </div>
        );
    };

    const renderOptions = () => {
        const editable = getEditable(fieldConfig, 'options') && disabled !== true;
        const deletable = editable && options.length > 1;
        let addBtn;
        if (editable) {
            addBtn = (
                <Button
                    className='i-field-f-select-add_btn'
                    type='primary'
                    ghost={true}
                    onClick={addNewOption}
                >添加新选项</Button>
            );
        }
        return (
            <React.Fragment>
                {options.map((option, index) => renderOption(option, index, deletable, editable))}
                {addBtn}
            </React.Fragment>
        );
    };

    let colorfulFormItem;
    if (colorfulOptions) {
        colorfulFormItem = (
            <Form.Item
                label='是否需要颜色'
                className='i-field-f-select-options'
            >
                <Switch
                    disabled={disabled}
                    checked={colorSetting}
                    onChange={handleColorfulChange}
                />
            </Form.Item>
        );
    }

    return (
        <React.Fragment>
            {colorfulFormItem}
            <Form.Item
                label='选项内容'
                className='i-field-f-select-options'
            >
                {renderOptions()}
            </Form.Item>
        </React.Fragment>
    );
});

OptionRule.displayName = 'OptionRule';
export default OptionRule;
