import React, {ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';

import classnames from 'classnames';

import AdminSetting from 'pages/admin_setting';
import DingtalkSSO from 'pages/sso/dingtalk';
import WecomSSO from 'pages/sso/wecom';
import KaixueSSO from 'pages/sso/kaixue';
import LingyangSSO from 'pages/sso/lingyang';
import BackendCenter from 'pages/backend_content/backend_content';
import Loading from 'components/loading_screen';
import Icon from 'components/icon';
import MemberSelectModal from 'components/member_selects/member_select_modal';
import DepartmentMemberSelectModal from 'components/department_member_select/department_member_select_modal';
import DeleteConfirm from 'components/delete_confirm';

import GlobalContext from 'context/global_context';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
import {DDSSORoute, KWSSORoute, LYSSORoute, WWSSORoute} from 'utils/constants/route';
import {initializeWeCom, isWeComEnvironment, registerOpenData} from 'utils/utils';
import {envEnum} from 'utils/constants/general';

export default function Root() {
    const location = useLocation();
    const {userInfo} = useContext(GlobalContext);

    // 用作阻止可能发生的并发行为
    const registeringFlag = useRef(false);
    const [WWSdkLoaded, setWWSdkLoaded] = useState(false);
    const [registeredFlag, setRegisteredFlag] = useState(false);

    const clickClose = () => {
        const url = localStorage.getItem('back_url');
        localStorage.removeItem('back_url');

        if (url) {
            window.location.replace(url);
            return;
        }
        window.parent.window.postMessage('backMainApp', '*');
    };

    useEffect(() => {
        if (location.pathname.includes(WWSSORoute) || isWeComEnvironment()) {
            initializeWeCom(() => setWWSdkLoaded(true));
        }
        EventEmitter.addListener(EventTypeEnum.CLOSE_BACKEND, clickClose);
        return () => {
            EventEmitter.removeListener(EventTypeEnum.CLOSE_BACKEND, clickClose);
        };
    }, []);

    useEffect(() => {
        if (
            isWeComEnvironment() && userInfo &&
            registeredFlag === false && registeringFlag.current === false &&
            WWSdkLoaded
        ) {
            registeringFlag.current = true;
            registerOpenData(userInfo,
                () => {
                    setRegisteredFlag(true);
                },
                () => {
                    registeringFlag.current = false;
                },
            );
        }
    }, [userInfo, WWSdkLoaded]);

    if (isWeComEnvironment() && registeredFlag === false) {
        return null;
    }

    const envName = window.name;

    let headerElement: ReactNode | null = (
        <div className='backend_center-header'>
            <div
                className='backend_center-header-return'
                onClick={clickClose}
            >
                <Icon
                    type='left'
                    style={{marginRight: '4px'}}
                />
                <span style={{fontWeight: 500}}>返回</span>
            </div>
            <div className='backend_center-header-divider'/>
            <span className='backend_center-title'>{'企业后台管理'}</span>
        </div>
    );

    if (envName === envEnum.PERFORMANCE || envName === envEnum.PERFORMANCE_ROLE) {
        headerElement = null;
    }

    const contentContainerClass = classnames('backend_center-container', {
        'backend_center-container--performance-role': envName === envEnum.PERFORMANCE_ROLE,
    });

    return (
        <React.Suspense fallback={<Loading/>}>
            <Switch>
                <Route
                    path={'/admin_setting'}
                    component={AdminSetting}
                />
                <Route
                    path={`${DDSSORoute}`}
                    component={DingtalkSSO}
                />
                <Route
                    path={`${WWSSORoute}`}
                    component={WecomSSO}
                />
                <Route
                    path={`${KWSSORoute}`}
                    component={KaixueSSO}
                />
                <Route
                    path={`${LYSSORoute}`}
                    component={LingyangSSO}
                />
                <Route
                    render={() => (
                        <div className={contentContainerClass}>
                            {headerElement}
                            <BackendCenter/>
                            <DeleteConfirm/>
                            <MemberSelectModal/>
                            <DepartmentMemberSelectModal/>
                        </div>
                    )}
                />
            </Switch>
        </React.Suspense>
    );
}
