import {IAIClient} from 'server/client';
import TClient from 'server/client/tripartite';
import {DingtalkSSOParams, IsvType, WecomSSOParams, TicketType, KaixueSSOParams, KaixueBasicInfoParams} from 'types/users';

export async function loginByDdOauth(params: DingtalkSSOParams) {
    let data;
    try {
        data = await IAIClient.loginByDdOauth(params);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

export async function loadDdJsConfig(corpId: string, url: string, isv_app_type: IsvType = 'Teamwork') {
    let data: any;
    try {
        data = await IAIClient.loadDdJsConfig({
            corp_id: corpId,
            url,
            isv_app_type,
        });
    } catch (error) {
        return {error};
    }
    return {data};
}

export async function loadDingtalkSyncStatus(corpId: string) {
    let data;
    try {
        data = await IAIClient.loadDingtalkSyncStatus(corpId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadDingTalkEnterpriseId(corpId: string) {
    let data;
    try {
        data = await IAIClient.loadDingTalkEnterpriseId(corpId);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

export async function getWeComCorpIdByCode(code: string) {
    let data;
    try {
        data = await IAIClient.getWeComCorpIdByCode(code);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

export async function loginByWeComOauth(params: WecomSSOParams) {
    let data;
    try {
        data = await IAIClient.loginByWeComOauth(params);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

export async function loadWeComJsConfig(corpId: string, current_url: string, ticket_type: TicketType) {
    let data: any;
    try {
        data = await IAIClient.loadWeComJsConfig({
            corp_id: corpId,
            current_url,
            ticket_type,
        });
    } catch (error) {
        return {error};
    }
    return {data};
}

export async function loadWeComSyncStatus(corpId: string) {
    let data;
    try {
        data = await IAIClient.loadWeComSyncStatus(corpId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadWeComEnterpriseId(corpId: string) {
    let data;
    try {
        data = await IAIClient.loadWeComEnterpriseId(corpId);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

export async function handleWeComSync() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    let data;
    try {
        data = await IAIClient.handleWeComSync(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadWeComInitiativeSyncStatus(syncId: number) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    let data;
    try {
        data = await IAIClient.loadWeComInitiativeSyncStatus(enterpriseId, syncId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadKaiXueBasicParams(params: KaixueBasicInfoParams) {
    let data;
    try {
        data = await IAIClient.loadKaiXueBasicParams(params);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

export async function loginByKaiXueOauth(params: KaixueSSOParams) {
    let data;
    try {
        data = await IAIClient.loginByKaiXueOauth(params);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

// 羚羊平台
export async function getLingYangCorpIdByCode(code: string, isvAppType: string, type: string | null) {
    let data;
    try {
        data = await TClient.getLingYangCorpIdByCode(code, isvAppType, type);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

// 羚羊平台
export async function loginByLingYangOauth(params: WecomSSOParams) {
    let data;
    try {
        data = await TClient.loginByLingYangOauth(params);
    } catch (error: any) {
        return {error};
    }

    return {data};
}

// 羚羊平台
export async function loadLingYangSyncStatus(corpId: string) {
    let data;
    try {
        data = await TClient.loadLingYangSyncStatus(corpId);
    } catch (error) {
        return {error};
    }

    return {data};
}

// 羚羊平台
export async function loadLingYangEnterpriseId(corpId: string) {
    let data;
    try {
        data = await TClient.loadLingYangEnterpriseId(corpId);
    } catch (error: any) {
        return {error};
    }

    return {data};
}
