export function concatImagePrefix(imageUrl?: string) {
    if (!imageUrl) {
        return '';
    }

    const reg = /(http|https):\/\/([\w.]+\/?)\S*/ig;
    if (imageUrl.match(reg)) {
        return imageUrl;
    }
    return process.env.IAI_UPLOAD_PREFIX + '/' + imageUrl;
}
