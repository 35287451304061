import React, {useEffect, useMemo, useState} from 'react';
import {Checkbox} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';

import {Feature, ModuleFeatures} from 'types/feature';

export interface ModuleProps {
    module: ModuleFeatures,
    authorizedTokens: Map<string, boolean> | undefined,
    onAuthorizeTokens: (ids: number[], tokens: string[]) => void,
    onCancelTokens: (ids: number[], tokens: string[]) => void,
}

const Module: React.FC<ModuleProps> = (props) => {
    const [moduleChecked, setModuleChecked] = useState<boolean>();
    const [moduleIndeterminate, setModuleIndeterminate] = useState<boolean>();
    const {module, authorizedTokens, onAuthorizeTokens, onCancelTokens} = props;

    useEffect(() => {
        if (module && module.features && module.features.length && authorizedTokens) {
            let checkedCount = 0;
            for (const feature of module.features) {
                if (authorizedTokens.has(feature.token)) {
                    checkedCount++;
                }
            }

            if (checkedCount === module.features.length) {
                setModuleChecked(true);
                setModuleIndeterminate(false);
            } else if (checkedCount > 0) {
                setModuleChecked(false);
                setModuleIndeterminate(true);
            } else {
                setModuleChecked(false);
                setModuleIndeterminate(false);
            }
        }
    }, [authorizedTokens]);

    const renderList = useMemo<Feature[]>(() => {
        const list = [...module.features];
        list.sort((a, b) => a.order - b.order);
        return list;
    }, [module]);

    const handleModuleChange = (e: CheckboxChangeEvent) => {
        if (!authorizedTokens) {
            return;
        }

        const checked = e.target.checked;
        if (checked) {
            const ids: number[] = [];
            const tokens: string[] = [];

            for (const feature of module.features) {
                if (authorizedTokens.has(feature.token)) {
                    continue;
                }

                ids.push(feature.id);
                tokens.push(feature.token);
            }

            if (ids.length) {
                onAuthorizeTokens(ids, tokens);
            }
        } else {
            const ids: number[] = [];
            const tokens: string[] = [];

            for (const feature of module.features) {
                ids.push(feature.id);
                tokens.push(feature.token);
            }

            if (ids.length) {
                onCancelTokens(ids, tokens);
            }
        }
    };

    const handleFeatureChange = (checked: boolean, id: number, token: string) => {
        const ids = [id];
        const tokens = [token];
        if (checked) {
            onAuthorizeTokens(ids, tokens);
        } else {
            onCancelTokens(ids, tokens);
        }
    };

    return (
        <>
            <div className={'module'}>
                <Checkbox
                    checked={moduleChecked}
                    indeterminate={moduleIndeterminate}
                    onChange={handleModuleChange}
                >
                    {module.module_name}
                </Checkbox>
            </div>
            <div className='module-features-container'>
                {
                    renderList.map((feature) => {
                        let checked = false;
                        if (authorizedTokens) {
                            checked = authorizedTokens.has(feature.token);
                        }

                        return (
                            <div
                                key={`${feature.id}`}
                                className={'feature'}
                            >
                                <Checkbox
                                    checked={checked}
                                    onChange={(e: CheckboxChangeEvent) => handleFeatureChange(e.target.checked, feature.id, feature.token)}
                                >
                                    {feature.name}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

export default Module;
