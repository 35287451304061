import React, {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import type {Identifier, XYCoord} from 'dnd-core';

import type {CustomFieldConfigType} from 'types/custom_field';
const ACCEPT_KEY = 'draggable_row';

export type MoveRowParams = {
    dragIndex: number, hoverIndex: number
    dragId: string, hoverId: string
};
type OptionProps = {
    data: CustomFieldConfigType
    tableId?: string
    index: number
    style?: React.CSSProperties
    disabled?: boolean,
    moveItem: (params: MoveRowParams) => void
};

type DragItem = {
    index: number
    id: string
    type: string
};

const DraggableRow: React.FC<OptionProps> = ({
    data, tableId = '', index, style,
    disabled, moveItem,
    ...restProps
}) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const acceptKey = ACCEPT_KEY + tableId;

    const [{handlerId}, drop] = useDrop<
    DragItem,
    void,
    {handlerId: Identifier | null}
    >({
        accept: acceptKey,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current || data.basic) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveItem({
                dragIndex, hoverIndex,
                dragId: item.id, hoverId: data.id
            });

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{isDragging}, drag] = useDrag({
        type: acceptKey,
        item: () => {
            return {id: data.id, index};
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: !(data.basic) && !(disabled),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <tr
            ref={ref}
            style={{opacity, ...style}}
            data-handler-id={handlerId}
            {...restProps}
        />
    );
};

const WrapperRow: React.FC<OptionProps> = (props) => {
    if (props.data) {
        // 由于 Table components 的机制问题，dataSource 为空数组时会渲染一个 值为undefined 的数据
        return <DraggableRow {...props}/>;
    }
    return null;
};

export default WrapperRow;
