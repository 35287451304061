import React from 'react';

import {IAIClient} from 'server/client';
import {envEnum} from 'utils/constants/general';
import GoToWrapper from '../goto_wrapper';
import {RoleSettingProps} from 'types/roles';

const MemberGoTo: React.FC<RoleSettingProps> = (props) => {
    const {role} = props;

    const appEnvironment: string | undefined = IAIClient.getAppType() || envEnum.ONLINE;
    const inOnline = appEnvironment === envEnum.ONLINE;
    return (
        <div className={'backend-role-setting'}>
            <div className={'backend-role-setting-scroll'}>
                <div className={'member-go-to'}>
                    <span className={'introduce'}>
                        {`“${role.alias_name}”属于特殊角色，如需修改成员或管理范围，请通过组织架构进行调整`}
                    </span>
                    {inOnline ? <GoToWrapper/> : null}
                </div>
            </div>
        </div>
    );
};

export default MemberGoTo;
