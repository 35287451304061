import React, {useState} from 'react';
import classnames from 'classnames';
import {Popover} from 'antd';

import Icon from 'components/icon';
type IconSelectorProps = {
    value: string,
    onChange: (value: string) => void,
    isPreview?: boolean,
};
const IconSelector: React.FC<IconSelectorProps> = ({
    value,
    onChange,
    isPreview,
}) => {
    const [visible, setVisible] = useState(false);

    const handleSelectItem = (icon: string) => {
        onChange(icon);
        setVisible(false);
    };

    const renderPicker = () => {
        const icons = [];
        for (let i = 1; i <= 86; i++) {
            const id = `a-${i}`;
            icons.push(
                <Icon
                    key={id}
                    type={id}
                    className={classnames('icon-selector-item', {active: value === id})}
                    onClick={() => handleSelectItem(id)}
                />
            );
        }
        return (
            <div className='icon-selector-list'>
                {icons}
            </div>
        );
    };

    const content = (
        <Icon
            type={value}
            color='var(--gray-color)'
            size={18}
        />
    );

    if (isPreview) {
        return <div className='icon-selector-container'>{content}</div>;
    }

    return (
        <Popover
            overlayClassName='no-arrow-popover compact-popover'
            open={visible}
            onOpenChange={setVisible}
            content={renderPicker()}
            trigger='click'
            placement='bottomLeft'

        >
            <div
                className={classnames('icon-selector-container', {
                    selectable: !isPreview
                })}
            >
                {content}
                <Icon type='down'/>
            </div>
        </Popover>
    );
};

export default IconSelector;
