import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import {setClientSingalByCookies, setUrlParams} from 'utils/utils';

import App from './App';
import reportWebVitals from './reportWebVitals';

function renderRootComponent() {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root'),
    );
}

/**
 * Adds a function to be invoked onload appended to any existing onload
 * event handlers.
 *
 * @param   {function} fn onload event handler
 *
 */
function appendOnLoadEvent(fn: (event?: any) => any) {
    if (window.attachEvent) {
        window.attachEvent('onload', fn);
    } else if (window.onload) {
        const curronload: any = window.onload;
        window.onload = (evt: Event) => {
            curronload(evt);
            fn(evt);
        };
    } else {
        window.onload = fn;
    }
}

appendOnLoadEvent(() => {
    setClientSingalByCookies();
    setUrlParams();
    renderRootComponent();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
