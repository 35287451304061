import {IAIClient} from 'server/client';
import {DepartmentNode, EnterpriseInfo, EnterpriseOrder, EnterpriseSummary} from 'types/organization';

export async function loadEnterpriseInfoById() {
    let data: EnterpriseInfo;
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    try {
        data = await IAIClient.loadEnterpriseInfoById(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadEnterpriseSummaryById() {
    let data: EnterpriseSummary;
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    try {
        data = await IAIClient.loadEnterpriseSummaryById(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadEnterpriseOrders() {
    let data: EnterpriseOrder[];
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    try {
        data = await IAIClient.loadEnterpriseOrders(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadDepartmentTree(departmentId = 0, extraCondition = '') {
    let data: DepartmentNode;
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    try {
        data = await IAIClient.loadDepartmentTree(enterpriseId, departmentId, extraCondition);
    } catch (error) {
        return {error};
    }

    return {data};
}
