import React from 'react';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';

import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ConfigProvider} from 'antd';
import locale from 'antd/es/locale/zh_CN';

import 'moment/locale/zh-cn';
import 'antd/dist/antd.less';
import 'scss/styles.scss';

import Root from 'components/root';
import {BaseRoute} from 'utils/constants/route';

import {Provider} from 'context/global_context';

function App() {
    return (
        <Provider>
            <ConfigProvider
                locale={locale}
                autoInsertSpaceInButton={false}
            >
                <DndProvider backend={HTML5Backend}>
                    <Router basename={BaseRoute}>
                        <Route
                            path='/'
                            component={Root}
                        />
                    </Router>
                </DndProvider>
            </ConfigProvider>
        </Provider>
    );
}

export default App;
