import React, {useContext, useEffect, useRef, useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';

import {Button, message, Modal} from 'antd';

import {BACKEND_MANAGEMENT} from 'utils/constants/permission';
import {OrganizationSyncStatus} from 'utils/constants/general';
import {isDingtalkEnvironment, isWeComEnvironment} from 'utils/utils';
import GlobalContext from 'context/global_context';

import OrganizationTree from 'components/organization_tree';

import Icon from 'components/icon';
import {checkDingtalkOrgauthscope} from 'server/actions/platform';
import {handleWeComSync, loadWeComInitiativeSyncStatus} from 'server/actions/users';

import StaffView from './components/staff_view';
import DepartmentView from './components/department_view';

import DingtalkModifyGuide from './components/dingtalk_modify_guide.jsx';

const OrganizationManagement: React.FC = (): JSX.Element | null => {
    const browserHistory = useHistory();
    const {
        loadOrganizationData,
    } = useContext(GlobalContext);
    const syncId = useRef<number>();
    const syncTimer = useRef<NodeJS.Timeout>();
    const [syncing, setSyncing] = useState(false);
    const [disabled, setDisabled] = useState(false);

    // 钉钉特殊需求，组织架构不一致
    const [diffWithDingTalk, setDiffWithDingTalk] = useState(false);
    const [openModifyGuide, setOpenModifyGuide] = useState(false);

    useEffect(() => {
        if (isDingtalkEnvironment()) {
            checkDingtalkOrgauthscope().then(({data}) => {
                if (data && data.auth_scope_all === false) {
                    setDiffWithDingTalk(true);
                }
            });
        }

        return () => {
            if (syncTimer.current) {
                clearTimeout(syncTimer.current);
            }
        };
    }, []);

    const onNodeSelect = (selectedKey: string) => {
        const splitId = selectedKey.split('/');
        if (splitId[0] === 'department') {
            browserHistory.push(`/${BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT}/department/${splitId[1]}`);
        } else if (splitId[0] === 'staff') {
            browserHistory.push(`/${BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT}/staff/${splitId[1]}`);
        }
    };

    const finishSync = () => {
        setSyncing(false);
        setTimeout(() => {
            setDisabled(false);
        }, 10000);
    };

    const updateOrganizationData = () => {
        loadOrganizationData(
            () => {
                message.success('刷新成功');
                finishSync();
            },
            finishSync,
        );
    };

    const checkOrganizationSyncStatus = () => {
        if (syncId.current) {
            loadWeComInitiativeSyncStatus(syncId.current).then(({data}) => {
                if (data) {
                    if (data.status === OrganizationSyncStatus.COMPLETED) {
                        updateOrganizationData();
                    } else if (data.status === OrganizationSyncStatus.INIT || data.status === OrganizationSyncStatus.SYNC) {
                        if (syncTimer.current) {
                            clearTimeout(syncTimer.current);
                        }
                        syncTimer.current = setTimeout(checkOrganizationSyncStatus, 5000);
                    }
                }
            });
        }
    };

    const syncData = async () => {
        setSyncing(true);
        setDisabled(true);

        if (isWeComEnvironment()) {
            // 微信环境触发同步
            const {data} = await handleWeComSync();
            if (data && data.status !== OrganizationSyncStatus.COMPLETED) {
                message.warning('同步中，请稍后');
                syncId.current = data.id;
                syncTimer.current = setTimeout(checkOrganizationSyncStatus, 5000);
            }
        } else {
            // 钉钉环境直接更新数据
            updateOrganizationData();
        }
    };

    let warningTip;
    if (diffWithDingTalk) {
        warningTip = (
            <div className='backend_center-organization-warning_tip'>
                <Icon
                    type='warning'
                    color='#FBBC05'
                />
                <span>{'当前组织架构和钉钉不一致，可通过钉钉工作台调整应用的可见范围'}</span>
                <span
                    className='adjustment-btn'
                    onClick={() => setOpenModifyGuide(true)}
                >{'如何调整'}</span>
                <Modal
                    title='如何调整可见范围？'
                    open={openModifyGuide}
                    closable={false}
                    footer={null}
                    width={600}
                    className='platform-modify-organization-modal'
                >
                    <DingtalkModifyGuide
                        onClose={() => setOpenModifyGuide(false)}
                    />
                </Modal>
            </div>
        );
    }

    return (
        <div className='backend_center-organization-warning_nested'>
            {warningTip}
            <div className='backend_center-organization-container'>
                <div className='backend_center-organization-sidebar'>
                    <OrganizationTree
                        onSelect={onNodeSelect}
                    />
                    <Button
                        className='sync-organization-btn'
                        onClick={syncData}
                        loading={syncing}
                        disabled={disabled}
                        type='primary'
                    >{'刷新'}</Button>
                </div>
                <div className='organization-content'>
                    <Switch>
                        <Route
                            path={`/${BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT}/department/:departmentId`}
                            component={DepartmentView}
                        />
                        <Route
                            path={`/${BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT}/staff/:userId`}
                            component={StaffView}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default OrganizationManagement;
