import React, {useCallback, useMemo, useState} from 'react';
import {useMemoizedFn} from 'ahooks';
import {Switch} from 'antd';

import CollapseItem from 'components/collapse/collapse_item';
import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';
import CustomFieldDrawer from 'components/custom_field_setting/custom_field_drawer';
import PropertiesTable from 'components/custom_field_setting/properties_table';
import {getEditable} from 'components/custom_field_setting/utils';
import type {MoveRowParams} from 'components/custom_field_setting/draggable_row';
import type {CustomFieldConfigType} from 'types/custom_field';

const TypeList = [
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_LONGTEXT,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_NUMBER,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_DATE,
    CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF,
];

// 截止20231204 组件结构为
/**
 * - PropertiesContent
 *  - PropertiesTable
 *      - 各表格相关的组件
 *  - CustomFieldDrawer
 *      - CustomFieldForm
 */
type Props = {
    editing: boolean
    properties: CustomFieldConfigType[]
    onFieldsChange: (fields: CustomFieldConfigType[]) => void
};
const PropertiesContent = ({
    editing,
    properties,
    onFieldsChange
}: Props) => {
    const [modifyId, setModifyId] = useState<string>();

    const {
        basicNames,
        defaultList,
        customList,
        indexDictionary,
    } = useMemo(() => {
        const basicNames: string[] = [];
        const defaultList: CustomFieldConfigType[] = [];
        const customList: CustomFieldConfigType[] =[];
        const indexDictionary: Record<string, number> = {};

        properties.forEach((property, index) => {
            indexDictionary[property.id] = index;
            if (property.systemic) {
                basicNames.push(property.name);
            } else if (property.basic) {
                basicNames.push(property.name);
                defaultList.push(property);
            } else {
                customList.push(property);
            }
        });

        return {
            basicNames,
            defaultList,
            customList,
            indexDictionary,
        };
    }, [properties]);

    const modifyingItem = useMemo(() => {
        if (modifyId) {
            return properties.find((item) => item.id === modifyId);
        }
    }, [modifyId, properties]);

    const onAddItem = useMemoizedFn((item: CustomFieldConfigType) => {
        if (item.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF) {
            // 全量属性扫一遍 -> 为已经设置过 allowed_ids 的属性添加新的 item.id
            properties.forEach((pItem) => {
                if (pItem.systemic || (pItem.basic && pItem.access_rules_modifiable !== true)) {
                    return;
                }
                if (
                    pItem.access_rules?.[0] &&
                    pItem.access_rules[0].type === 'MemberRole' &&
                    pItem.access_rules[0].allowed_ids
                ) {
                    pItem.access_rules[0].allowed_ids.push(item.id);
                }
            });
        }
        onFieldsChange([
            ...properties,
            item,
        ]);
    });

    const onDelete = useMemoizedFn((id: string) => {
        const nextCache = [...properties];
        const index = indexDictionary[id];
        if (index !== undefined) {
            nextCache.splice(index, 1);
            onFieldsChange(nextCache);
        }
    });

    const onModify = useMemoizedFn((propertyItem: CustomFieldConfigType) => {
        const nextCache = [...properties];
        const modifiedIndex = indexDictionary[propertyItem.id];
        if (modifiedIndex !== undefined) {
            nextCache[modifiedIndex] = propertyItem;
            onFieldsChange(nextCache);
        }
    });

    const onMove = useMemoizedFn(({dragId, hoverId}: MoveRowParams) => {
        const dragIndex = indexDictionary[dragId];
        const hoverIndex = indexDictionary[hoverId];

        if (dragIndex !== undefined && hoverIndex !== undefined) {
            const nextCache = [...properties];
            const dragItem = nextCache[dragIndex];
            nextCache.splice(dragIndex, 1);
            nextCache.splice(hoverIndex, 0, dragItem);
            onFieldsChange(nextCache);
        }
    });

    const triggerModifyItem = useCallback((item: CustomFieldConfigType) => {
        setModifyId(item.id);
    }, []);

    const onDrawerClose = useCallback(() => {
        setModifyId(undefined);
    }, []);

    let formDrawer;
    if (modifyingItem) {
        formDrawer = (
            <CustomFieldDrawer
                totalCustomFields={properties}
                typeOptions={TypeList}
                colorfulOptions={true}
                showUsageSetting={true}
                editing={editing}

                modifyItem={modifyingItem}
                onModify={onModify}
                onDelete={onDelete}
                handleClose={onDrawerClose}
            />
        );
    }

    let addAction;
    if (customList.length < 16) {
        addAction = onAddItem;
    }

    return (
        <React.Fragment>
            <CollapseItem
                title='默认列'
                content={
                    <PropertiesTable
                        tableId='default'
                        dataSource={defaultList}
                        typeOptions={TypeList}
                        disabled={true}
                        extendedField={[{
                            title: '是否使用',
                            dataIndex: 'usage',
                            align: 'center',
                            width: '75px',
                            render: (value: string, record: CustomFieldConfigType) => (
                                <Switch
                                    checked={!(record.disabled)}
                                    disabled={true}
                                />
                            ),
                        }]}
                        onModifyItem={onModify}
                        triggerModifyItem={triggerModifyItem}
                        columnNames={['列名称']}
                    />
                }
            />
            <CollapseItem
                title='自定义列'
                content={(
                    <PropertiesTable
                        tableId='custom'
                        dataSource={customList}
                        basicNames={basicNames}
                        typeOptions={TypeList}
                        colorfulOptions={true}
                        disabled={!editing}
                        extendedField={[{
                            title: '是否使用',
                            dataIndex: 'usage',
                            align: 'center',
                            width: '75px',
                            render: (value: string, record: CustomFieldConfigType) => (
                                <Switch
                                    checked={!(record.disabled)}
                                    onClick={(checked, e) => e.stopPropagation()}
                                    onChange={(checked) => onModify({...record, disabled: !checked})}
                                    disabled={!editing || !getEditable(record, 'disabled')}
                                />
                            ),
                        }]}
                        onAddItem={addAction}
                        onModifyItem={onModify}
                        onMoveItem={onMove}
                        triggerModifyItem={triggerModifyItem}
                        columnNames={['列名称']}
                    />
                )}
            />
            {formDrawer}
        </React.Fragment>
    );
};

export default PropertiesContent;
