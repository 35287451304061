import React, {useContext, useEffect} from 'react';
import {
    Switch,
    Route,
    NavLink,
    Redirect,
} from 'react-router-dom';
import classnames from 'classnames';

import EnterpriseInfo from 'components/enterprise_info/enterprise_info.jsx';

import {IAIClient} from 'server/client';
import {BACKEND_MANAGEMENT, BACKEND_MANAGEMENT_LABELS} from 'utils/constants/permission';
import GlobalContext from 'context/global_context';

import OrganizationManagement from 'components/organization_management';
import AuthoriseManagement from 'components/authorise_management/authorise_management';
import RoleManagement from 'components/role_management/role_management';
import StaffDataMeta from 'components/staff_datameta';
import ProjectProperty from 'components/teamwork_setting/project_property';
import TaskProperty from 'components/teamwork_setting/task_property';
import Icon from 'components/icon';
import {loadUserRoles} from 'server/actions/general';

import {envEnum} from 'utils/constants/general';
import {isLingYangEnvironment} from 'utils/utils';

const defaultRedirectConfig = {
    [envEnum.TEAMWORK]: BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT,
    [envEnum.PERFORMANCE]: BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT,
    [envEnum.ONLINE]: BACKEND_MANAGEMENT.ENTERPRISE_INFO,
};

interface GroupItem {
    group: string,
    items: MenuItem[],
}

interface MenuItemType {
    path: string
    teamworkRequired?: boolean
    performanceRequired?: boolean
    onlineRequired?: boolean
    component?: React.ComponentType<any>
    disable?: boolean
    level?: number
    children?: MenuItem[]
    tips?: string
}
class MenuItem {
    path: string;
    required: Record<string, boolean | undefined>;
    component?: React.ComponentType<any>;
    disable?: boolean;
    level: number;
    children?: MenuItem[];
    tips?: string;

    constructor({
        path,
        teamworkRequired,
        performanceRequired,
        onlineRequired,
        component,
        disable,
        level,
        children,
        tips
    }: MenuItemType) {
        this.path = path;
        this.required = {
            [envEnum.TEAMWORK]: teamworkRequired,
            [envEnum.PERFORMANCE]: performanceRequired,
            [envEnum.ONLINE]: onlineRequired,
        };
        this.component = component;
        this.disable = disable;
        this.level = level || 0;
        this.children = children;
        this.tips = tips;
    }

    isRequired(env: string) {
        return this.required[env] === true;
    }
}

const AllMenus: GroupItem[] = [
    {
        group: '基础设置',
        items: [
            new MenuItem({
                path: BACKEND_MANAGEMENT.ENTERPRISE_INFO,
                onlineRequired: true,
                component: EnterpriseInfo,
            }),
            new MenuItem({
                path: BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT,
                teamworkRequired: true,
                performanceRequired: true,
                component: OrganizationManagement,
            }),
            new MenuItem({
                path: BACKEND_MANAGEMENT.AUTHORISE_MANAGEMENT,
                teamworkRequired: true,
                performanceRequired: true,
                component: AuthoriseManagement,
            }),
            new MenuItem({
                path: BACKEND_MANAGEMENT.ROLE_MANAGEMENT,
                teamworkRequired: true,
                performanceRequired: true,
                onlineRequired: true,
                disable: true,
                children: [
                    new MenuItem({
                        path: BACKEND_MANAGEMENT.ROLE_MANAGEMENT_WORK,
                        teamworkRequired: true,
                        onlineRequired: true,
                        component: RoleManagement,
                        level: 1,
                    }),
                    new MenuItem({
                        path: BACKEND_MANAGEMENT.ROLE_MANAGEMENT_PERF,
                        performanceRequired: true,
                        onlineRequired: true,
                        component: RoleManagement,
                        level: 1,
                    }),
                    new MenuItem({
                        path: BACKEND_MANAGEMENT.STAFF_SETTINGS,
                        teamworkRequired: true,
                        onlineRequired: true,
                        component: StaffDataMeta,
                    }),
                ],
            }),
        ],
    },
    {
        group: '协作',
        items: [
            new MenuItem({
                path: BACKEND_MANAGEMENT.PROJECT_SETTINGS,
                teamworkRequired: true,
                onlineRequired: true,
                component: ProjectProperty,
            }),
            new MenuItem({
                path: BACKEND_MANAGEMENT.TASK_SETTINGS,
                teamworkRequired: true,
                onlineRequired: true,
                component: TaskProperty,
            })
        ]
    },
];

type ExtraParamsType = {
    organizationHidden: boolean,
};
function getEnvMenuItems(env: string = envEnum.ONLINE, extraParams?: ExtraParamsType): {
    groupItems: Array<GroupItem>,
    effectiveItems: Array<MenuItem>,
} {
    const groupItems: Array<GroupItem> = [];
    const effectiveItems: Array<MenuItem> = []; // 只做有效的 Route 匹配
    for (const gItem of AllMenus) {
        const menuItems: Array<MenuItem> = [];
        for (const mItem of gItem.items) {
            if (mItem.isRequired(env)) {
                if (mItem.path === BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT && extraParams?.organizationHidden) {
                    continue;
                }
                menuItems.push(mItem);
                effectiveItems.push(mItem);
            }
            if (mItem.children) {
                for (const child of mItem.children) {
                    if (child.isRequired(env)) {
                        menuItems.push(child);
                        effectiveItems.push(child);
                    }
                }
            }
        }
        if (menuItems.length) {
            groupItems.push({
                group: gItem.group,
                items: menuItems,
            });
        }
    }
    return {groupItems, effectiveItems};
}

const BackendCenter: React.FC = (): JSX.Element => {
    // managerList相关是否需要？
    const {managerList, receivedManagerList} = useContext(GlobalContext);

    useEffect(() => {
        loadUserRoles(IAIClient.getEnterpriseId()).then(({data, error}) => {
            receivedManagerList(data);
        });
    }, []);

    if (!managerList) {
        return <div className='backend_center-main'/>;
    }

    /**
     * 环境标识: Teamwork | Performance -> 其实就是isvType，为实现应用“独立售卖”而做
     * ONLINE 作为默认值，便于MenuItems的计算
     */
    const appEnvironment: string | undefined = IAIClient.getAppType() || envEnum.ONLINE;
    const {groupItems, effectiveItems} = getEnvMenuItems(appEnvironment, {organizationHidden: isLingYangEnvironment()});

    const containerClass = classnames(
        'backend_center-menu-container',
        {
            'backend_center-menu-container--performance': window.name === envEnum.PERFORMANCE || window.name === envEnum.PERFORMANCE_ROLE,
        },
    );

    const sidebarClass = classnames(
        'backend_center-menu-sidebar',
        {
            'backend_center-menu-sidebar--performance': window.name === envEnum.PERFORMANCE,
            'backend_center-menu-sidebar--performance-role': window.name === envEnum.PERFORMANCE_ROLE,
        },
    );

    const backendMenuContentHolderClass = classnames('backend-menu-content-holder', {
        'backend-menu-content-holder--performance-role': window.name === envEnum.PERFORMANCE_ROLE
    });

    return (
        <div className='backend_center-main'>
            <div className={containerClass}>
                <div className={sidebarClass}>
                    {
                        groupItems.map((groupItem) => {
                            return (
                                <React.Fragment key={groupItem.group}>
                                    <div className='backend-menu-item-group'>{groupItem.group}</div>
                                    {
                                        groupItem.items.map((menuItem) => {
                                            const showObject = BACKEND_MANAGEMENT_LABELS[menuItem.path];
                                            let linkStyle: React.CSSProperties | undefined;
                                            if (menuItem.disable) {
                                                linkStyle = {
                                                    pointerEvents: 'none',
                                                };
                                            }
                                            let offsetStyle: React.CSSProperties | undefined;
                                            if (menuItem.level) {
                                                offsetStyle = {
                                                    marginLeft: menuItem.level * 20,
                                                };
                                            }
                                            let tipIcon;
                                            if (menuItem.tips) {
                                                tipIcon = (
                                                    <Icon
                                                        type='help_tip'
                                                        title={menuItem.tips}
                                                        style={{
                                                            flex: 'none',
                                                            marginLeft: '2px'
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <NavLink
                                                    className='backend-menu_item'
                                                    style={linkStyle}
                                                    key={menuItem.path}
                                                    to={`/${menuItem.path}`}
                                                >
                                                    <Icon
                                                        type={showObject.icon}
                                                        style={
                                                            {
                                                                ...offsetStyle,
                                                                color: 'var(--gray-color)',
                                                            }
                                                        }
                                                        size={16}
                                                    />
                                                    <span>{showObject.name}</span>
                                                    {tipIcon}
                                                </NavLink>
                                            );
                                        })
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                </div>
                <div className={backendMenuContentHolderClass}>
                    <Switch>
                        {
                            effectiveItems.map((menuItem) => {
                                return (
                                    <Route
                                        key={`${menuItem.path}`}
                                        path={`/${menuItem.path}`}
                                        component={menuItem.component}
                                    />
                                );
                            })
                        }
                        <Redirect to={`/${defaultRedirectConfig[appEnvironment]}`}/>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default BackendCenter;
