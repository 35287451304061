import React, {useContext, useEffect, useState} from 'react';
import {Avatar, Form, message} from 'antd';
import moment from 'moment';

import {setDepartmentMain} from 'server/actions/staffs';
import {UserDepartmentFullPath} from 'types/departments';
import {concatImagePrefix} from 'utils/image_utils';
import {isDingtalkEnvironment} from 'utils/utils';
import GlobalContext from 'context/global_context';

import Icon from 'components/icon';
import StaffInfo from 'components/organization_components/staff_info';
import {loadDingtalkEmployeeInfo} from 'server/actions/platform';
import {DingTalkEmployeeInfo} from 'server/types/actions';
import {loadStaffsDepartmentFullPaths} from 'server/actions/department';
import StaffDepartmentMainPopover from 'components/organization_management/components/StaffDepartmentMainPopover';

const {Item} = Form;
export interface StaffViewProps {
    match: {
        params: {userId: string}
    }
}

const StaffView: React.FC<StaffViewProps> = (props) => {
    const {departmentFullPath, staffs} = useContext(GlobalContext);
    const currentStaff = staffs[props.match.params.userId];
    const [userDepartmentFullPath, setUserDepartmentFullPath] = useState<undefined | UserDepartmentFullPath>();
    const [dingTalkEmployeeInfo, setDingTalkEmployeeInfo] = useState<undefined | DingTalkEmployeeInfo>();

    const loadDepartments = () => {
        loadStaffsDepartmentFullPaths(props.match.params.userId).then(({data, error}) => {
            if (error) {
                message.error('获取部门信息失败');
            } else {
                setUserDepartmentFullPath(data);
            }
        });
    };

    const loadEmployeeInfo = () => {
        if (isDingtalkEnvironment()) {
            loadDingtalkEmployeeInfo(props.match.params.userId).then(({data, error}) => {
                if (error) {
                    return;
                }
                setDingTalkEmployeeInfo(data);
            });
        }
    };

    useEffect(() => {
        if (props.match.params.userId) {
            loadDepartments();
            loadEmployeeInfo();
        }
    }, [props.match.params.userId]);

    if (!currentStaff) {
        return null;
    }

    const changeMainUnit = (e: React.MouseEvent, departmentId: number) => {
        e.stopPropagation();
        e.preventDefault();
        setDepartmentMain(currentStaff.user_id, departmentId).then(({error, data}) => {
            if (error) {
                message.error('设置主部门失败');
            } else {
                message.success('设置主部门成功');
                loadDepartments();
            }
        });
    };

    const getSexName = (sex: string | undefined) => {
        if (sex === 'Male') {
            return '男';
        } else if (sex === 'Female') {
            return '女';
        }
        return '';
    };

    const renderDepartmentList = () => {
        let content;
        if (userDepartmentFullPath) {
            content = userDepartmentFullPath.department_ids.map((id) => {
                const departmentPath = departmentFullPath[id];
                if (departmentPath) {
                    if (userDepartmentFullPath.main_department_id === id) {
                        return (
                            <span
                                key={id}
                                className='department-item'
                            >
                                {departmentPath.fullpath}
                                <Icon
                                    type='main-unit'
                                    className='admin_unit_icon'
                                />
                            </span>
                        );
                    }
                    return (
                        <StaffDepartmentMainPopover
                            key={id}
                            departmentId={id}
                            userId={currentStaff.user_id}
                            successCallback={() => loadDepartments()}
                        >
                            <span
                                className='department-item'
                                style={{cursor: 'pointer'}}
                            >
                                {departmentPath.fullpath}
                            </span>
                        </StaffDepartmentMainPopover>
                    );
                }
                return null;
            });
        }

        return (
            <div className='staff-info-departments'>
                {content}
            </div>
        );
    };

    return (
        <div className='staff-info-container'>
            <div className='staff-info-title'>
                {'查看员工信息'}
            </div>
            <Form
                className='staff-info-content'
                colon={false}
            >
                <div className='staff-info-block staff-info-content-top'>
                    <div style={{flex: 1}}>
                        <Item label='姓名'>
                            <StaffInfo
                                staff={currentStaff}
                                nameOnly={true}
                            />
                        </Item>
                        <Item label='花名'>
                            <StaffInfo
                                staff={currentStaff}
                                nickOnly={true}
                            />
                        </Item>
                        <Item
                            label='性别'
                        >{getSexName(currentStaff.sex)}</Item>
                    </div>
                    <Avatar
                        size={76}
                        src={concatImagePrefix(currentStaff.avatar?.large)}
                        className='staff-info-avatar'
                    />
                </div>
                <div className='staff-info-block'>
                    <Item
                        label='手机号码'
                    >{currentStaff.phone}</Item>
                    <Item
                        label='座机'
                    >{currentStaff.land_line}</Item>
                    <Item
                        label='邮箱'
                    >{currentStaff.email}</Item>
                    <Item
                        label='工号'
                    >{currentStaff.no}</Item>
                    <Item
                        label='入职时间'
                    >{currentStaff.join_at ? moment(currentStaff.join_at).format('YYYY-MM-DD') : ''}</Item>
                    {dingTalkEmployeeInfo && (
                        <>
                            <Item label='岗位职级'>{dingTalkEmployeeInfo.position_level}</Item>
                            <Item label='员工类型'>{dingTalkEmployeeInfo.employee_type}</Item>
                            <Item label='员工状态'>{dingTalkEmployeeInfo.employee_status}</Item>
                        </>
                    )}
                </div>
                <div className='staff-info-block'>
                    <Item
                        label='所属部门'
                    >
                        {renderDepartmentList()}
                    </Item>
                    <Item
                        label='职务'
                    >{currentStaff.duty}</Item>
                </div>
            </Form>
        </div>
    );
};

export default StaffView;
