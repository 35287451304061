import React, {useContext, useEffect, useState} from 'react';
import {Button, Input, Checkbox, message} from 'antd';

import {CheckboxChangeEvent} from 'antd/lib/checkbox';

import GlobalContext from 'context/global_context';
import Icon from 'components/icon';
import {addRoleUserRanges, loadRoleUsersRanges, removeRoleUserRanges} from 'server/actions/roles';
import {Dictionary, RoleUserRange, SimpleRoleUserRange} from 'types/role_user_range';
import {RoleSettingProps} from 'types/roles';
import {CallBackFunc} from 'types/general';
import {RangeTypes} from 'utils/constants/general';
import {isWeComEnvironment} from 'utils/utils';

import SelectTrigger from 'components/select_trigger/select_trigger';

import MemberItem from './member_item';

const MemberTable: React.FC<RoleSettingProps> = (props) => {
    const {role, memberIds, onMemberSelected, onMemberRemove} = props;
    const [memberModalVisible, setMemberModalVisible] = useState<boolean>(false);
    const [batchActive, setBatchActive] = useState<boolean>(false);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [checkedMemberIds, setCheckedMemberIds] = useState<Set<string>>(new Set());
    const [token, setToken] = useState<string>('');
    const [rolesRanges, setRolesRanges] = useState<Dictionary<Dictionary<RoleUserRange[]>>>({});

    const {organization, loadOrganizationData, loadStaffsIfNeeded} = useContext(GlobalContext);

    const isMemberNotEmpty = () => {
        return memberIds && memberIds.length > 0;
    };

    useEffect(() => {
        if (!organization) {
            loadOrganizationData();
        }

        if (role && memberIds && memberIds.length) {
            if (!rolesRanges[role.id]) {
                loadRoleUsersRanges(role.id).then(({data, error}) => {
                    if (error) {
                        message.error('获取角色管理范围失败');
                    } else if (data) {
                        const userIds = [];
                        for (const ranges of Object.values(data)) {
                            for (const range of ranges) {
                                if (range.range_type === RangeTypes.Staff) {
                                    userIds.push(range.range_id);
                                }
                            }
                        }

                        if (userIds.length) {
                            loadStaffsIfNeeded(userIds).then();
                        }

                        setRolesRanges((originRolesRanges) => {
                            return {
                                ...originRolesRanges,
                                [role.id]: data,
                            };
                        });
                    }
                });
            }
        }
    }, [role, memberIds]);

    const toggleMemberModalVisible = () => {
        setMemberModalVisible(!memberModalVisible);
    };

    const toggleBatchActive = () => {
        setBatchActive(!batchActive);
        if (batchActive) {
            handleCheckedAll(false);
        }
    };

    const handleFilterToken: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setToken(e.target.value.trim());
    };

    const handleCheckedAll = (checked: boolean) => {
        setCheckedAll(checked);
        if (checked) {
            setCheckedMemberIds(new Set(memberIds));
        } else {
            setCheckedMemberIds(new Set());
        }
    };

    const handleItemChecked = (memberId: string, checked: boolean) => {
        setCheckedMemberIds((originCheckedMemberIds) => {
            const nextCheckedMemberIds = new Set(Array.from(originCheckedMemberIds));
            if (checked) {
                nextCheckedMemberIds.add(memberId);
                if (nextCheckedMemberIds.size === memberIds?.length) {
                    setCheckedAll(true);
                }
            } else {
                nextCheckedMemberIds.delete(memberId);
                setCheckedAll(false);
            }

            return nextCheckedMemberIds;
        });
    };

    const handleMemberRemove = (removeIds: string[], onSuccess?: CallBackFunc) => {
        onMemberRemove(removeIds, () => {
            setRolesRanges((originRolesRanges) => {
                const roleRanges = originRolesRanges[role.id];
                if (!roleRanges) {
                    return originRolesRanges;
                }

                const nextRoleRanges = {
                    ...roleRanges,
                };

                for (const removeId of removeIds) {
                    delete nextRoleRanges[removeId];
                }

                return {
                    ...originRolesRanges,
                    [role.id]: nextRoleRanges,
                };
            });

            if (onSuccess) {
                onSuccess();
            }
        });
    };

    const handleBatchRemove = () => {
        const removeIds = Array.from(checkedMemberIds);
        handleMemberRemove(removeIds, toggleBatchActive);
    };

    const handleRangeRemove = (memberId: string, range: RoleUserRange) => {
        removeRoleUserRanges(role.id, memberId, [range]).then(({error}) => {
            if (error) {
                message.error('删除范围失败');
                return;
            }

            setRolesRanges((originRolesRanges) => {
                const roleRanges = originRolesRanges[role.id];
                if (!roleRanges) {
                    return originRolesRanges;
                }

                const memberRanges = roleRanges[memberId];
                if (!memberRanges) {
                    return originRolesRanges;
                }

                const nextMemberRanges = memberRanges.filter((one) => one.id !== range.id);
                const nextRoleRanges = {
                    ...roleRanges,
                    [memberId]: nextMemberRanges,
                };

                return {
                    ...originRolesRanges,
                    [role.id]: nextRoleRanges,
                };
            });
        });
    };

    const handleRangesAdd = (memberId: string, ranges: SimpleRoleUserRange[]) => {
        addRoleUserRanges(role.id, memberId, ranges).then(({error, data}) => {
            if (error) {
                message.error('添加范围失败');
                return;
            }

            if (data && data.length) {
                setRolesRanges((originRolesRanges) => {
                    const roleRanges = originRolesRanges[role.id];
                    if (!roleRanges) {
                        return originRolesRanges;
                    }

                    const memberRanges = roleRanges[memberId] || [];
                    const nextMemberRanges = [...memberRanges, ...data];
                    const nextRoleRanges = {
                        ...roleRanges,
                        [memberId]: nextMemberRanges,
                    };

                    return {
                        ...originRolesRanges,
                        [role.id]: nextRoleRanges,
                    };
                });
            }
        });
    };

    const renderTop = () => {
        return (
            <div className={'member-table-top mx-16'}>
                <div className={'member-buttons'}>
                    <SelectTrigger
                        holderComponent={
                            <Button
                                type='primary'
                                onClick={toggleMemberModalVisible}
                            >
                                <Icon type='plus'/>
                                {'新增成员'}
                            </Button>
                        }
                        originMemberIds={memberIds}
                        onSelected={onMemberSelected}
                    />
                    {
                        isMemberNotEmpty() ?
                            <Button
                                onClick={toggleBatchActive}
                            >
                                {batchActive ? '取消批量操作' : '批量操作'}
                            </Button> : null
                    }
                </div>
                {

                    // 20230228微信环境暂时简单处理-隐藏搜索
                    isMemberNotEmpty() && !isWeComEnvironment() ?
                        <div className={'filter'}>
                            <Input
                                prefix={
                                    <Icon
                                        type={'search'}
                                        size={16}
                                        color={'#9E9EA7'}
                                    />
                                }
                                placeholder={'请搜索员工姓名'}
                                allowClear={true}
                                onChange={handleFilterToken}
                            />
                        </div> : null
                }
            </div>
        );
    };

    const renderRows = () => {
        if (!isMemberNotEmpty()) {
            return null;
        }

        return memberIds?.map((memberId) => {
            let ranges;
            const roleRanges = rolesRanges[role.id];
            if (roleRanges) {
                ranges = roleRanges[memberId];
            }

            return (
                <MemberItem
                    key={memberId}
                    memberId={memberId}
                    onMemberRemove={handleMemberRemove}
                    batchActive={batchActive}
                    checkedMemberIds={checkedMemberIds}
                    onItemChecked={handleItemChecked}
                    token={token}
                    ranges={ranges}
                    onRemoveRange={handleRangeRemove}
                    onAddRanges={handleRangesAdd}
                />
            );
        });
    };

    const renderBatch = () => {
        if (!batchActive) {
            return null;
        }

        return (
            <div className={'member-table-batch mx-16'}>
                <div className={'info'}>
                    <span>{'已选择'}<span className={'select-number'}>{checkedMemberIds.size}</span>{'项'}</span>
                    <span
                        className={'cancel-select'}
                        onClick={() => {
                            handleCheckedAll(false);
                        }}
                    >
                        {'取消选择'}
                    </span>
                </div>
                <div className={'member-buttons'}>
                    <Button
                        onClick={toggleBatchActive}
                    >
                        {'取消'}
                    </Button>

                    <Button
                        danger={true}
                        type='primary'
                        disabled={checkedMemberIds.size === 0}
                        onClick={handleBatchRemove}
                    >
                        {'删除'}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            {renderTop()}
            <div className={'member-table-item mx-16 member-table-header'}>
                <div className={'cell-member'}>
                    {
                        batchActive ?
                            <Checkbox
                                className={'checked'}
                                checked={checkedAll}
                                onChange={(e: CheckboxChangeEvent) => handleCheckedAll(e.target.checked)}
                            /> : null
                    }
                    <span>{'成员姓名'}</span>
                </div>
                <div className={'cell-ranges'}>
                    <span>{'授权范围'}</span>
                </div>
                <div className={'cell-operation'}>
                    <span>{'操作'}</span>
                </div>
            </div>
            <div className={'backend-role-setting-scroll'}>
                {renderRows()}
            </div>
            {renderBatch()}
        </>
    );
};

export default MemberTable;
