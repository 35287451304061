export function highLightTerm(string, term) {
    const matchResult = highLightTermMatch(string, null, term, false);
    if (matchResult == null) {
        return string;
    }
    return matchResult;
}

export function highLightTermMatch(name, nickname, term, withComma) {
    const nameResult = getMatchResult(name, term);

    let nicknameResult = null;
    if (nickname !== null) {
        nicknameResult = getMatchResult(nickname, term);
    }

    if (nameResult !== null && nicknameResult !== null) {
        if (withComma) {
            return (
                <span className='term-in-search_result'>
                    {','}{nameResult}{'('}{nicknameResult}{')'}
                </span>
            );
        }

        return (
            <span className='term-in-search_result'>
                {nameResult}{'('}{nicknameResult}{')'}
            </span>
        );
    } else if (nameResult !== null) {
        if (withComma) {
            return (
                <span className='term-in-search_result'>
                    {','}{nameResult}
                </span>
            );
        }
        return (
            <span className='term-in-search_result'>
                {nameResult}
            </span>
        );
    } else if (nicknameResult !== null) {
        if (withComma) {
            return (
                <span className='term-in-search_result'>
                    {','}{nicknameResult}
                </span>
            );
        }
        return (
            <span className='term-in-search_result'>
                {nicknameResult}
            </span>
        );
    }
    return null;
}

function getMatchResult(str, term) {
    if (!str) {
        return null;
    }

    let matched = false;
    const result = [];
    for (let i = 0; i < str.length; i++) {
        if (term.has(str[i])) {
            matched = true;
            result.push(joinFont(str[i], i));
        } else {
            result.push(str[i]);
        }
    }

    if (matched) {
        return result;
    }
    return null;
}

function joinFont(str, i) {
    return (
        <font key={i}>{str}</font>
    );
}
export function highLightStr(originStr, targetStr, style = {fontSize: 16, lineHeight: '24px', color: '#F5222D', fontWeight: 'bolder'}) {
    if (!targetStr) {
        return (
            <span>{originStr}</span>
        );
    }

    if (originStr === targetStr) {
        return (
            <span style={style}>
                {originStr}
            </span>
        );
    }

    const beforeIndex = originStr.indexOf(targetStr);
    const afterIndex = originStr.lastIndexOf(targetStr);

    if (beforeIndex < 0 || afterIndex < 0) {
        return (
            <span>{originStr}</span>
        );
    }

    const otherChildStrings = originStr.split(targetStr);
    const spans = [];
    const otherChildStringsLength = otherChildStrings.length;
    for (let i = 0; i < otherChildStringsLength; i++) {
        spans.push(<span key={`${i}${otherChildStrings[i]}`}>{otherChildStrings[i]}</span>);
        if (i !== otherChildStringsLength - 1) {
            spans.push(
                <font
                    key={`${i}${targetStr}`}
                    style={style}
                >
                    {targetStr}
                </font>,
            );
        }
    }
    return spans;
}
