import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, message} from 'antd';

import {createStaffDataMeta, loadStaffDataMeta, modifyStaffDataMeta} from 'server/actions/custom_field';
import {CustomFieldConfigType, DataMetaType} from 'types/custom_field';

import StaffProperties from './staff_properties';

const StaffDataMeta: React.FC = () => {
    const [dataMeta, setDataMeta] = useState<DataMetaType>();

    // 删除字段操作的标识，用于区分删除操作引发的onModify调用
    const deleteId = useRef<string>();

    // 以时间戳为标识，强制更新CustomFieldConfigTable组件
    const [forceUpdate, setForceUpdate] = useState<number>();

    // 对config进行深拷贝缓存
    const [configCache, setConfigCache] = useState<CustomFieldConfigType[]>([]);

    // 缓存basic字段的名称，供字段名称校验重复
    const [basicNames, setBasicNames] = useState<string[]>();

    const initOriginData = useCallback((data: DataMetaType) => {
        setDataMeta(data);
        const validFields: CustomFieldConfigType[] = [];
        const namesTemp: string[] = [];
        data.fields?.forEach((i) => {
            if (!(i.systemic) && !(i.basic)) {
                validFields.push(i);
            }
            if (i.basic === true) {
                namesTemp.push(i.name);
            }
        });
        setConfigCache(JSON.parse(JSON.stringify(validFields || [])));
        setBasicNames(namesTemp);
    }, []);

    useEffect(() => {
        loadStaffDataMeta().then(({data}) => {
            if (data) {
                initOriginData(data);
            }
        });
    }, [initOriginData]);

    const onModify = useCallback((config: CustomFieldConfigType) => {
        setConfigCache((prevCache) => {
            const nextCahce = [...prevCache];
            const modifiedIndex = nextCahce.findIndex((item) => item.id === config.id);
            if (modifiedIndex === -1) {
                // 增
                if (config.id !== deleteId.current) {
                    nextCahce.push(config);
                    return nextCahce;
                }
            } else {
                // 改
                nextCahce[modifiedIndex] = config;
                return nextCahce;
            }
            return prevCache;
        });
    }, []);

    const onDelete = useCallback((id: string) => {
        setConfigCache((prev) => {
            const nextCahce = [...prev];
            const index = nextCahce.findIndex((item) => item.id === id);
            if (index !== -1) {
                deleteId.current = id;
                nextCahce.splice(index, 1);
                return nextCahce;
            }
            return prev;
        });
    }, []);

    const onMove = useCallback((dragIndex: number, hoverIndex: number) => {
        setConfigCache((prev) => {
            const nextCache = [...prev];
            const dragItem = nextCache[dragIndex];
            nextCache.splice(dragIndex, 1);
            nextCache.splice(hoverIndex, 0, dragItem);
            return nextCache;
        });
    }, []);

    const clickSave = () => {
        if (dataMeta && dataMeta.id) {
            modifyStaffDataMeta(dataMeta.id, {fields: configCache, version: dataMeta.version}).then(({data}) => {
                if (data) {
                    initOriginData(data);
                    message.success('保存成功');
                    setForceUpdate(new Date().valueOf());
                } else {
                    message.error('保存失败');
                }
            });
        } else if (configCache.length !== 0) {
            createStaffDataMeta({fields: configCache}).then(({data}) => {
                if (data) {
                    initOriginData(data);
                    message.success('保存成功');
                    setForceUpdate(new Date().valueOf());
                } else {
                    message.error('保存失败');
                }
            });
        }
    };

    return (
        <div className='staff-datameta-container'>
            <div className='staff-datameta-header'>
                <Button
                    type='primary'
                    onClick={clickSave}
                    disabled={dataMeta === undefined && configCache.length === 0}
                >
                    {'保存'}
                </Button>
            </div>
            <StaffProperties
                key={forceUpdate}
                basicNames={basicNames}
                customFieldConfig={configCache}
                onModify={onModify}
                onDelete={onDelete}
                onMove={onMove}
            />
        </div>
    );
};

export default StaffDataMeta;
