import React, {useState, useRef, useEffect} from 'react';
import {Tabs, Input, message} from 'antd';

import Icon from 'components/icon';
import {AUTHORIZATION_TYPE_ALL, AUTHORIZATION_TYPE_AUTHORISED, AUTHORIZATION_TYPE_TOBE} from 'utils/constants/authorise';
import {AuthorizationStatistic, StaffAuthorization, AuthorizationType, AuthorizationName} from 'types/authorise';
import {DepartmentWithMembers, StaffToDepartment} from 'types/departments';
import {Staff} from 'types/staffs';
import {
    loadAuthoriseStaffs,
    loadAuthoriseStatistic,
    loadAuthorizationMenus,
    turnOffAuthoriseStaffs,
    turnOnAuthoriseStaffs,
} from 'server/actions/authorise';
import {loadStaffsDepartments} from 'server/actions/department';
import {IAIClient} from 'server/client';
import {useDebounce} from 'utils/hooks';

import MembersManagementList from './components/members_management_list';
import BatchOperation from './components/batch_operation';

const {TabPane} = Tabs;
const COUNT = 20;

const AuthoriseManagement: React.FC = (): JSX.Element | null => {
    const {current: AuthName} = useRef(function() {
        let authName: AuthorizationName | undefined;
        switch (IAIClient.getAppType()) {
            case 'Performance':
                authName = 'Performance';
                break;
            case 'Teamwork':
                authName = 'Task';
                break;
            default:
                break;
        }
        return authName;
    }());
    const [tabkey, setTabkey] = useState(AUTHORIZATION_TYPE_ALL);
    const [statistic, setStatistic] = useState<AuthorizationStatistic>();

    // 搜索进行防抖处理
    const [term, setTerm] = useState('');
    const debouncedValue = useDebounce<string>(term, 300);

    // 部门展示数据
    const [departmentsData, setDepartmentsData] = useState<{[deparmtentId: string]: DepartmentWithMembers}>();
    const [staffToDepartmentsMap, setStaffToDepartmentsMap] = useState<StaffToDepartment>();

    // 全体授权、未授权的userIds（以下两份数据成对出现，应同步更新）
    const [authorizedIds, setAuthorizedIds] = useState<string[]>();
    const [unAuthorizedIds, setUnAuthorizedIds] = useState<string[]>();

    const onTabsChange = (activeKey: string) => {
        setTabkey(activeKey);
    };

    // 当权限管理有二级菜单的需求时，需要迁移到二级菜单的组件
    // 暂时只使用绩效的值
    const [performanceLimit, setLimit] = useState<number>();
    useEffect(() => {
        loadAuthorizationMenus().then(({data}) => {
            if (data) {
                for (const menu of data) {
                    if (menu.name === AuthName) {
                        setLimit(menu.member_limit);
                        break;
                    }
                }
            }
        });
    }, []);

    // 在授权/撤销的情况下AllMembers的数据类型会不统一，但是能满足StaffAuthorization
    const [AllMembers, setAllMembers] = useState<StaffAuthorization[]>();
    const [All_hasMore, setAll_hasMore] = useState(true);
    const All_loading = useRef(false);

    const loadDepartmentsData = (userIds: string[]) => {
        if (userIds.length > 0) {
            loadStaffsDepartments(userIds).then(({data, error}) => {
                if (error) {
                    message.error('获取部门信息失败');
                } else if (data) {
                    const {departments, staffs_departments} = data;
                    const staffToDepartment: StaffToDepartment = {};
                    staffs_departments.forEach((item) => {
                        staffToDepartment[item.user_id] = {
                            department_ids: item.department_ids,
                            main_department_id: item.main_department_id,
                        };
                    });
                    if (departmentsData) {
                        setDepartmentsData({
                            ...departmentsData,
                            ...departments,
                        });
                    } else {
                        setDepartmentsData(departments);
                    }

                    if (staffToDepartmentsMap) {
                        setStaffToDepartmentsMap({
                            ...staffToDepartmentsMap,
                            ...staffToDepartment,
                        });
                    } else {
                        setStaffToDepartmentsMap(staffToDepartment);
                    }
                }
            });
        }
    };

    const loadAllMembers = async (init = false) => {
        if (!init && (All_loading.current || !All_hasMore)) {
            return;
        }

        All_loading.current = true;
        let staffId;
        if (!init && AllMembers) {
            staffId = AllMembers[AllMembers.length - 1]?.id;
        } else {
            staffId = 0;
        }

        loadAuthoriseStaffs(AuthName, {
            type: tabkey,
            staff_id: staffId,
            term: term || undefined,
            count: COUNT,
        }).then(({data, error}) => {
            if (error) {
                message.error('用户列表获取失败');
            } else if (data) {
                if (init || !AllMembers) {
                    setAllMembers(data);
                } else {
                    setAllMembers(AllMembers?.concat(data));
                }
                if (data.length < COUNT) {
                    setAll_hasMore(false);
                } else {
                    setAll_hasMore(true);
                }

                // 拉取部门信息
                if (data.length > 0) {
                    const userIds = data.map((item) => {
                        return item.user_id;
                    });
                    loadDepartmentsData(userIds);
                }
            }
            All_loading.current = false;
        });
    };

    useEffect(() => {
        // 搜索关键词修改 -> 重新拉取统计数据
        const loadStatisticData = () => {
            loadAuthoriseStatistic(AuthName, debouncedValue || undefined).then(({data}) => {
                if (data) {
                    setStatistic(data);
                }
            });
        };
        loadStatisticData();
    }, [debouncedValue]);

    useEffect(() => {
        // 搜索关键词修改 | tab切换  -> 重新拉取人员数据
        loadAllMembers(true);
    }, [debouncedValue, tabkey]);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setTerm(value);
    };

    const insertMembers = (origin: StaffAuthorization[], insert: StaffAuthorization[]) => {
        const lastPerson = origin[origin.length - 1];
        const nextList = origin.concat(insert);
        nextList.sort((a, b) => {
            return a.id - b.id;
        });
        const index = nextList.findIndex((item) => {
            return item.user_id === lastPerson.user_id;
        });
        nextList.splice(index);
        return nextList;
    };

    // memberIds衍生自members，这里不做重复计算
    const updateMemberList = (members: (Staff | StaffAuthorization)[], memberIds: string[], authorised: boolean) => {
        if (!AllMembers) {
            return;
        }
        const effectedMember: StaffAuthorization[] = members.map((member) => {
            return {
                id: member.id,
                user_id: member.user_id,
                name: member.name,
                nickname: member.nickname,
                authorised,
            };
        });
        switch (tabkey) {
            case AUTHORIZATION_TYPE_AUTHORISED:
                if (authorised) {
                    const nextList = insertMembers(AllMembers, effectedMember);
                    setAllMembers(nextList);
                } else {
                    setAllMembers(AllMembers.filter((item) => {
                        return memberIds.indexOf(item.user_id) === -1;
                    }));
                }
                break;
            case AUTHORIZATION_TYPE_TOBE:
                if (authorised) {
                    setAllMembers(AllMembers.filter((item) => {
                        return memberIds.indexOf(item.user_id) === -1;
                    }));
                } else {
                    const nextList = insertMembers(AllMembers, effectedMember);
                    setAllMembers(nextList);
                }
                break;
            default:
            // 由于全体列表是按staffId排序的，所以只需要做update
                setAllMembers(AllMembers.map((item) => {
                    if (memberIds.indexOf(item.user_id) !== -1) {
                        return {
                            ...item,
                            authorised,
                        };
                    }
                    return item;
                }));
                break;
        }
    };

    const handleAuthorizationChange = async (members: (Staff | StaffAuthorization)[], authorised: boolean) => {
        let count = members.length;
        if (count === 0) {
            return;
        }

        let effectedMembers = members;
        let userIds = members.map((member) => {
            return member.user_id;
        });
        let canUpdate = true;
        if (authorised) {
            let remainingCount = 0;
            if (statistic && performanceLimit !== undefined) {
                remainingCount = performanceLimit - statistic.authorised_number;
                if (remainingCount < 1) {
                    message.error('操作失败，已到达企业人数上限，如需增加人数请联系客服');
                    return;
                }
            } else {
                message.warning('授权不成功，请重新尝试或刷新页面');
                return;
            }

            let noticeSuccess = true;
            if (remainingCount < count) {
                const tempIds: string[] = [];
                const tempMembers: (Staff | StaffAuthorization)[] = [];
                for (let i = 0; i < remainingCount; i++) {
                    tempIds.push(effectedMembers[i].user_id);
                    tempMembers.push(effectedMembers[i]);
                }
                userIds = tempIds;
                effectedMembers = tempMembers;
                count = remainingCount;
                message.error('操作失败，已到达企业人数上限，如需增加人数请联系客服');
                noticeSuccess = false;
            }

            await turnOnAuthoriseStaffs(AuthName, userIds).then(({error}) => {
                if (error) {
                    if (error.server_error_id === 'authorization_exceed_enterprise_limit') {
                        message.error('操作失败，已到达企业人数上限，如需增加人数请联系客服');
                    } else {
                        message.error('授权失败');
                    }
                    canUpdate = false;
                } else {
                    if (noticeSuccess) {
                        message.success('授权成功');
                    }
                    if (authorizedIds && unAuthorizedIds) {
                        setAuthorizedIds(authorizedIds.concat(userIds));
                        setUnAuthorizedIds(unAuthorizedIds.filter((itemId) => {
                            return userIds.indexOf(itemId) === -1;
                        }));
                    }

                    if (statistic) {
                        setStatistic({
                            ...statistic,
                            authorised_number: statistic.authorised_number + count,
                            to_be_authorise_number: statistic.to_be_authorise_number - count,
                        });
                    }
                }
            });
        } else {
            await turnOffAuthoriseStaffs(AuthName, userIds).then(({error}) => {
                if (error) {
                    message.error('撤销失败');
                    canUpdate = false;
                } else {
                    message.success('撤销成功');
                    if (authorizedIds && unAuthorizedIds) {
                        setAuthorizedIds(authorizedIds.filter((itemId) => {
                            return userIds.indexOf(itemId) === -1;
                        }));
                        setUnAuthorizedIds(unAuthorizedIds.concat(userIds));
                    }

                    if (statistic) {
                        setStatistic({
                            ...statistic,
                            authorised_number: statistic.authorised_number - count,
                            to_be_authorise_number: statistic.to_be_authorise_number + count,
                        });
                    }
                }
            });
        }

        if (canUpdate) {
            updateMemberList(effectedMembers, userIds, authorised);
        }
    };

    const renderMembersContent = () => {
        return (
            <div className='members-management-content'>
                <BatchOperation
                    authorizationType={tabkey as AuthorizationType}
                    handleAuthorizationChange={handleAuthorizationChange}
                    authorizedIds={authorizedIds}
                    unAuthorizedIds={unAuthorizedIds}
                    setAuthorizedIds={setAuthorizedIds}
                    setUnAuthorizedIds={setUnAuthorizedIds}
                />
                <MembersManagementList
                    authorizationType={AUTHORIZATION_TYPE_ALL}
                    loadMore={loadAllMembers}
                    handleAuthorizationChange={handleAuthorizationChange}
                    handleMainDepartmentChange={(userId: string) => loadDepartmentsData([userId])}
                    hasMoreChannels={All_hasMore}
                    members={AllMembers}
                    departments={departmentsData}
                    staffToDepartmentsMap={staffToDepartmentsMap}
                />
            </div>
        );
    };

    return (
        <div className='authorise-management'>
            <Tabs
                activeKey={tabkey}
                className='authorise-list-tabs'
                onChange={onTabsChange}
                tabBarExtraContent={
                    <Input
                        className='authorise-list-search_input'
                        placeholder='请搜索姓名'
                        allowClear={true}
                        prefix={
                            <Icon
                                type='search'
                                size={16}
                                color='#9E9EA7'
                            />
                        }
                        value={term}
                        onChange={onInputChange}
                    />
                }
                destroyInactiveTabPane={true}
            >
                <TabPane
                    tab={`全部（${statistic ? statistic.authorised_number + statistic.to_be_authorise_number : ''}）`}
                    key={AUTHORIZATION_TYPE_ALL}
                >
                    {renderMembersContent()}
                </TabPane>
                <TabPane
                    tab={`正在使用（${statistic ? statistic.authorised_number : ''}）`}
                    key={AUTHORIZATION_TYPE_AUTHORISED}
                >
                    {renderMembersContent()}
                </TabPane>
                <TabPane
                    tab={`未开启（${statistic ? statistic.to_be_authorise_number : ''}）`}
                    key={AUTHORIZATION_TYPE_TOBE}
                >
                    {renderMembersContent()}
                </TabPane>
            </Tabs>
        </div>
    );
};

export default AuthoriseManagement;
