import React from 'react';
import {Form} from 'antd';

import {FieldOptionType} from 'types/custom_field';
import type {NormalRuleProps} from '../utils';

import DraggableOption from './draggable_option';

const PriorityRule: React.FC<NormalRuleProps> = ({
    fieldConfig,
}) => {

    const renderOption = (option: FieldOptionType, index: number) => {
        return (
            <div
                key={option.value}
                className='i-field-f-option-wrapper'
            >
                <DraggableOption
                    className='priority-option'
                    index={index}
                    id={option.value}
                    text={option.label}
                    colorIcon='youxianji'
                    color={option.color}
                />
            </div>
        );
    };

    const renderOptions = () => {
        const options = fieldConfig.props?.options;
        if (options) {
            return options.map((option, index) => renderOption(option, index));
        }
        return null;
    };

    return (
        <Form.Item
            label='选项内容'
            className='i-field-f-select-options'
        >
            {renderOptions()}
        </Form.Item>
    );
};

export default PriorityRule;
