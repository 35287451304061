import React from 'react';

import WWOpenDataCom from 'components/ww_opendata';
import {isWeComEnvironment} from 'utils/utils';
import {Staff} from 'types/staffs';
import {getFullname, getSingleName} from 'utils/staff_utils';

interface StaffInfoProps {
    plain?: boolean,
    nameOnly?: boolean,
    nickOnly?: boolean,
    staff?: Staff
}

const StaffInfo: React.FC<StaffInfoProps> = ({
    plain,
    nameOnly,
    nickOnly,
    staff,
}) => {
    if (staff) {
        if (isWeComEnvironment()) {
            const type: WWOpenDataType = 'userName';
            if (nickOnly) {
                return null;
            }
            return (
                <WWOpenDataCom
                    type={type}
                    openid={staff.name}
                />
            );
        }
        if (nameOnly) {
            return <span>{staff.name}</span>;
        }
        if (nickOnly) {
            return <span>{staff.nickname}</span>;
        }
        if (plain) {
            return <span>{getSingleName(staff)}</span>;
        }
        return <span>{getFullname(staff)}</span>;
    }
    return null;
};

export default StaffInfo;
