import React, {useContext, useState} from 'react';
import {Input, Button} from 'antd';

import GlobalContext from 'context/global_context';

import {getFullname} from 'utils/staff_utils';
import {highLightTerm} from 'utils/high_light';
import {isWeComEnvironment} from 'utils/utils';

import {RoleSettingProps} from 'types/roles';
import {Staff} from 'types/staffs';

import SelectTrigger from 'components/select_trigger/select_trigger';
import StaffInfo from 'components/organization_components/staff_info';
import Icon from 'components/icon';

import MemberRow from './member_row';

const MemberList: React.FC<RoleSettingProps> = (props) => {
    const [token, setToken] = useState<string>('');
    const {role, memberIds, onMemberSelected, onMemberRemove} = props;
    const {staffs} = useContext(GlobalContext);

    const handleFilterToken: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setToken(e.target.value.trim());
    };

    const getFullName = (staff?: Staff) => {
        if (token && staff) {
            const term = new Set(token.split(''));
            return highLightTerm(getFullname(staff), term);
        }
        return <StaffInfo staff={staff}/>;
    };

    const renderItem = () => {
        if (!memberIds) {
            return null;
        }

        return memberIds.map((memberId) => {
            const member = staffs[memberId];
            if (!member || !getFullname(member).includes(token)) {
                return null;
            }

            return (
                <MemberRow
                    key={member.user_id}
                    member={member}
                    fullName={getFullName(member)}
                    onMemberRemove={onMemberRemove}
                />
            );
        });
    };

    return (
        <div className={'backend-role-setting-scroll'}>
            <div className='backend-manager-list margin-horizontal-8'>
                {/* 顶部操作区域 */}
                <div
                    className='member-table-top mx-16'
                    style={{marginBottom: 12, textAlign: 'right'}}
                >
                    {/* 搜索 */}
                    {

                        // 20230228微信环境暂时简单处理-隐藏搜索
                        !isWeComEnvironment() ?
                            <div className={'filter'}>
                                <Input
                                    prefix={
                                        <Icon
                                            type={'search'}
                                            size={16}
                                            color={'#9E9EA7'}
                                        />
                                    }
                                    placeholder={'请搜索员工姓名'}
                                    allowClear={true}
                                    onChange={handleFilterToken}
                                />
                            </div> : null
                    }
                    {/* 添加按钮 */}
                    <SelectTrigger
                        originMemberIds={memberIds}
                        onSelected={onMemberSelected}
                        extraText={`添加${role.alias_name}`}
                        holderComponent={
                            <Button type='primary'>
                                <Icon type='plus'/>
                                {`添加${role.alias_name}`}
                            </Button>
                        }
                    />
                </div>
                <div
                    className={'member-table-item mx-16 member-table-header'}
                    style={{marginTop: 0}}
                >
                    <div className={'cell-normal-content'}>
                        <span>{'姓名'}</span>
                    </div>
                    <div className={'cell-operation'}>
                        <span>{'操作'}</span>
                    </div>
                </div>
                {renderItem()}
            </div>
        </div>
    );
};

export default MemberList;
