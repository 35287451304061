import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Avatar, Input} from 'antd';

import GlobalContext from 'context/global_context';
import {isWeComEnvironment} from 'utils/utils';
import {concatImagePrefix} from 'utils/image_utils';
import Icon from 'components/icon';
import StaffInfo from 'components/organization_components/staff_info';

import {getFullname} from 'utils/staff_utils';
import {highLightTerm} from 'utils/high_light';
import {Staff} from 'types/staffs';
import {RoleSettingProps} from 'types/roles';
import noDataPng from 'images/noData.png';

import GoToWrapper from '../goto_wrapper';

const MemberSystemTable: React.FC<RoleSettingProps> = (props) => {
    const {role} = props;
    const [token, setToken] = useState<string>('');

    const {organization, loadOrganizationData, staffs, loadStaffsIfNeeded, departmentFullPath} = useContext(GlobalContext);

    const {memberIds, staffToDepartments} = useMemo(() => {
        const userIds = new Set<string>();
        const staffToDepartments: Record<string, string[]> = {};
        const roleKey = role.name as 'Hrg' | 'Manager';
        for (const d of Object.values(departmentFullPath)) {
            if (d?.data.roles_map) {
                const userId = d.data.roles_map[roleKey];
                if (userId) {
                    userIds.add(userId);
                    if (staffToDepartments[userId]) {
                        staffToDepartments[userId].push(d.fullpath);
                    } else {
                        staffToDepartments[userId] = [d.fullpath];
                    }
                }
            }
        }

        const memberIds = [...userIds];
        loadStaffsIfNeeded(memberIds);
        return {
            memberIds,
            staffToDepartments,
        };
    }, [role, departmentFullPath]);

    const isMemberNotEmpty = () => {
        return memberIds && memberIds.length > 0;
    };

    useEffect(() => {
        if (!organization) {
            loadOrganizationData();
        }
    }, [role]);

    const handleFilterToken: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setToken(e.target.value.trim());
    };

    const renderTop = () => {
        return (
            <div className={'member-table-top mx-16'}>
                {

                    // 20230228微信环境暂时简单处理-隐藏搜索
                    isMemberNotEmpty() && !isWeComEnvironment() ?
                        <div className={'filter'}>
                            <Input
                                prefix={
                                    <Icon
                                        type={'search'}
                                        size={16}
                                        color={'#9E9EA7'}
                                    />
                                }
                                placeholder={'请搜索员工姓名'}
                                allowClear={true}
                                onChange={handleFilterToken}
                            />
                        </div> : null
                }
            </div>
        );
    };

    const renderFullName = (staff?: Staff) => {
        if (token && staff) {
            const fullName = getFullname(staff);
            const term = new Set(token.split(''));
            return highLightTerm(fullName, term);
        }

        return <StaffInfo staff={staff}/>;
    };

    const renderDepartments = (user_id: string) => {
        const departmentNames = staffToDepartments?.[user_id];
        if (departmentNames) {
            return departmentNames.map((dName, index) => {
                return (
                    <div
                        key={`${dName}-${index}`}
                        style={{whiteSpace: 'normal'}}
                    >
                        {dName}
                    </div>
                );
            });
        }
    };

    const renderContent = () => {
        if (isMemberNotEmpty()) {
            return (
                <>
                    <div className={'member-table-item mx-16 member-table-header'}>
                        <div className={'cell-normal-content'}>
                            <span>{'姓名'}</span>
                        </div>
                        <div className={'cell-normal-content'}>
                            <span>{'部门'}</span>
                        </div>
                    </div>
                    <div className={'backend-role-setting-scroll'}>
                        {memberIds?.map((memberId) => {
                            const member = staffs[memberId];
                            if (token) {
                                let fullName = '';
                                if (member) {
                                    fullName = getFullname(member);
                                }
                                if (!fullName.includes(token)) {
                                    return null;
                                }
                            }
                            return (
                                <div
                                    key={memberId}
                                    className={'member-table-item mx-16'}
                                >
                                    <div className={'cell-normal-content'}>
                                        <div
                                            className='single-normal-user-container'
                                            style={{padding: 0, height: 'auto'}}
                                        >
                                            <Avatar
                                                src={concatImagePrefix(member?.avatar?.small)}
                                                size={24}
                                            />
                                            <div
                                                className='single_user-right-container'
                                                style={{marginLeft: 8}}
                                            >
                                                <div className='single-normal-user-info'>
                                                    <div
                                                        className='single-normal-user-name'
                                                    >{renderFullName(member)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'cell-normal-content'}>
                                        {renderDepartments(memberId)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            );
        }

        return (
            <div className='empty-content-container'>
                <img
                    className='empty-content-img'
                    alt='no-data'
                    src={noDataPng}
                />
                <div className='empty-content-tips'>暂无数据，去<GoToWrapper text='修改组织架构'/></div>
            </div>
        );
    };

    return (
        <>
            {renderTop()}
            {renderContent()}
        </>
    );
};

export default MemberSystemTable;
