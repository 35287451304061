import React, {useState} from 'react';
import {Modal} from 'antd';

import classnames from 'classnames';

import Icon, {IconWithHighlight} from 'components/icon';

import {envEnum} from 'utils/constants/general';

import LogsList from './logs_list';

const title = '操作日志';

const LogsTrigger: React.FC = () => {
    const [visible, setVisible] = useState(false);

    const roleLogBtnClass = classnames('role-log-btn', {
        'role-log-btn--performance-role': window.name === envEnum.PERFORMANCE_ROLE,
    });

    return (
        <>
            <div
                className={roleLogBtnClass}
                onClick={() => setVisible(true)}
            >
                <Icon type='caozuorizhi'/>
                <span>{title}</span>
            </div>
            <Modal
                title={title}
                open={visible}
                onCancel={() => setVisible(false)}
                width={840}
                footer={null}
                closeIcon={
                    <IconWithHighlight
                        type='close_normal'
                        size={16}
                        color='#333333'
                    />
                }
                destroyOnClose={true}
            >
                <LogsList/>
            </Modal>
        </>
    );
};

export default LogsTrigger;
