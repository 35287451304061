import React, {useContext, useEffect} from 'react';

import GlobalContext from 'context/global_context';

import PureOrganizationTree, {OrganizationTreeProps} from './organization_tree';

// 此组件作为一个中间件（用于全局数据、api注入）
type ExcludeKeys = 'organization' | 'enterpriseInfo' | 'loadEnterpriseInfo' | 'departmentFullPath' | 'staffs' | 'loadStaffsIfNeeded';
const OrganizationTree: React.FC<{excludeNoIsv?: boolean} & Omit<OrganizationTreeProps, ExcludeKeys>> = ({excludeNoIsv, ...props}): JSX.Element | null => {
    const {
        organization,
        loadOrganizationData,
        oExcludeNoIsv,
        loadOExcludeNoIsv,
        enterpriseInfo,
        loadEnterpriseInfo,
        departmentFullPath,
        staffs,
        loadStaffsIfNeeded,
    } = useContext(GlobalContext);

    useEffect(() => {
        if (!organization) {
            loadOrganizationData();
        }
        if (excludeNoIsv && !oExcludeNoIsv) {
            loadOExcludeNoIsv?.();
        }
        if (!enterpriseInfo) {
            loadEnterpriseInfo();
        }
    }, []);

    return (
        <PureOrganizationTree
            {...props}
            treeData={excludeNoIsv ? oExcludeNoIsv : organization}
            departmentFullPath={departmentFullPath}
            enterpriseInfo={enterpriseInfo}
            staffs={staffs}
            loadStaffsIfNeeded={loadStaffsIfNeeded}
        />
    );
};
OrganizationTree.displayName = 'OrganizationTree';
export default OrganizationTree;
