import Cookies from 'js-cookie';

import {Options, ClientResponse, StatusOK} from 'server/types/client';
import {ServerError} from 'server/types/error';

import {
    AuthorizationMenu,
    AuthorizationName,
    AuthorizationStatistic,
    AuthorizationType,
    StaffAuthorization,
    StaffAuthorizations,
} from 'types/authorise';
import {DepartmentNode, EnterpriseInfo, EnterpriseOrder, EnterpriseSummary} from 'types/organization';
import {AuthorityLog, AuthorityParams, Role, RoleForm} from 'types/roles';
import {SearchStaffsParams, Staff} from 'types/staffs';

import {Enterprise} from 'types/enterprise';
import {Feature} from 'types/feature';
import {Dictionary, RoleUserRange, SimpleRoleUserRange} from 'types/role_user_range';
import {TripartiteConfigParams, DingtalkSSOParams, OSyncStatus, UserType, WecomSSOParams, WeWorkConfigParams, KaixueSSOParams, KaixueBasicInfoParams} from 'types/users';
import {CollaborationDataMeta, CustomFieldConfigType, DataMetaType, ModifyCollaborationDataMetaParams, ModifyStaffDataMetaParams} from 'types/custom_field';
import {DingTalkEmployeeInfo} from 'server/types/actions';

import fetch from './fetch_etag';

const HEADER_AUTH = 'Authorization';
const HEADER_BEARER = 'BEARER';
const HEADER_REQUESTED_WITH = 'X-Requested-With';
const HEADER_USER_AGENT = 'User-Agent';
const HEADER_X_CSRF_TOKEN = 'X-CSRF-Token';
export const HEADER_X_VERSION_ID = 'X-Version-Id';

export default class IAIClient {
    logToConsole = false;
    serverVersion = '';
    token = '';
    csrf = '';
    apiPrefix = process.env.IAI_API_PREFIX;
    userAgent = null;
    userId = '';
    enterpriseId = '';
    appType = '';
    openPlatformType = '';
    defaultHeaders: any = {};
    includeCookies = true;

    setCSRF(csrfToken: string) {
        this.csrf = csrfToken;
    }

    setUserId(userId: string) {
        this.userId = userId;
    }

    getUserId(): string {
        return this.userId;
    }

    setEnterpriseId(enterpriseId: string) {
        this.enterpriseId = enterpriseId;
    }

    getEnterpriseId(): string {
        return this.enterpriseId;
    }

    setAppType(appType: string) {
        this.appType = appType;
    }

    getAppType(): string {
        return this.appType;
    }

    setOpenPlatformType(openPlatformType: string) {
        this.openPlatformType = openPlatformType;
    }

    getOpenPlatformType(): string {
        return this.openPlatformType;
    }

    setAcceptLanguage(locale: string) {
        this.defaultHeaders['Accept-Language'] = locale;
    }

    setIncludeCookies(include: boolean) {
        this.includeCookies = include;
    }

    getBaseRoute() {
        return this.apiPrefix;
    }

    getEnterprisesRoute() {
        return `${this.getBaseRoute()}/enterprises`;
    }

    getEnterpriseRoute(enterpriseId: string) {
        return `${this.getEnterprisesRoute()}/${enterpriseId}`;
    }

    getDepartmentsRoute() {
        return `${this.getBaseRoute()}/departments`;
    }

    loadUser = async () => {
        return this.doFetch<UserType>(
            `${this.getBaseRoute()}/users`,
            {method: 'get'},
        );
    };

    loadClientConfig = async () => {
        return this.doFetch(
            `${this.getBaseRoute()}/config/client`,
            {method: 'get'},
        );
    };

    logs = (message: string) => {
        return this.doFetch(
            `${this.getBaseRoute()}/logs`,
            {method: 'post', body: JSON.stringify({level: 'message', message})},
        );
    };

    // Enterprise Routes
    loadEnterprisesByUserId = async () => {
        return this.doFetch<Enterprise[]>(
            `${this.getEnterprisesRoute()}`,
            {method: 'get'},
        );
    };

    loadEnterpriseInfoById = async (enterpriseId: string) => {
        return this.doFetch<EnterpriseInfo>(
            `${this.getEnterprisesRoute()}/${enterpriseId}`,
            {method: 'get'},
        );
    };

    loadEnterpriseSummaryById = async (enterpriseId: string) => {
        return this.doFetch<EnterpriseSummary>(
            `${this.getEnterprisesRoute()}/${enterpriseId}/summary`,
            {method: 'get'},
        );
    };

    loadEnterpriseOrders = async (enterpriseId: string) => {
        return this.doFetch<EnterpriseOrder[]>(
            `${this.getEnterprisesRoute()}/${enterpriseId}/orders`,
            {method: 'get'},
        );
    };

    loadUserRoles = async (enterpriseId: string) => {
        return this.doFetch(
            `${this.getEnterpriseRoute(enterpriseId)}/user/roles`,
            {method: 'get'},
        );
    };

    //  Role & Feature & Authorization & range Routes
    loadRoleList = async (enterpriseId: string) => {
        return this.doFetch<Role[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles`,
            {method: 'get'},
        );
    };

    loadAuthorityLogs = (enterpriseId: string, params: AuthorityParams) => {
        return this.doFetch<AuthorityLog[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/authority_logs`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    addRole = async (enterpriseId: string, role: RoleForm) => {
        return this.doFetch<Role>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles`,
            {method: 'post', body: JSON.stringify(role)},
        );
    };

    modifyRole = async (enterpriseId: string, roleId: number, role: RoleForm) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}`,
            {method: 'put', body: JSON.stringify(role)},
        );
    };

    removeRole = async (enterpriseId: string, roleId: number) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}`,
            {method: 'delete'},
        );
    };

    loadRoleUsers = async (enterpriseId: string, roleId: number) => {
        return this.doFetch<string[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users`,
            {method: 'get'},
        );
    };

    addRoleUsers = async (enterpriseId: string, roleId: number, userIds: string[]) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users`,
            {method: 'post', body: JSON.stringify(userIds)},
        );
    };

    removeRoleUsers = async (enterpriseId: string, roleId: number, userIds: string[]) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users`,
            {method: 'delete', body: JSON.stringify(userIds)},
        );
    };

    loadFeatureList = async (enterpriseId: string) => {
        return this.doFetch<Feature[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/features`,
            {method: 'get'},
        );
    };

    loadRoleFeatureTokens = async (enterpriseId: string, roleId: number) => {
        return this.doFetch<string[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/features`,
            {method: 'get'},
        );
    };

    addRoleFeatures = async (enterpriseId: string, roleId: number, featureIds: number[]) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/features`,
            {method: 'post', body: JSON.stringify(featureIds)},
        );
    };

    removeRoleFeatures = async (enterpriseId: string, roleId: number, featureIds: number[]) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/features`,
            {method: 'delete', body: JSON.stringify(featureIds)},
        );
    };

    loadRoleUsersRanges = async (enterpriseId: string, roleId: number) => {
        return this.doFetch<Dictionary<RoleUserRange[]>>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users/ranges`,
            {method: 'get'},
        );
    };

    loadRoleUserRanges = async (enterpriseId: string, roleId: number, userId: string) => {
        return this.doFetch<RoleUserRange[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users/${userId}/ranges`,
            {method: 'get'},
        );
    };

    addRoleUserRanges = async (enterpriseId: string, roleId: number, userId: string, ranges: SimpleRoleUserRange[]) => {
        return this.doFetch<RoleUserRange[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users/${userId}/ranges`,
            {method: 'post', body: JSON.stringify(ranges)},
        );
    };

    removeRoleUserRanges = async (enterpriseId: string, roleId: number, userId: string, ranges: SimpleRoleUserRange[]) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/${roleId}/users/${userId}/ranges`,
            {method: 'delete', body: JSON.stringify(ranges)},
        );
    };

    // Organization Api

    loadDepartmentTree = async (enterpriseId: string, departmentId: number, extraCondition: string) => {
        return this.doFetch<DepartmentNode>(
            `${this.getEnterpriseRoute(enterpriseId)}/departments/${departmentId}/tree${extraCondition}`,
            {method: 'get'},
        );
    };

    loadStaffsDepartments = (enterpriseId: string, userIds: string[]) => {
        return this.doFetch<any>(
            `${this.getEnterpriseRoute(enterpriseId)}/staffs/departments/full_paths`,
            {method: 'POST', body: JSON.stringify(userIds)},
        );
    };
    loadStaffsDepartmentFullPaths = (enterpriseId: string, userId: string) => {
        return this.doFetch<any>(
            `${this.getEnterpriseRoute(enterpriseId)}/staffs/${userId}/departments/full_paths`,
            {method: 'GET'},
        );
    };

    searchStaffs = async (enterpriseId: string, params: SearchStaffsParams) => {
        return this.doFetch(
            `${this.getEnterprisesRoute()}/${enterpriseId}/search/staffs`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    getStaffsByUserIds = async (userIds: string[]): Promise<Staff[]> => {
        return this.doFetch(
            `${this.getEnterprisesRoute()}/${this.getEnterpriseId()}/staffs`,
            {method: 'post', body: JSON.stringify(userIds)},
        );
    };

    modifyDepartment = async (params: object, departmentId: number, modifyMode: string) => {
        return this.doFetch<StatusOK>(
            `${this.getDepartmentsRoute()}/${departmentId}${modifyMode ? ('/' + modifyMode) : ''}`,
            {method: 'put', body: JSON.stringify(params)},
        );
    };

    removeHrg = async (departmentId: number) => {
        return this.doFetch<StatusOK>(
            `${this.getDepartmentsRoute()}/${departmentId}/hrg`,
            {method: 'delete'},
        );
    };

    setDepartmentMain = async (enterpriseId: string, userId: string, departmentId: number) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/staffs/${userId}/departments/main`,
            {method: 'post', body: JSON.stringify({department_id: departmentId})},
        );
    };

    loadSimpleStaffs = async (enterpriseId: string, userIds: string[]) => {
        return this.doFetch<Staff[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/staffs/list`,
            {method: 'post', body: JSON.stringify(userIds)},
        );
    };

    loadEnterpriseAdmins = async (enterpriseId: string) => {
        return this.doFetch<Staff[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/staffs/admins`,
            {method: 'get'},
        );
    };

    // Product Authorise Api
    loadAuthoriseStatistic = async (enterpriseId: string, name: AuthorizationName, params: {term: string | undefined}) => {
        return this.doFetch<AuthorizationStatistic>(
            `${this.getEnterprisesRoute()}/${enterpriseId}/product_auths/${name}/statistic`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loadAuthoriseStaffs = async (enterpriseId: string, name: AuthorizationName, params: {
        type: AuthorizationType,
        staff_id: number,
        term: string,
        count: number,
    }) => {
        return this.doFetch<StaffAuthorization[]>(
            `${this.getEnterprisesRoute()}/${enterpriseId}/product_auths/${name}/staffs`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    turnOnAuthoriseStaffs = async (enterpriseId: string, params: {
        user_ids: string[],
        name: AuthorizationName,
    }) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterprisesRoute()}/${enterpriseId}/product_auths/staffs`,
            {method: 'put', body: JSON.stringify(params)},
        );
    };

    turnOffAuthoriseStaffs = async (enterpriseId: string, params: {
        user_ids: string[],
        name: AuthorizationName,
    }) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterprisesRoute()}/${enterpriseId}/product_auths/staffs`,
            {method: 'delete', body: JSON.stringify(params)},
        );
    };

    queryAuthorizations = async (enterpriseId: string, userIds: string[]) => {
        return this.doFetch<StaffAuthorizations[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/product_auths`,
            {method: 'post', body: JSON.stringify(userIds)},
        );
    };

    loadAuthorizationMenus = async (enterpriseId: string) => {
        return this.doFetch<AuthorizationMenu[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/product_auths/menus`,
            {method: 'get'},
        );
    };

    // 三方接入中，可能公司未设置首个管理员的特殊需求
    initEnterpriseAdmin = async (enterpriseId: string, userId: string) => {
        return this.doFetch<StatusOK>(
            `${this.getEnterpriseRoute(enterpriseId)}/roles/admin`,
            {method: 'post', body: JSON.stringify({user_id: userId})},
        );
    };

    // 三方相关api

    checkDingtalkOrgauthscope = async (enterpriseId: string) => {
        return this.doFetch<{auth_scope_all: boolean}>(
            `${this.getBaseRoute()}/enterprises/${enterpriseId}/dingtalk/orgauthscope`,
            {method: 'get'},
        );
    };

    loadDingtalkCustomerServiceConfig = async (enterpriseId: string) => {
        return this.doFetch<any>(
            `${this.getBaseRoute()}/enterprises/${enterpriseId}/dingtalk/customerserviceconfig
            `,
            {method: 'get'},
        );
    };

    loadDingtalkEmployeeInfo = async (enterpriseId: string, userId: string) => {
        return this.doFetch<DingTalkEmployeeInfo>(
            `${this.getBaseRoute()}/enterprises/${enterpriseId}/dingtalk/hrm/staffs/${userId}/employee_info`,
            {method: 'get'},
        );
    };

    loginByDdOauth = async (params: DingtalkSSOParams) => {
        return this.doFetch(
            `${this.getBaseRoute()}/open_platform/dingtalk/sso`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loadDdJsConfig = async (params: TripartiteConfigParams) => {
        return this.doFetch(
            `${this.getBaseRoute()}/open_platform/dingtalk/jsconfig`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loadDingtalkSyncStatus = async (corpId: string) => {
        return this.doFetch<OSyncStatus>(
            `${this.getBaseRoute()}/open_platform/dingtalk/${corpId}/syncstatus`,
            {method: 'get'},
        );
    };

    loadDingTalkEnterpriseId = async (corpId: string) => {
        return this.doFetch<{enterprise_id: string}>(
            `${this.getBaseRoute()}/open_platform/dingtalk/${corpId}`,
            {method: 'get'},
        );
    };

    getWeComCorpIdByCode = async (code: string) => {
        return this.doFetch<{corp_id: string, user_id: string}>(
            `${this.getBaseRoute()}/open_platform/wecom/code/${code}`,
            {method: 'get'},
        );
    };

    loginByWeComOauth = async (params: WecomSSOParams) => {
        return this.doFetch<UserType>(
            `${this.getBaseRoute()}/open_platform/wecom/sso`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loadWeComJsConfig = async (params: WeWorkConfigParams) => {
        return this.doFetch(
            `${this.getBaseRoute()}/open_platform/wecom/jsconfig`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loadWeComSyncStatus = async (corpId: string) => {
        // 拉取企业架构初始化状态
        return this.doFetch<OSyncStatus>(
            `${this.getBaseRoute()}/open_platform/wecom/${corpId}/syncstatus`,
            {method: 'get'},
        );
    };

    loadWeComEnterpriseId = async (corpId: string) => {
        return this.doFetch<{enterprise_id: string}>(
            `${this.getBaseRoute()}/open_platform/wecom/${corpId}`,
            {method: 'get'},
        );
    };

    handleWeComSync = async (enterpriseId: string) => {
        return this.doFetch<OSyncStatus>(
            `${this.getBaseRoute()}/open_platform/wecom/organization/${enterpriseId}/sync`,
            {method: 'post'},
        );
    };

    loadWeComInitiativeSyncStatus = async (enterpriseId: string, syncId: number) => {
        // 拉取用户主动同步企业架构的状态
        return this.doFetch<OSyncStatus>(
            `${this.getBaseRoute()}/open_platform/wecom/organization/${enterpriseId}/sync/${syncId}/status`,
            {method: 'get'},
        );
    };

    loadKaiXueBasicParams = async (params: KaixueBasicInfoParams) => {
        return this.doFetch<{user_id: string, enterprise_id: string}>(
            `${this.getBaseRoute()}/open_platform/kaixue/enterprise`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loginByKaiXueOauth = async (params: KaixueSSOParams) => {
        return this.doFetch(
            `${this.getBaseRoute()}/open_platform/kaixue/sso`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    // Staff DataMeta api
    // 原始url为 data_metas/{application}/{module}/{relation_id}
    loadStaffDataMeta = (enterpriseId: string) => {
        return this.doFetch<DataMetaType | null>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/organization/staffs/staff`,
            {method: 'get'},
        );
    };

    createStaffDataMeta = (enterpriseId: string, params: Omit<ModifyStaffDataMetaParams, 'version'>) => {
        return this.doFetch<DataMetaType>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/organization/staffs/staff`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    modifyStaffDataMeta = (enterpriseId: string, id: number, params: ModifyStaffDataMetaParams) => {
        return this.doFetch<DataMetaType>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/organization/staffs/${id}`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    loadDefaultCollaboration = (enterpriseId: string) => {
        return this.doFetch<CustomFieldConfigType[]>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/collaboration/default/fields`,
            {method: 'get'},
        );
    };

    loadCollaborationDataMeta = (enterpriseId: string) => {
        return this.doFetch<CollaborationDataMeta[] | null>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/collaboration/true`,
            {method: 'get'},
        );
    };

    createCollaborationDataMeta = (enterpriseId: string, params: Omit<ModifyCollaborationDataMetaParams, 'version'>) => {
        return this.doFetch<CollaborationDataMeta>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/collaboration`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    modifyCollaborationDataMeta = (enterpriseId: string, id: number, params: ModifyCollaborationDataMetaParams) => {
        return this.doFetch<CollaborationDataMeta>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/collaboration/${id}`,
            {method: 'post', body: JSON.stringify(params)},
        );
    };

    deleteCollaborationDataMeta = (enterpriseId: string, id: number) => {
        return this.doFetch<CollaborationDataMeta>(
            `${this.getEnterpriseRoute(enterpriseId)}/data_metas/collaboration/${id}`,
            {method: 'delete'},
        );
    };

    // Utils
    getCSRFFromCookie() {
        const csrf = Cookies.get('IAICSRF');
        if (csrf) {
            this.setCSRF(csrf);
            return csrf;
        }
        return '';
    }

    getOptions(options: Options) {
        const newOptions = Object.assign({}, options);

        const headers = {
            [HEADER_REQUESTED_WITH]: 'XMLHttpRequest',
            ...this.defaultHeaders,
        };

        if (this.token) {
            headers[HEADER_AUTH] = `${HEADER_BEARER} ${this.token}`;
        }

        const csrfToken = this.csrf || this.getCSRFFromCookie();
        if (options.method && options.method.toLowerCase() !== 'get' && csrfToken) {
            headers[HEADER_X_CSRF_TOKEN] = csrfToken;
        }

        if (this.includeCookies) {
            newOptions.credentials = 'include';
        }

        if (this.userAgent) {
            headers[HEADER_USER_AGENT] = this.userAgent;
        }

        if (newOptions.headers) {
            Object.assign(headers, newOptions.headers);
        }

        return {
            ...newOptions,
            headers,
        };
    }

    // Client Helpers

    doFetch = async <T>(url: string, options: Options): Promise<T> => {
        const {data} = await this.doFetchWithResponse<T>(url, options);

        return data;
    };

    doFetchWithResponse = async <T>(url: string, options: Options): Promise<ClientResponse<T>> => {
        const response = await fetch(url, this.getOptions(options));
        const headers = parseAndMergeNestedHeaders(response.headers);

        let data;
        try {
            data = await response.json();
        } catch (err) {
            throw new ClientError({
                message: 'Received invalid response from the server.',
                intl: {
                    id: 'mobile.request.invalid_response',
                    defaultMessage: 'Received invalid response from the server.',
                },
                url,
            });
        }

        if (headers.has(HEADER_X_VERSION_ID) && !headers.get('Cache-Control')) {
            const serverVersion = headers.get(HEADER_X_VERSION_ID);
            if (serverVersion && this.serverVersion !== serverVersion) {
                this.serverVersion = serverVersion;
            }
        }

        let dingtalkAuthored: boolean;
        switch (data && data.id) {
            case 'enterprise_no_product_auth':
                dingtalkAuthored = false;
                break;
            case 'enterprise_user_exceed_limit':
                dingtalkAuthored = false;
                break;
            case 'user_no_product_auth':
                dingtalkAuthored = false;
                break;
            default:
                dingtalkAuthored = true;
                break;
        }

        if (response.ok && dingtalkAuthored) {
            return {
                response,
                headers,
                data,
            };
        }

        const msg = data.message || '';

        if (this.logToConsole) {
            console.error(msg); // eslint-disable-line no-console
        }

        throw new ClientError({
            message: msg,
            server_error_id: data.id,
            status_code: data.status_code,
            url,
        });
    };
}

function parseAndMergeNestedHeaders(originalHeaders: any) {
    const headers = new Map();
    let nestedHeaders = new Map();
    originalHeaders.forEach((val: string, key: string) => {
        const capitalizedKey = key.replace(/\b[a-z]/g, (l) => l.toUpperCase());
        let realVal = val;
        if (val && val.match(/\n\S+:\s\S+/)) {
            const nestedHeaderStrings = val.split('\n');
            realVal = nestedHeaderStrings.shift() as string;
            const moreNestedHeaders = new Map(
                nestedHeaderStrings.map((h: any) => h.split(/:\s/)),
            );
            nestedHeaders = new Map([...nestedHeaders, ...moreNestedHeaders]);
        }
        headers.set(capitalizedKey, realVal);
    });
    return new Map([...headers, ...nestedHeaders]);
}

export class ClientError extends Error implements ServerError {
    url?: string;
    intl?: {
        id: string;
        defaultMessage: string;
        values?: any;
    };
    server_error_id?: string;
    status_code?: number;

    constructor(data: ServerError) {
        super(data.message + ': ' + data.url);

        this.message = data.message;
        this.url = data.url;
        this.intl = data.intl;
        this.server_error_id = data.server_error_id;
        this.status_code = data.status_code;

        // Ensure message is treated as a property of this class when object spreading. Without this,
        // copying the object by using `{...error}` would not include the message.
        Object.defineProperty(this, 'message', {enumerable: true});
    }
}
