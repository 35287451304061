import PropTypes from 'prop-types';
import React from 'react';

import OrganizationTree from 'components/organization_tree';
import {STAFF_KEY} from 'components/organization_tree/tree_utils';
import {getUserIdsFromDepartmentTree} from 'utils/department';
import {isDingtalkEnvironment} from 'utils/utils';

import ItemsSelected from './components/items_selected.jsx';

export default class DepartmentMemberSelect extends React.Component {
    static propTypes = {
        departmentSelectable: PropTypes.bool.isRequired,
        originItems: PropTypes.array.isRequired,
        singleChoice: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        onSelected: PropTypes.func.isRequired,

        staffs: PropTypes.object.isRequired,
        loadStaffsIfNeeded: PropTypes.func.isRequired,
        departmentFullPath: PropTypes.object,
    };

    static defaultProps = {
        originItems: [],
        singleChoice: false,
        departmentSelectable: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            originItems: [],
            originSelectedIds: [],
            disabledItemIds: [],

            selectedItems: [],
            selectedItemIds: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {originItems} = props;
        if (originItems && originItems !== state.originItems) {
            const disabledItemIds = [];
            const originSelectedIds = [];
            for (const item of originItems) {
                if (item.disabled === true) {
                    disabledItemIds.push(item.id);
                }
                if (item.selected === true) {
                    originSelectedIds.push(item.id);
                }
            }

            return {
                originItems,
                originSelectedIds,
                disabledItemIds,
            };
        }

        return null;
    }

    transformItem = (item) => {
        let userIds = [];
        if (this.props.departmentSelectable) {
            if (item.type === STAFF_KEY) {
                userIds.push(item.id);
            } else {
                item.id = `${item.id}`;
            }
            return {userIds, items: [item], keys: new Set([item.id])};
        }

        if (item.type === STAFF_KEY) {
            userIds.push(item.id);
            return {userIds, items: [item], keys: new Set([item.id])};
        }

        const {departmentFullPath} = this.props;
        if (!departmentFullPath || !departmentFullPath[item.id]) {
            return {};
        }

        userIds = getUserIdsFromDepartmentTree(departmentFullPath[item.id].data);

        const items = [];
        const keys = new Set();
        for (const userId of userIds) {
            items.push({
                type: STAFF_KEY,
                id: userId,
            });

            keys.add(userId);
        }

        return {userIds, items, keys};
    };

    handleItemSelected = (item, deleted = false) => {
        if (item) {
            const {userIds, items, keys} = this.transformItem(item);
            if (!items) {
                return;
            }

            if (this.props.singleChoice) {
                if (item.type === STAFF_KEY) {
                    // 单选只支持人员的选择
                    const nextSelectedItems = [];
                    const nextSelectedItemIds = [];
                    if (deleted === false) {
                        nextSelectedItems.push(items[0]);
                        nextSelectedItemIds.push(items[0].id);
                    }
                    this.setState({selectedItems: nextSelectedItems, selectedItemIds: nextSelectedItemIds});
                }
            } else {
                const {selectedItems, selectedItemIds, disabledItemIds} = this.state;
                let nextSelectedItems;
                let nextSelectedItemIds;

                if (deleted) {
                    nextSelectedItems = [];
                    nextSelectedItemIds = [];
                    for (let i = 0; i < selectedItems.length; i++) {
                        const key = selectedItemIds[i];
                        if (keys.has(key)) {
                            continue;
                        }

                        nextSelectedItems.push(selectedItems[i]);
                        nextSelectedItemIds.push(key);
                    }
                } else {
                    const originKeys = new Set(selectedItemIds);
                    const disabledKeys = new Set(disabledItemIds);
                    nextSelectedItems = [...selectedItems];
                    nextSelectedItemIds = [...selectedItemIds];
                    for (const one of items) {
                        if (originKeys.has(one.id) || disabledKeys.has(one.id)) {
                            continue;
                        }
                        nextSelectedItems.push(one);
                        nextSelectedItemIds.push(one.id);
                    }
                }

                this.setState({selectedItems: nextSelectedItems, selectedItemIds: nextSelectedItemIds});
            }

            if (!deleted && userIds.length) {
                this.props.loadStaffsIfNeeded(userIds);
            }
        }
    };

    handleConfirm = () => {
        const {selectedItems} = this.state;
        this.props.onSelected(selectedItems);
        this.setState({
            selectedItems: [],
            selectedItemIds: [],
        });
    };

    render() {
        // selectedItems 与 selectedItemIds 应保持 同步 且 一一对应
        // 已选项在展现层的数据应为 selectedItemIds 与 originSelectedIds 的并集
        const {originSelectedIds, disabledItemIds, selectedItems, selectedItemIds} = this.state;
        const totalSelectedIds = [...new Set(selectedItemIds.concat(originSelectedIds))];

        // 钉钉环境内的特殊需求： [uxg5w87obpbffrwxagpbrxxqgh]排除“绩效应用外部”未授权用户
        const excludeNoIsv = isDingtalkEnvironment();

        const left = (
            <ItemsSelected
                departmentSelectable={this.props.departmentSelectable}
                staffs={this.props.staffs}
                departmentFullPath={this.props.departmentFullPath}
                selectedItems={selectedItems}
                selectedItemIds={totalSelectedIds}
                itemIdsDisabled={disabledItemIds}
                onItemSelected={this.handleItemSelected}
                onConfirm={this.handleConfirm}
                onClose={this.props.onClose}
                excludeNoIsv={excludeNoIsv}
            />
        );

        const right = (
            <OrganizationTree
                departmentSelectable={this.props.departmentSelectable}
                checkable={true}
                singleChoice={this.props.singleChoice}
                onItemSelected={this.handleItemSelected}
                itemIdsSelected={totalSelectedIds}
                itemIdsDisabled={disabledItemIds}
                excludeNoIsv={excludeNoIsv}
            />
        );

        return (
            <div
                id='Members_Selector'
                className='search-select-container'
            >
                {left}
                {right}
            </div>
        );
    }
}
