import React, {useRef, useCallback} from 'react';
import {Button, Drawer, FormInstance} from 'antd';

import Icon from 'components/icon';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
import type {CustomFieldConfigType} from 'types/custom_field';
import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';

import CustomFieldForm, {CustomFieldRef} from './field_setting_form';

type Props = {
    basicNames?: string[]
    totalCustomFields: CustomFieldConfigType[]
    typeOptions: CUSTOM_FIELD_TYPES[]
    colorfulOptions?: boolean

    modifyItem: CustomFieldConfigType
    typeMutable?: boolean
    showUsageSetting?: boolean
    editing?: boolean
    // 返回一个新构造的CustomFieldConfigType
    onModify: (config: CustomFieldConfigType) => void
    onDelete?: (id: string) => void
    handleClose: () => void
};

const CustomFieldDrawer: React.FC<Props> = ({
    basicNames,
    totalCustomFields,
    typeOptions,
    colorfulOptions,
    modifyItem,
    typeMutable,
    showUsageSetting,
    editing,
    onModify,
    onDelete,
    handleClose,
}) => {
    const formInstance = useRef<FormInstance | undefined>();
    const customComponentRef = useRef<CustomFieldRef>(null);

    const getFormInstance = useCallback((field: FormInstance) => {
        formInstance.current = field;
    }, []);

    const onClose = () => {
        if (!editing) {
            handleClose();
            return;
        }

        if (formInstance) {
            formInstance.current?.validateFields().then(() => {
                const optionErros = customComponentRef.current?.getOptionErrors();
                if (optionErros) {
                    for (const error of Object.values(optionErros)) {
                        if (error) {
                            return;
                        }
                    }
                }
                handleClose();
            }).catch(() => {
                // 阻止关闭
            });
        }
    };

    let fieldDrawer = null;
    if (modifyItem) {
        let deleteBtn;
        if (
            modifyItem.basic !== true &&
            modifyItem.deletable !== false &&
            onDelete &&
            editing === true
        ) {
            deleteBtn = (
                <Button
                    type='primary'
                    danger={true}
                    onClick={() => {
                        EventEmitter.emit(EventTypeEnum.DELETE_CONFIRM, {
                            title: modifyItem.name,
                            subTitle: '删除后数据不可恢复，若您希望保留数据，可关闭使用该列。若您继续删除，请输入列名称确认操作。',
                            placeholder: modifyItem.name,
                            successCallback: () => onDelete(modifyItem.id),
                        });
                    }}
                    disabled={!Boolean(modifyItem.name)}
                >删除</Button>
            );
        }
        fieldDrawer = (
            <Drawer
                title={`${editing ? '编辑' : '查看'}列`}
                width={497}
                open={modifyItem !== null}
                closable={false}
                onClose={onClose}
                extra={
                    <Icon
                        type='close_normal'
                        onClick={onClose}
                    />
                }
                footer={deleteBtn}
                footerStyle={{textAlign: 'right'}}
                destroyOnClose={true}
                maskStyle={{background: 'transparent'}}
                contentWrapperStyle={{
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                    overflow: 'hidden'
                }}
            >
                <CustomFieldForm
                    key={modifyItem.id}
                    ref={customComponentRef}
                    typeOptions={typeOptions}
                    colorfulOptions={colorfulOptions}
                    totalCustomFields={totalCustomFields}
                    basicNames={basicNames}
                    fieldConfig={modifyItem}
                    onModify={onModify}
                    getFormInstance={getFormInstance}
                    typeMutable={typeMutable}
                    showUsageSetting={showUsageSetting}
                    disabled={!editing}
                />
            </Drawer>
        );
    }

    return fieldDrawer;
};

export default CustomFieldDrawer;
