import React from 'react';

import Icon from 'components/icon';

interface FilterTriggerProps {
    active?: boolean
    activeCount?: number
    allowClear?: boolean
    handleClear?: () => void
    clickTrigger?: () => void
}

const FilterTrigger: React.FC<FilterTriggerProps> = ({
    active,
    activeCount = 0,
    allowClear,
    handleClear,
    clickTrigger,
}) => {
    let filterCountText;
    if ((active && activeCount > 0)) {
        filterCountText = <span className='filter-trigger-text'>{activeCount}</span>;
    }

    return (
        <div
            className={`filter-trigger${active ? ' filter-active' : ''}`}
            onClick={clickTrigger}
        >
            <Icon type='filter_'/>
            {filterCountText}
            {((activeCount || 0) > 0 && allowClear) && (
                <Icon
                    className='filter-trigger-clear-btn'
                    onClick={handleClear}
                    type='close_small'
                />
            )}
        </div>
    );
};
export default FilterTrigger;
