
import React, {useRef, useState, useEffect, useContext} from 'react';
import {Avatar, Button, message} from 'antd';

import {loadSimpleStaffs} from 'server/actions/staffs';
import {IAIClient} from 'server/client';
import {Staff} from 'types/staffs';
import LoadingScreen from 'components/loading_screen';
import OrganizationTree from 'components/organization_tree';
import StaffInfo from 'components/organization_components/staff_info';
import Icon from 'components/icon';
import {useClickOutsideRef} from 'utils/hooks';
import {concatImagePrefix} from 'utils/image_utils';
import {initEnterpriseAdmin} from 'server/actions/authorise';

import GlobalContext from 'context/global_context';

import AdminExists from './admin_exists';

const AdminSetting: React.FC = (): JSX.Element => {
    const currentUserId = IAIClient.getUserId();
    const {staffs} = useContext(GlobalContext);
    const selectionRef = useRef<HTMLDivElement>(null);
    const [selectionOpen, setSelectionOpen] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<Staff>();
    const [adminExists, setAdminExists] = useState<boolean>(false);
    useEffect(() => {
        loadSimpleStaffs([currentUserId]).then(({data, error}) => {
            if (error) {
                message.error('人员信息获取失败');
            } else if (data) {
                setCurrentUser(data[0]);
            }
        });
    }, []);

    const handleSelection = () => setSelectionOpen(!selectionOpen);

    useClickOutsideRef(selectionRef, () => {
        setSelectionOpen(false);
    });

    const onSelectMember = (item: {type: 'staff', id: string} | {type: 'department', id: number}, deleted: boolean) => {
        if (!deleted) {
            // OrganizationTree组件的单选情况下，返回的userIds在context中一定存在对应的Staff
            const selectedUserId = item.id;
            setCurrentUser(staffs[selectedUserId]);
            setSelectionOpen(false);
        }
    };

    const onConfirm = () => {
        if (currentUser) {
            initEnterpriseAdmin(currentUser.user_id).then(({data, error}) => {
                if (error) {
                    if (error.server_error_id === 'admin_already_exists') {
                        setAdminExists(true);
                    } else {
                        message.error('管理员设置失败');
                    }
                } else if (data?.status === 'OK') {
                    let applicationUrl: string | undefined;
                    const appType = IAIClient.getAppType();
                    if (appType === 'Performance') {
                        applicationUrl = '/perf/';
                    } else if (appType === 'Teamwork') {
                        applicationUrl = '/iai/';
                    }
                    if (applicationUrl) {
                        window.location.replace(applicationUrl);
                    }
                }
            });
        }
    };

    if (currentUser === undefined) {
        return <LoadingScreen/>;
    }

    if (adminExists) {
        return <AdminExists/>;
    }

    let options;
    if (selectionOpen) {
        options = (
            <div className='selection-options-container'>
                <div className='selection-options-content'>
                    <OrganizationTree
                        checkable={true}
                        itemIdsSelected={[currentUser.user_id]}
                        onItemSelected={onSelectMember}
                        singleChoice={true}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className='admin-setting-container'>
            <h1 className='admin-setting-title'>{'你所在公司还没有管理员，请选择一名成员作为管理员'}</h1>
            <p className='admin-setting-explain'>{'管理员有管理组织、管理角色、授权成员等权限'}</p>
            <div
                ref={selectionRef}
                className='admin-setting-selection'
                style={{width: 460}}
            >
                <div
                    className='admin_selection-value-container'
                    onClick={handleSelection}
                >
                    <div className='single-normal-user-container'>
                        <Avatar
                            src={concatImagePrefix(currentUser.avatar?.small)}
                            size={32}
                        />
                        <div className='single_user-right-container'>
                            <div className='single-normal-user-info'>
                                <div
                                    className='single-normal-user-name'
                                ><StaffInfo staff={currentUser}/></div>
                            </div>
                        </div>
                    </div>
                    <Icon
                        type='down'
                        className={`admin_selection-value-arrow ${selectionOpen ? 'open' : ''}`}
                    />
                </div>
                {options}
            </div>
            <Button
                className='admin-setting-operation-btn'
                type='primary'
                onClick={onConfirm}
            >{'确定'}</Button>
        </div>
    );
};

export default AdminSetting;
