import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import {Input, DatePicker, message, Avatar} from 'antd';
import moment from 'moment';

import Icon from 'components/icon';
import {AuthorityLog} from 'types/roles';
import MemberSelectInline from 'components/member_selects/member_select_inline';
import {loadAuthorityLogs} from 'server/actions/roles';
import {concatImagePrefix} from 'utils/image_utils';
import StaffInfo from 'components/organization_components/staff_info';
import GlobalContext from 'context/global_context';
import FilterTrigger from 'components/filter/filter_trigger';
import {useDebounce} from 'utils/hooks';

const {RangePicker} = DatePicker;

const LIMIT_SIZE = 50;
const LogsList: React.FC = () => {
    const {staffs, loadStaffsIfNeeded} = useContext(GlobalContext);
    const loading = useRef(false);
    const loadingTime = useRef(0);

    const [term, setTerm] = useState<string>();
    const debouncedValue = useDebounce<string | undefined>(term, 300);
    const [filterUserIds, setFilterUserIds] = useState<string[]>();
    const [dates, setDates] = useState<any>();
    const [filterExpansion, setFilterExpansion] = useState(false);

    const [list, setList] = useState<AuthorityLog[]>();
    const [hasMore, setHasMore] = useState(true);

    const scrollRef = useRef(null);
    const [scrollbarWidth, setScrollbarWidth] = React.useState(0);
    const onResize: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
        const target = entries[0].target as HTMLElement;
        const {offsetWidth, clientWidth} = target;
        const barWidth = offsetWidth - clientWidth;
        if (scrollbarWidth !== barWidth) {
            setScrollbarWidth(barWidth);
        }
    };
    const resizeObserver = new ResizeObserver(onResize);
    useEffect(() => {
        if (scrollRef.current) {
            resizeObserver.observe(scrollRef.current);
            const prevScroll = scrollRef.current;
            return () => {
                resizeObserver.unobserve(prevScroll);
            };
        }
    });

    useEffect(() => {
        loadMore(true);
    }, [debouncedValue, filterUserIds, dates]);

    const {filterCount, filterActive} = useMemo(() => {
        let filterCount = 0;
        let filterActive = false;
        if (filterUserIds) {
            filterActive = true;
            filterCount++;
        }
        if (dates) {
            filterActive = true;
            filterCount++;
        }
        return {
            filterActive,
            filterCount,
        };
    }, [filterUserIds, dates]);

    const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setTerm(value);
    };

    const onFilterMember = (userIds: string[]) => {
        if (userIds.length) {
            setFilterUserIds(userIds);
        } else {
            setFilterUserIds(undefined);
        }
    };

    const handleFilterClear = () => {
        setFilterUserIds(undefined);
        setDates(undefined);
    };

    const loadMore = (init = false) => {
        if (!init && (loading.current || !hasMore)) {
            return;
        }

        loading.current = true;
        const startTime = new Date().valueOf();
        loadingTime.current = startTime;

        let beforeId;
        if (!init && list) {
            beforeId = list[list.length - 1]?.id;
        } else {
            beforeId = 0;
        }
        let from;
        let to;
        if (dates) {
            from = dates[0].valueOf();
            to = dates[1].valueOf();
        }

        loadAuthorityLogs({
            before_id: beforeId,
            limit: LIMIT_SIZE,
            term: debouncedValue,
            creator_id: filterUserIds?.[0],
            from,
            to,
        }).then(({data, error}) => {
            // init = true 时会强行执行数据加载， loadingTime 作为最新一次请求的标识， 不相等时舍弃请求数据
            if (loadingTime.current === startTime) {
                if (error) {
                    message.error('日志加载失败');
                } else if (data) {
                    if (init || !list) {
                        setList(data);
                    } else {
                        setList((preList) => {
                            return preList?.concat(data);
                        });
                    }
                    if (data.length < LIMIT_SIZE) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }

                    // 拉取人员信息
                    const userIds = new Set<string>();
                    data.forEach((dItem) => {
                        userIds.add(dItem.creator_id);
                    });
                    loadStaffsIfNeeded([...userIds]);
                }
                loading.current = false;
            }
        });
    };

    const renderHeader = () => {
        return (
            <div className='role-management-logs-header'>
                <Input
                    style={{width: 200}}
                    placeholder='搜索操作内容'
                    allowClear={true}
                    prefix={
                        <Icon
                            type='search'
                            size={16}
                            color='#9E9EA7'
                        />
                    }
                    value={term}
                    onChange={onInputChange}
                />
                <FilterTrigger
                    active={filterExpansion || filterActive}
                    activeCount={filterCount}
                    allowClear={true}
                    handleClear={handleFilterClear}
                    clickTrigger={() => setFilterExpansion((e) => !e)}
                />
            </div>
        );
    };

    const renderFilters = () => {
        if (filterExpansion) {
            return (
                <div className='filter-content-container'>
                    <div className='filter-item-container'>
                        <div className='filter-item-label'>
                            {'操作人'}
                        </div>
                        <div className='filter-item-input'>
                            <MemberSelectInline
                                userIds={filterUserIds}
                                onSelected={onFilterMember}
                            />
                        </div>
                    </div>
                    <div className='filter-item-container'>
                        <div className='filter-item-label'>
                            {'时间范围'}
                        </div>
                        <div className='filter-item-input'>
                            <RangePicker
                                value={dates}
                                onChange={(values) => setDates(values)}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderLogItem = (logItem: AuthorityLog) => {
        const member = staffs[logItem.creator_id];
        return (
            <li
                key={logItem.id}
                className='members-list-row_layout'
            >
                <div className='row_layout-name'>
                    <div
                        className='single-normal-user-container'
                        style={{padding: 0, height: 'auto'}}
                    >
                        <Avatar
                            src={concatImagePrefix(member?.avatar?.small)}
                            size={24}
                        />
                        <div
                            className='single_user-right-container'
                            style={{marginLeft: 8}}
                        >
                            <div className='single-normal-user-info'>
                                <div
                                    className='single-normal-user-name'
                                ><StaffInfo staff={member}/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row_layout-180-width'>
                    {moment(logItem.create_at).format('YYYY年M月D日 HH:mm')}
                </div>
                <div
                    className='row_layout-main-content'
                    style={{lineHeight: 1.5}}
                >
                    {logItem.content}
                </div>
            </li>
        );
    };

    const renderList = () => {
        let listContent;
        if (list && list.length) {
            listContent = (
                <InfiniteScroller
                    initialLoad={false}
                    loadMore={() => loadMore()}
                    hasMore={hasMore}
                    useWindow={false}
                >
                    <ul>
                        {list.map((item) => {
                            return renderLogItem(item);
                        })}
                    </ul>
                </InfiniteScroller>
            );
        }
        return (
            <div className='members-management-list'>
                <div className='members-list-header-row members-list-row_layout'>
                    <span className='row_layout-name'>{'操作人'}</span>
                    <span className='row_layout-180-width'>{'操作时间'}</span>
                    <span className='row_layout-main-content'>{'操作内容'}</span>
                    <span
                        className='row_layout-cell-scrollbar'
                        style={{flex: `${scrollbarWidth}px 0 0`}}
                    />
                </div>
                <div
                    ref={scrollRef}
                    className='members-list-scroller_container'
                >
                    {listContent}
                </div>
            </div>
        );
    };

    return (
        <div className='role-management-logs-conatiner'>
            {renderHeader()}
            {renderFilters()}
            {renderList()}
        </div>
    );
};

export default LogsList;
