import React, {useCallback, useEffect, useState} from 'react';
import {DatePicker, Input, InputNumber, Select} from 'antd';

import {CUSTOM_FIELD_DATE, CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';
import Icon from 'components/icon';
import type {CustomFieldConfigType} from 'types/custom_field';
const {MonthPicker, YearPicker} = DatePicker;
type Props = {
    value?: any,
    defaultValue?: any,
    fieldConfig: CustomFieldConfigType,
    onChange?: (value: any) => void,
    onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    onAFocus?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void,
    onABlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void,
};

const style = {
    width: '100%',
};

const InputComponent: React.FC<Props> = ({
    value,
    defaultValue,
    fieldConfig,
    onChange,
    onFocus,
    onBlur,
    onAFocus,
    onABlur,
}) => {
    const [valueCache, setValueCache] = useState<any>(value || defaultValue);

    useEffect(() => {
        setValueCache(value);
    }, [value]);

    const onValueChange = useCallback((value: any) => {
        setValueCache(value);
        if (onChange) {
            onChange(value);
        }
    }, [onChange]);

    let min; let max;
    let minLength; let maxLength;
    fieldConfig.value_rules?.forEach((item) => {
        if (item.value) {
            if (item.type === 'min') {
                min = item.value;
            }
            if (item.type === 'max') {
                max = item.value;
            }
            if (item.type === 'minLength') {
                minLength = item.value;
            }
            if (item.type === 'maxLength') {
                maxLength = item.value;
            }
        }
    });

    switch (fieldConfig.form_type) {
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_NUMBER: {
            return (
                <InputNumber
                    style={style}
                    value={valueCache}
                    defaultValue={defaultValue}
                    addonAfter={fieldConfig.props?.unit}
                    onChange={onValueChange}
                    controls={false}
                    min={min}
                    max={max}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            );
        }
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT: {
            return (
                <Input
                    style={style}
                    value={valueCache}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        const value = e.target.value;
                        onValueChange(value);
                    }}
                    maxLength={maxLength}
                    minLength={minLength}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            );
        }
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_LONGTEXT: {
            return (
                <Input.TextArea
                    style={style}
                    value={valueCache}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        const value = e.target.value;
                        onValueChange(value);
                    }}
                    autoSize={true}
                    maxLength={maxLength}
                    minLength={minLength}
                    onFocus={onAFocus}
                    onBlur={onABlur}
                />
            );
        }
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT:
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT: {
            let mode: 'multiple' | undefined;
            if (fieldConfig.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT) {
                mode = 'multiple';
            }
            return (
                <Select
                    style={style}
                    value={valueCache}
                    defaultValue={defaultValue}
                    mode={mode}
                    onChange={onValueChange}
                >
                    {fieldConfig.props?.options?.map((item) => (
                        <Select.Option
                            key={item.value}
                            value={item.value}
                        >
                            {item.label}
                        </Select.Option>
                    ))}
                </Select>
            );
        }
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_FILES: {
            return (
                <Icon
                    type='upload'
                />
            );
        }
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_DATE: {
            const cProps = {
                style,
                value: valueCache,
                defaultValue,
                onChange: onValueChange,
            };
            switch (fieldConfig.props?.format) {
                case CUSTOM_FIELD_DATE.YEAR: {
                    return (
                        <YearPicker
                            {...cProps}
                        />
                    );
                }
                case CUSTOM_FIELD_DATE.MONTH: {
                    return (
                        <MonthPicker
                            {...cProps}
                        />
                    );
                }
                case CUSTOM_FIELD_DATE.DAY: {
                    return (
                        <DatePicker
                            {...cProps}
                        />
                    );
                }
                case CUSTOM_FIELD_DATE.MINUTE: {
                    return (
                        <DatePicker
                            {...cProps}
                            format={fieldConfig.props.format}
                            showTime={true}
                        />
                    );
                }

                default:
                    return null;
            }
        }
    }
    return value;
};

export default InputComponent;
