import React from 'react';

import Icon from 'components/icon';

export interface PlusTextProps {
    text?: string,
}

const PlusText: React.FC<PlusTextProps> = (props) => {
    const {text} = props;

    return (
        <div
            className={'plus-text'}
        >
            <div className={'plus'}><Icon type='plus'/></div>
            {
                text ? <div className={'content'}>{text}</div> : null
            }
        </div>
    );
};

export default PlusText;
