import {IAIClient} from 'server/client';
import {AuthorityParams, Role, RoleForm} from 'types/roles';
import {ServerError} from 'server/types/error';
import {Feature} from 'types/feature';
import {Dictionary, RoleUserRange, SimpleRoleUserRange} from 'types/role_user_range';

/**
 * 加载roleList
 */
export async function loadRoleList() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {};
    }

    let data: Role[];
    try {
        data = await IAIClient.loadRoleList(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 加载 修改日志
 */
export async function loadAuthorityLogs(params: AuthorityParams) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {};
    }

    let data;
    try {
        data = await IAIClient.loadAuthorityLogs(enterpriseId, params);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 新增 role
 */
export async function addRole(role: RoleForm) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {};
    }

    let data: Role;
    try {
        data = await IAIClient.addRole(enterpriseId, role);
    } catch (error) {
        return {error: error as ServerError};
    }

    return {data};
}

/**
 * 修改 role
 */
export async function modifyRole(id: number, role: RoleForm) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!id || !enterpriseId) {
        return {};
    }

    try {
        await IAIClient.modifyRole(enterpriseId, id, role);
    } catch (error) {
        return {error: error as ServerError};
    }

    return {data: true};
}

/**
 * 删除 role
 */
export async function removeRole(roleId: number) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {};
    }

    try {
        await IAIClient.removeRole(enterpriseId, roleId);
    } catch (error) {
        return {error};
    }

    return {data: true};
}

/**
 * 加载某role的所有员工，返回 userId 数组
 * @param roleId
 */
export async function loadRoleUsers(roleId: number) {
    if (!roleId) {
        return {data: undefined};
    }
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }

    let data: string[];
    try {
        data = await IAIClient.loadRoleUsers(enterpriseId, roleId);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 增加角色下成员
 * @param roleId
 * @param userIds
 */
export async function addRoleUsers(roleId: number, userIds: string[]) {
    const currentEnterpriseId = IAIClient.getEnterpriseId();
    if (!userIds || userIds.length === 0 || !currentEnterpriseId) {
        return {data: false};
    }

    try {
        await IAIClient.addRoleUsers(currentEnterpriseId, roleId, userIds);
    } catch (error) {
        return {error};
    }

    return {data: true};
}

/**
 * 删除角色下成员
 * @param roleId
 * @param userIds
 */
export async function removeRoleUsers(roleId: number, userIds: string[]) {
    const currentEnterpriseId = IAIClient.getEnterpriseId();
    if (!userIds || userIds.length === 0 || !currentEnterpriseId) {
        return {data: false};
    }

    try {
        await IAIClient.removeRoleUsers(currentEnterpriseId, roleId, userIds);
    } catch (error) {
        return {error};
    }

    return {data: true};
}

/**
 * 加载所有 Features
 */
export async function loadFeatureList() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }

    let data: Feature[];
    try {
        data = await IAIClient.loadFeatureList(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 加载某role的所有feature，返回 feature token 数组
 * @param roleId
 */
export async function loadRoleFeatureTokens(roleId: number) {
    if (!roleId) {
        return {data: undefined};
    }
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }

    let data: string[];
    try {
        data = await IAIClient.loadRoleFeatureTokens(enterpriseId, roleId);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 角色下 feature 增加授权
 * @param roleId
 * @param userIds
 */
export async function addRoleFeatures(roleId: number, featureIds: number[]) {
    const currentEnterpriseId = IAIClient.getEnterpriseId();
    if (!featureIds || featureIds.length === 0 || !currentEnterpriseId) {
        return {data: false};
    }

    try {
        await IAIClient.addRoleFeatures(currentEnterpriseId, roleId, featureIds);
    } catch (error) {
        return {error};
    }

    return {data: true};
}

/**
 * 角色下 feature 取消授权
 * @param roleId
 * @param featureIds
 */
export async function removeRoleFeatures(roleId: number, featureIds: number[]) {
    const currentEnterpriseId = IAIClient.getEnterpriseId();
    if (!featureIds || featureIds.length === 0 || !currentEnterpriseId) {
        return {data: false};
    }

    try {
        await IAIClient.removeRoleFeatures(currentEnterpriseId, roleId, featureIds);
    } catch (error) {
        return {error};
    }

    return {data: true};
}

/**
 * 加载某 role 所有 user 的 范围
 * @param roleId
 * @param userId
 */
export async function loadRoleUsersRanges(roleId: number) {
    if (!roleId) {
        return {data: undefined};
    }
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!roleId || !enterpriseId) {
        return {data: undefined};
    }

    let data: Dictionary<RoleUserRange[]>;
    try {
        data = await IAIClient.loadRoleUsersRanges(enterpriseId, roleId);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 加载某 role ,user 下 范围
 * @param roleId
 * @param userId
 */
export async function loadRoleUserRanges(roleId: number, userId: string) {
    if (!roleId) {
        return {data: undefined};
    }
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!roleId || !userId || !enterpriseId) {
        return {data: undefined};
    }

    let data: RoleUserRange[];
    try {
        data = await IAIClient.loadRoleUserRanges(enterpriseId, roleId, userId);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 添加某 role ,user 下 范围
 * @param roleId
 * @param userId
 * @param ranges
 */
export async function addRoleUserRanges(roleId: number, userId: string, ranges: SimpleRoleUserRange[]) {
    const currentEnterpriseId = IAIClient.getEnterpriseId();
    if (!roleId || !userId || !ranges || ranges.length === 0 || !currentEnterpriseId) {
        return {data: []};
    }

    let data: RoleUserRange[];
    try {
        data = await IAIClient.addRoleUserRanges(currentEnterpriseId, roleId, userId, ranges);
    } catch (error) {
        return {error};
    }

    return {data};
}

/**
 * 删除某 role ,user 下 范围
 * @param roleId
 * @param userId
 * @param ranges
 */
export async function removeRoleUserRanges(roleId: number, userId: string, ranges: SimpleRoleUserRange[]) {
    const currentEnterpriseId = IAIClient.getEnterpriseId();
    if (!roleId || !userId || !ranges || ranges.length === 0 || !currentEnterpriseId) {
        return {data: false};
    }

    try {
        await IAIClient.removeRoleUserRanges(currentEnterpriseId, roleId, userId, ranges);
    } catch (error) {
        return {error};
    }

    return {data: true};
}
