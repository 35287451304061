export const EnterpriseAll = 'All';

export const RoleNames = {
    Admin: 'Admin',
    Boss: 'Boss',
    SeniorAdmin: 'SeniorAdmin',
    PerformanceAdmin: 'PerformanceAdmin',
    PerformanceDataAdmin: 'PerformanceDataAdmin',
    Manager: 'Manager',
    Hrg: 'Hrg',
    Member: 'Member',
};

export const RangeTypes = {
    Staff: 'Staff',
    Department: 'Department',
};

export const OrganizationSyncStatus = {
    COMPLETED: 'Completed',
    SYNC: 'Sync',
    INIT: 'Init',
};

export const envEnum = {
    TEAMWORK: 'Teamwork',
    PERFORMANCE: 'Performance',
    PERFORMANCE_ROLE: 'PerformanceRole',
    ONLINE: 'Online',
};
