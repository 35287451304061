import React from 'react';

import WWOpenDataCom from 'components/ww_opendata';
import {isWeComEnvironment} from 'utils/utils';

interface DepartmentInfoProps {
    departmentName?: string,
}

const DepartmentInfo: React.FC<DepartmentInfoProps> = ({
    departmentName,
}) => {
    if (departmentName) {
        if (isWeComEnvironment()) {
            return (
                <WWOpenDataCom
                    type='departmentName'
                    openid={departmentName}
                />
            );
        }
        return <span>{departmentName}</span>;
    }
    return null;
};

export default DepartmentInfo;
