import {MutableRefObject, useEffect, useState} from 'react';

/**
 * Hook that alerts clicks outside of the passed ref.
 */
export function useClickOutsideRef(ref: MutableRefObject<HTMLElement | null>, handler: () => void, options?: boolean | EventListenerOptions): void {
    useEffect(() => {
        function onMouseDown(event: MouseEvent) {
            const target = event.target;
            if (ref.current && target instanceof Node && !ref.current.contains(target)) {
                handler();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', onMouseDown, options);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', onMouseDown, options);
        };
    }, [ref, handler, options]);
}

export function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay || 300);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return debouncedValue;
}
