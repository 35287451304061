import {register, initOpenData} from '@wecom/jssdk';

import {IAIClient} from 'server/client';
import {loadWeComJsConfig} from 'server/actions/users';
import {TicketType, UserType} from 'types/users';

export function setClientSingalByCookies() {
    if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('IAIENTERPRISEID=')) {
                IAIClient.setEnterpriseId(cookie.replace('IAIENTERPRISEID=', ''));
            } else if (cookie.startsWith('IAIUSERID=')) {
                IAIClient.setUserId(cookie.replace('IAIUSERID=', ''));
            } else if (cookie.startsWith('IAIISVAPPTYPE=')) {
                IAIClient.setAppType(cookie.replace('IAIISVAPPTYPE=', ''));
            } else if (cookie.startsWith('IAIOPENPLATFORMTYPE=')) {
                IAIClient.setOpenPlatformType(cookie.replace('IAIOPENPLATFORMTYPE=', ''));
            }
        }
    }
}

export function setUrlParams() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const backUrl = params.get('from');
    const redirectUrl = params.get('redirect_url');

    if (backUrl) {
        localStorage.setItem('back_url', backUrl);
    } else if (redirectUrl) {
        localStorage.setItem('back_url', redirectUrl);
    }
}

export function getUrlParams(searchKey: string) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    return params.get(searchKey);
}

export function listToMap<T, R>(list: T[], keyFunc: (item: T) => string, valueFunc: (item: T) => R) {
    const map = new Map();

    if (!list || !keyFunc || !valueFunc) {
        return map;
    }

    const listLength = list.length;

    for (let index = 0; index < listLength; index++) {
        const item = list[index];
        map.set(keyFunc(item), valueFunc(item));
    }

    return map;
}

export function mapToList<T, R>(map: Map<T, R>): R[] | undefined {
    if (!map || map.size === 0) {
        return undefined;
    }

    return [...map.values()];
}

// 验证输入手机号格式
export function isPhone(phone: string) {
    return (/^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4(?:(?:10|4[01])\d{3}|[68]\d{4}|[579]\d{2}))\d{6}$/).test(phone);
}

export function guid(): string {
    const timestamp = Date.now();
    return timestamp.toString(36);
}

export const addInOrder = (names: string[], name: string, divide = '') => {
    const nums = names.map((v) => {
        if (v && typeof v === 'string') {
            const arr = v.split(divide);
            return arr[arr.length - 1];
        }
        return null;
    });

    let i = 1;
    while (nums.includes(i.toString())) {
        i++;
    }

    return `${name}${divide}${i}`;
};

export function isDingtalkEnvironment(): boolean {
    return IAIClient.getOpenPlatformType() === 'DingTalk';
}

export function isWeComEnvironment(): boolean {
    return IAIClient.getOpenPlatformType() === 'Wework';
}

export function isLingYangEnvironment(): boolean {
    return IAIClient.getOpenPlatformType() === 'LingYang';
}

export function initializeWeCom(onSdkLoaded: () => void) {
    if (window.WWOpenData) {
        return;
    }

    const openScript = document.createElement('script');
    openScript.type = 'text/javascript';
    document.head.appendChild(openScript);
    openScript.src = 'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js';
    openScript.onload = onSdkLoaded;
}

export const registerOpenData = (userInfo: UserType, onSuccess: () => void, onComplete: () => void) => {
    if (userInfo.props?.corp_id && userInfo.props.agent_id) {
        // Todo 需要试验一下 “签名函数在页面URL发生变更后需要重新调用，对使用哈希路由的单页应用，签名函数只会被调用一次”

        const corpId = userInfo.props.corp_id;
        const agentId = userInfo.props.agent_id;

        const getConfigSignatureCreator = (key: TicketType) => {
            return async (url: string) => {
                const {data} = await loadWeComJsConfig(corpId, url, key);
                if (data) {
                    return {
                        timestamp: data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: data.nonce_str, // 必填，生成签名的随机串
                        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                    };
                }
                return {
                    timestamp: '',
                    nonceStr: '',
                    signature: '',
                };
            };
        };

        setTimeout(() => {
            // 包裹 setTimeout ，使得register在空闲（url稳定时间）执行
            register({
                corpId,
                agentId,
                jsApiList: ['config', 'agentConfig'],
                getConfigSignature: getConfigSignatureCreator('corp'),
                getAgentConfigSignature: getConfigSignatureCreator('agent'),
                onAgentConfigSuccess: () => {
                    onSuccess();
                    initOpenData({
                        fail: () => {
                            //
                        },
                    });
                },
                onAgentConfigComplete: () => {
                    onComplete();
                },
                onAgentConfigFail: (res) => {
                    console.log(res);
                },
            });
        }, 100);
    }
};

/**
 * URL base64解码
 * '-' -> '+'
 * '_' -> '/'
 * 字符串长度%4的余数，补'='
 */
export function urlsafeB64decode(string: string) {
    if (string) {
        let decodeUrl = string.replace(/-/g, '+').replace(/_/g, '/');
        const mod4 = decodeUrl.length % 4;
        if (mod4) {
            decodeUrl += '===='.substring(mod4);
        }
        return atob(decodeUrl);
    }
    return '';
}

/**
 * URL base64编码
 * '+' -> '-'
 * '/' -> '_'
 * '=' -> ''
 */
export function urlsafeB64encode(string: string) {
    const encodeUrl = btoa(string);
    encodeUrl.replace(/\+/g, '-').replace(/\//g, '_').replace(/[=]/g, '');
    return encodeUrl;
}
