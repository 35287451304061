import {IAIClient} from 'server/client';
import {SearchStaffsParams} from 'types/staffs';
export async function searchStaffs(params: SearchStaffsParams) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    let data;
    try {
        data = await IAIClient.searchStaffs(enterpriseId, params);
    } catch (error) {
        return {error};
    }

    return data;
}
