import React, {useMemo} from 'react';

import {ApplicationFeatures, ModuleFeatures} from 'types/feature';

import Module from './module';

export interface ApplicationProps {
    application: ApplicationFeatures,
    authorizedTokens: Map<string, boolean> | undefined,
    onAuthorizeTokens: (ids: number[], tokens: string[]) => void,
    onCancelTokens: (ids: number[], tokens: string[]) => void,
}

// [xg8io6xj6pdsxkt7ajzct54f5e]绩效 - 功能模板排序 - 前端写死
const MODULE_ORDER: Record<string, number> = {
    Definition: 0,
    Plan: 1,
    Team: 2,
    Result: 3,
    ProcessImprovement: 4,
    Analysis: 5,
    Ranking: 6,
    Record: 7,
    Template: 8
};

const Application: React.FC<ApplicationProps> = (props) => {
    const {application, authorizedTokens, onAuthorizeTokens, onCancelTokens} = props;

    const renderList = useMemo<ModuleFeatures[]>(() => {
        const list = [...application.modules];
        list.sort((a, b) => {
            return MODULE_ORDER[a.module] - MODULE_ORDER[b.module];
        });
        return list;
    }, [application.modules]);

    return (
        <>
            {
                renderList.map((module) => {
                    return (
                        <Module
                            key={module.module}
                            module={module}
                            authorizedTokens={authorizedTokens}
                            onAuthorizeTokens={onAuthorizeTokens}
                            onCancelTokens={onCancelTokens}
                        />
                    );
                })
            }
        </>
    );
};

export default Application;
