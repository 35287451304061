import React from 'react';
import PropTypes from 'prop-types';
import {LoadingOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';

import {searchStaffs} from 'server/actions/search';
import {highLightTerm} from 'utils/high_light';
import {getFullname} from 'utils/staff_utils';
import {concatImagePrefix} from 'utils/image_utils';
import {isWeComEnvironment} from 'utils/utils';

import Icon from 'components/icon';
import StaffInfo from 'components/organization_components/staff_info';
import DepartmentInfo from 'components/organization_components/department_info';
import {DEPARTMENT_KEY, STAFF_KEY} from 'components/organization_tree/tree_utils';

export default class Search extends React.PureComponent {
    static propTypes = {
        departmentSelectable: PropTypes.bool.isRequired,
        departmentFullPath: PropTypes.object,
        term: PropTypes.string.isRequired,
        selectedItemIds: PropTypes.array.isRequired,
        itemIdsDisabledSet: PropTypes.object.isRequired,
        onChooseItem: PropTypes.func.isRequired,
        onDeleteItem: PropTypes.func.isRequired,
        onCloseSearch: PropTypes.func,
        excludeNoIsv: PropTypes.bool,
    };

    static defaultProps = {
        selectedItemIds: [],
        itemIdsDisabledSet: new Set(),
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedItemIds: [],
            selectedItemIdsSet: new Set(),

            staffList: [],
            loadingStaff: false,
            departmentList: [],
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {selectedItemIds} = props;
        if (selectedItemIds !== state.selectedItemIds) {
            return {
                selectedItemIds,
                selectedItemIdsSet: new Set(selectedItemIds),
            };
        }

        return null;
    }

    componentDidMount() {
        this.searchStaffs();
        this.searchDepartments();
    }

    componentDidUpdate(prevProps) {
        const {term} = this.props;
        if (prevProps.term !== term) {
            this.searchStaffs();
            this.searchDepartments();
        }
    }

    searchDepartments = async () => {
        const {term, departmentFullPath, departmentSelectable} = this.props;
        if (!term || !departmentSelectable || !departmentFullPath) {
            return;
        }

        if (isWeComEnvironment()) {
            // 20230228 部门搜索原为纯前端搜索，微信环境下部门名称拿不到，暂不支持
            return;
        }

        const termSet = new Set(term.split(''));
        const departmentList = [];
        for (const [key, value] of Object.entries(departmentFullPath)) {
            if (value.data?.name?.includes(term, 0)) {
                departmentList.push({
                    id: key,
                    label: highLightTerm(value.data.name, termSet),
                });
            }
        }

        if (departmentList.length) {
            this.setState({
                departmentList,
            });
        }
    };

    searchStaffs = async () => {
        const {term, excludeNoIsv} = this.props;

        if (!term) {
            this.setState({
                staffList: [],
                loadingStaff: false,
            });
            return;
        }

        await this.setState({
            loadingStaff: true,
        });

        const params = {
            term,
            start: 0,
            count: 10,
        };
        const termSet = new Set(term.split(''));
        searchStaffs({...params, with_department: true, exclude_no_isv_auth: excludeNoIsv}).then((data) => {
            const {error} = data;
            if (error) {
                this.setState({
                    loadingStaff: false,
                });
                return;
            }
            for (const index in data) {
                if (isWeComEnvironment()) {
                    data[index].label = <StaffInfo staff={data[index]}/>;
                } else {
                    data[index].label = highLightTerm(getFullname(data[index]), termSet);
                }
            }
            this.setState({
                staffList: data,
                loadingStaff: false,
            });
        });
    };

    handleClickItem = (e, item, deleted) => {
        const {onCloseSearch} = this.props;
        if (onCloseSearch) {
            onCloseSearch();
        }

        e.preventDefault();
        e.stopPropagation();

        if (deleted) {
            this.props.onDeleteItem(item);
        } else {
            this.props.onChooseItem(item);
        }
    };

    getCurrentSelectClassName = (selected, disabled) => {
        if (selected || disabled) {
            if (disabled) {
                return 'disable-icon';
            }

            return 'show-icon';
        }

        return 'hidden-icon';
    };

    renderContent = () => {
        const {selectedItemIdsSet, staffList, departmentList} = this.state;
        const {itemIdsDisabledSet} = this.props;

        const content = [];

        if (staffList.length) {
            for (const staff of staffList) {
                const selected = selectedItemIdsSet.has(staff.user_id);
                const disabled = itemIdsDisabledSet.has(staff.user_id);
                const currentSelectIcon = this.getCurrentSelectClassName(selected, disabled);
                let onClick;
                if (!disabled) {
                    onClick = (e) => this.handleClickItem(e, {
                        type: STAFF_KEY,
                        id: staff.user_id,
                    }, selected);
                }

                content.push(
                    <li
                        className={`search-result_item single-normal-user-container ${disabled ? 'disabled' : ''}`}
                        key={staff.user_id}
                        onClick={onClick}
                    >
                        {
                            staff.avatar ? <Avatar src={concatImagePrefix(staff.avatar.small)}/> : <Avatar/>
                        }
                        <div className='single_user-right-container'>
                            <div className='single-normal-user-info'>
                                <div className='single-normal-user-name'>{staff.label}</div>
                                <div className='single-normal-user-extra'>
                                    {staff.department && <DepartmentInfo departmentName={staff.department.name}/>}
                                </div>
                            </div>
                            <Icon
                                type='duigou'
                                className={`select-icon ${currentSelectIcon}`}
                            />
                        </div>
                    </li>,
                );
            }
        }

        if (departmentList.length) {
            for (const department of departmentList) {
                const selected = selectedItemIdsSet.has(department.id);
                const disabled = itemIdsDisabledSet.has(department.id);
                const currentSelectIcon = this.getCurrentSelectClassName(selected, disabled);

                let onClick;
                if (!disabled) {
                    onClick = (e) => this.handleClickItem(e, {
                        type: DEPARTMENT_KEY,
                        id: department.id,
                    }, selected);
                }

                content.push(
                    <li
                        className={`search-result_item single-normal-user-container ${disabled ? 'disabled' : ''}`}
                        key={department.id}
                        onClick={onClick}
                    >
                        <Avatar
                            style={{backgroundColor: '#2DCCCA'}}
                            icon={
                                <Icon
                                    type={'department'}
                                />
                            }
                        />
                        <div className='single_user-right-container'>
                            <div className='single-normal-user-info'>
                                <div className='single-normal-user-name'>{department.label}</div>
                            </div>
                            <Icon
                                type='duigou'
                                className={`select-icon ${currentSelectIcon}`}
                            />
                        </div>
                    </li>,
                );
            }
        }

        return (
            <ul>{content}</ul>
        );
    };

    renderLoading = () => {
        return (
            <div className='search-loading-container'>
                <LoadingOutlined/>
            </div>
        );
    };

    render() {
        const {term} = this.props;
        const {staffList, loadingStaff, departmentList} = this.state;

        if (term && staffList.length === 0 && departmentList.length === 0 && !loadingStaff) {
            return (
                <div>{'暂无搜索结果'}</div>
            );
        }

        let content = null;
        if (loadingStaff) {
            content = this.renderLoading();
        } else {
            content = this.renderContent();
        }

        return (
            <div className='search-multiple_block'>
                {content}
            </div>
        );
    }
}
