import {IAIClient} from 'server/client';
export async function checkDingtalkOrgauthscope() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    let data;
    try {
        data = await IAIClient.checkDingtalkOrgauthscope(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadDingtalkCustomerServiceConfig() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    let data;
    try {
        data = await IAIClient.loadDingtalkCustomerServiceConfig(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadDingtalkEmployeeInfo(userId: string) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId || !userId) {
        return {data: undefined};
    }
    let data;
    try {
        data = await IAIClient.loadDingtalkEmployeeInfo(enterpriseId, userId);
    } catch (error) {
        return {error};
    }

    return {data};
}
