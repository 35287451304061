import React, {useState} from 'react';
import {Button, Popover} from 'antd';

import Icon from 'components/icon';
import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';

type ItemProps = {
    type?: CUSTOM_FIELD_TYPES,
};

const COMMON_TYPES: Record<CUSTOM_FIELD_TYPES, {icon: string, label: string}> = {
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_NUMBER]: {icon: 'shuzi', label: '数字'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_TEXT]: {icon: 'wenben', label: '文本'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_LONGTEXT]: {icon: 'changwenben', label: '长文本'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_SELECT]: {icon: 'xiala', label: '单选下拉'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_MULTIPLE_SELECT]: {icon: 'fuxuanxiala', label: '多选下拉'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_FILES]: {icon: 'wenjian', label: '文件'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_DATE]: {icon: 'shijian', label: '时间'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF]: {icon: 'renyuan', label: '人员'},
    [CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STATUS]: {icon: 'xiala', label: '单选下拉'},
};

export const FieldTypeItem: React.FC<ItemProps> = ({
    type,
}) => {
    if (type) {
        const data = COMMON_TYPES[type];
        if (data) {
            return (
                <div className='dropdown-option-content'>
                    <Icon
                        className='dropdown-option-icon'
                        type={data.icon}
                    />
                    <span className='dropdown-option-label '>{data.label}</span>
                </div>
            );
        }
    }
    return null;
};

type Props = {
    typeOptions?: CUSTOM_FIELD_TYPES[]
    value?: CUSTOM_FIELD_TYPES
    onChange?: (value: CUSTOM_FIELD_TYPES) => void
    triggerStyle?: React.CSSProperties
};
const FieldTypeSelect: React.FC<Props> = ({
    typeOptions = [],
    value,
    onChange,
    triggerStyle,
}) => {
    const [selectVisible, setSelectVisible] = useState(false);

    const renderTypeOption = (type: CUSTOM_FIELD_TYPES) => {
        let activeIcon;
        if (type === value) {
            activeIcon = (
                <Icon
                    type='duigou1'
                    style={{flex: 'none'}}
                    color='var(--primary-color)'
                />
            );
        }
        return (
            <div
                key={type}
                className='dropdown-option-item'
                style={{width: '138px'}}
                onClick={() => {
                    onChange?.(type);
                    setSelectVisible(false);
                }}
            >
                <FieldTypeItem type={type}/>
                {activeIcon}
            </div>
        );
    };

    let valuePreView;
    if (value) {
        valuePreView = <FieldTypeItem type={value}/>;
    }

    return (
        <Popover
            overlayClassName='no-arrow-popover compact-popover'
            content={
                <div
                    className='popup-inner'
                    onClick={(e) => e.stopPropagation()}
                    // 阻止PropertyAddItem的 useClickOutsideRef 相关事件触发
                    onMouseDown={e => e.stopPropagation()}
                >
                    <div className='custom-field_type-list'>
                        {typeOptions.map((item) => renderTypeOption(item))}
                    </div>
                </div>
            }
            trigger='click'
            open={selectVisible}
            onOpenChange={(visible) => setSelectVisible(visible)}
            placement='bottomLeft'
        >
            <Button
                type='text'
                size='small'
                className='add-property-type-btn'
                style={triggerStyle}
                onClick={(e) => e.stopPropagation()}
            >
                {valuePreView}
                <Icon type='down'/>
            </Button>
        </Popover>

    );
};

export default FieldTypeSelect;
