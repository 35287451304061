import React, {useContext, useState} from 'react';
import {Button, Form, Input, Modal} from 'antd';

import {IconWithHighlight} from 'components/icon';
import GlobalContext from 'context/global_context';
import {Role} from 'types/roles';

export interface EditRoleModalProps {
    id?: number,
    visible: boolean,
    onClose: () => void,
}

const FIELD_NAME = 'alias_name';
const FIELD_DESCRIPTION = 'description';

const EditRoleForm: React.FC<Omit<EditRoleModalProps, 'visible'>> = ({
    id,
    onClose,
}) => {
    const [formField] = Form.useForm();
    const {enterpriseRoles, addEnterpriseRole, modifyEnterpriseRole} = useContext(GlobalContext);
    const [confirming, setConfirming] = useState(false);

    let role: undefined | Role;
    if (id !== undefined) {
        role = enterpriseRoles?.rolesMap.get(id);
    }

    const validateName = (rule: any, value: string) => {
        if (value) {
            if (enterpriseRoles?.rolesMap) {
                for (const item of enterpriseRoles.rolesMap.values()) {
                    if (id !== item.id && value === item.alias_name) {
                        return Promise.reject('字段名称不能重复');
                    }
                }
            }
        }
        return Promise.resolve();
    };

    const onConfirm = (values: any) => {
        let roleName: string = values[FIELD_NAME];
        const description: string | undefined = values[FIELD_DESCRIPTION];
        if (roleName) {
            roleName = roleName.trim();
            formField.setFields([{name: FIELD_NAME, value: roleName}]);
            if (roleName.length === 0) {
                formField.validateFields();
                return;
            }
        }

        setConfirming(true);
        if (id) {
            modifyEnterpriseRole(id, {
                alias_name: roleName,
                description,
            }, onClose, () => setConfirming(false));
        } else {
            addEnterpriseRole({
                alias_name: roleName,
                description,
            }, onClose, () => setConfirming(false));
        }
    };

    return (
        <div className='role-edit-form'>
            <Form
                layout='vertical'
                requiredMark={false}
                form={formField}
                onFinish={onConfirm}
            >
                <Form.Item
                    label='角色名称'
                    name={FIELD_NAME}
                    initialValue={role?.alias_name}
                    rules={[
                        {required: true, message: '请输入角色名称'},
                        {max: 32, message: '角色的名称不超过32个字'},
                        {validator: validateName},
                    ]}
                >
                    <Input
                        maxLength={32}
                        placeholder={'请输入角色名称'}
                    />
                </Form.Item>
                <Form.Item
                    label='角色描述'
                    name={FIELD_DESCRIPTION}
                    initialValue={role?.description}
                    rules={[
                        {max: 128, message: '角色的名称不超过128个字'},
                    ]}
                >
                    <Input.TextArea
                        maxLength={128}
                        autoSize={{minRows: 1}}
                        placeholder={'请输入角色描述'}
                    />
                </Form.Item>
                <div className={'buttons'}>
                    <Button
                        onClick={() => onClose()}
                    >
                        {'取消'}
                    </Button>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={confirming}
                    >
                        {'确定'}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const EditRoleModal: React.FC<EditRoleModalProps> = ({
    id,
    visible,
    onClose,
}) => {
    return (
        <Modal
            title={`${id === undefined ? '添加' : '编辑'}角色`}
            open={visible}
            onCancel={() => onClose()}
            width={550}
            footer={null}
            closeIcon={
                <IconWithHighlight
                    type='close_normal'
                    size={16}
                    color='#333333'
                />
            }
            destroyOnClose={true}
        >
            <EditRoleForm
                id={id}
                onClose={onClose}
            />
        </Modal>
    );
};

export default EditRoleModal;
