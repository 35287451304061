import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';

import GlobalContext from 'context/global_context';
import StaffInfo from 'components/organization_components/staff_info';
import OrganizationTree from 'components/organization_tree';
import {Staff} from 'types/staffs';
import Icon from 'components/icon';
import {useClickOutsideRef} from 'utils/hooks';

export interface MemberSelectProps {
    userIds?: string[],
    onSelected: (userIds: string[]) => void,
}

const MemberSelectInline: React.FC<MemberSelectProps> = ({
    userIds,
    onSelected,
}) => {
    const {staffs, loadStaffsIfNeeded} = useContext(GlobalContext);
    const selectionRef = useRef<HTMLDivElement>(null);
    const [selectionOpen, setSelectionOpen] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<string[]| undefined>(userIds);

    useEffect(() => {
        if (userIds) {
            loadStaffsIfNeeded(userIds);
        }
        if (userIds !== selectedIds) {
            setSelectedIds(userIds);
        }
    }, [userIds]);

    const selectedStaff = useMemo<Staff | undefined>(() => {
        if (selectedIds) {
            return staffs[selectedIds[0]];
        }
    }, [selectedIds, staffs]);

    const handleSelection = () => setSelectionOpen(!selectionOpen);

    useClickOutsideRef(selectionRef, () => {
        setSelectionOpen(false);
    });

    const onSelectMember = (item: {type: 'staff', id: string} | {type: 'department', id: number}, deleted: boolean) => {
        if (!deleted) {
            // OrganizationTree组件的单选情况下，返回的userIds在context中一定存在对应的Staff
            const selectedUserId = item.id;
            if (typeof selectedUserId === 'string') {
                const selectedValue = [selectedUserId];
                setSelectedIds(selectedValue);
                onSelected(selectedValue);
                setSelectionOpen(false);
            }
        } else {
            const selectedValue: string[] = [];
            setSelectedIds(selectedValue);
            onSelected(selectedValue);
        }
    };

    let options;
    if (selectionOpen) {
        options = (
            <div
                className='selection-options-container'
                style={{width: 460}}
            >
                <div className='selection-options-content'>
                    <OrganizationTree
                        checkable={true}
                        itemIdsSelected={selectedIds}
                        onItemSelected={onSelectMember}
                        singleChoice={true}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            ref={selectionRef}
            className='admin-setting-selection'
            style={{width: 200}}
        >
            <div
                className='admin_selection-value-container'
                style={{height: 32}}
                onClick={handleSelection}
            >
                <div className='single-normal-user-container'>
                    <div className='single_user-right-container'>
                        <div className='single-normal-user-info'>
                            <div
                                className='single-normal-user-name'
                            ><StaffInfo staff={selectedStaff}/></div>
                        </div>
                    </div>
                </div>
                <Icon
                    type='down'
                    className={`admin_selection-value-arrow ${selectionOpen ? 'open' : ''}`}
                />
            </div>
            {options}
        </div>
    );
};

export default MemberSelectInline;
