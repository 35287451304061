import React, {useRef, useLayoutEffect} from 'react';

const WWOpenDataCom: React.FC<WWOpenDataProps> = ({type, openid}) => {
    const ref = useRef(null);
    useLayoutEffect(() => {
        if (ref.current) {
            window.WWOpenData?.bind(ref.current);
        }
    });
    return (<ww-open-data
        ref={ref}
        type={type}
        openid={openid}
    />);
};

export default WWOpenDataCom;
