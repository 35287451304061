import React, {ReactNode, useEffect, useRef} from 'react';
import {Switch, Popconfirm} from 'antd';
import InfiniteScroller from 'react-infinite-scroller';
import ResizeObserver from 'resize-observer-polyfill';

import Icon from 'components/icon';
import StaffInfo from 'components/organization_components/staff_info';
import DepartmentInfo from 'components/organization_components/department_info';
import {AuthorizationType, StaffAuthorization} from 'types/authorise';
import {DepartmentWithMembers, StaffToDepartment} from 'types/departments';
import {IAIClient} from 'server/client';
import StaffDepartmentMainPopover from "components/organization_management/components/StaffDepartmentMainPopover";

export interface MembersManagementListProps {
    authorizationType: AuthorizationType,
    loadMore: () => void,
    handleAuthorizationChange: (members: StaffAuthorization[], authorised: boolean) => void,
    handleMainDepartmentChange: (userId: string) => void,
    hasMoreChannels: boolean,
    members?: StaffAuthorization[],
    departments?: {[deparmtentId: string]: DepartmentWithMembers},
    staffToDepartmentsMap?: StaffToDepartment,
}

const MembersManagementList: React.FC<MembersManagementListProps> = (props): JSX.Element | null => {
    // UserId不一定能更新
    const currentUserId = IAIClient.getUserId();
    const [scrollbarWidth, setScrollbarWidth] = React.useState(0);

    const onResize: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
        const target = entries[0].target as HTMLElement;
        const {offsetWidth, clientWidth} = target;
        const barWidth = offsetWidth - clientWidth;
        if (scrollbarWidth !== barWidth) {
            setScrollbarWidth(barWidth);
        }
    };

    const resizeObserver = new ResizeObserver(onResize);
    const scrollRef = useRef(null);
    useEffect(() => {
        if (scrollRef.current) {
            resizeObserver.observe(scrollRef.current);
            const prevScroll = scrollRef.current;
            return () => {
                resizeObserver.unobserve(prevScroll);
            };
        }
    });

    const handleAuthorizationChange = (item: StaffAuthorization) => {
        props.handleAuthorizationChange([item], !item.authorised);
    };

    const renderRow = (member: StaffAuthorization) => {
        const {departments, staffToDepartmentsMap} = props;
        const departmentList: ReactNode[] = [];
        if (departments && staffToDepartmentsMap) {
            const currentDepartmentMap = staffToDepartmentsMap[member.user_id];
            currentDepartmentMap?.department_ids?.forEach((id) => {
                if (id === currentDepartmentMap.main_department_id) {
                    departmentList.unshift(
                        <span
                            key={id}
                            className='row_layout-department-item'
                        >
                            <DepartmentInfo departmentName={departments[id]?.name}/>
                            <Icon type='main-unit'/>
                        </span>,
                    );
                } else {
                    departmentList.push(
                        <StaffDepartmentMainPopover
                            key={id}
                            departmentId={id}
                            userId={member.user_id}
                            successCallback={() => props.handleMainDepartmentChange(member.user_id)}
                        >
                            <span
                                className='row_layout-department-item'
                                style={{cursor: 'pointer'}}
                            >
                                <DepartmentInfo departmentName={departments[id]?.name}/>
                            </span>
                        </StaffDepartmentMainPopover>
                    );
                }
            });
        }

        let switchBtn;
        if (member.user_id === currentUserId && member.authorised === true) {
            switchBtn = (
                <Switch
                    checkedChildren='开'
                    unCheckedChildren='关'
                    checked={member.authorised}
                    disabled={true}
                />
            );
        } else {
            switchBtn = (
                <Popconfirm
                    title={
                        <>
                            <span
                                style={{
                                    color: '#3D3D4E',
                                    fontWeight: 'bold',
                                }}
                            >{`确定${member.authorised ? '关闭' : '开启'}该员工`}</span><br/>
                            <span style={{color: '#6E6D7A'}}>
                                {member.authorised ? '关闭后，员工将无法登录使用智办事。' : '开启后，员工将可登录使用智办事。'}
                            </span>
                        </>
                    }
                    placement='topRight'
                    overlayStyle={{width: 264}}
                    okText={'确定'}
                    cancelText={'取消'}
                    onConfirm={() => handleAuthorizationChange(member)}
                >
                    <Switch
                        checkedChildren='开'
                        unCheckedChildren='关'
                        checked={member.authorised}
                    />
                </Popconfirm>
            );
        }
        return (
            <li
                key={member.user_id}
                className='members-list-row_layout'
            >
                <div className='row_layout-name'>
                    <StaffInfo
                        nameOnly={true}
                        staff={member}
                    />
                </div>
                <div className='row_layout-department'>
                    {departmentList}
                </div>
                <div className='row_layout-authorise'>
                    {switchBtn}
                </div>
            </li>
        );
    };

    const renderList = () => {
        const {members} = props;
        if (members && members.length) {
            return (
                <InfiniteScroller
                    initialLoad={false}
                    loadMore={() => props.loadMore()}
                    hasMore={props.hasMoreChannels}
                    useWindow={false}
                >
                    <ul>
                        {
                            members.map((member) => {
                                if (member) {
                                    return renderRow(member);
                                }
                                return null;
                            })
                        }
                    </ul>
                </InfiniteScroller>
            );
        }
    };

    return (
        <div className='members-management-list'>
            <div className='members-list-header-row members-list-row_layout'>
                <span className='row_layout-name'>{'名称'}</span>
                <span className='row_layout-department'>{'部门'}</span>
                <span className='row_layout-authorise'>{'智办事权限'}</span>
                <span
                    className='row_layout-cell-scrollbar'
                    style={{flex: `${scrollbarWidth}px 0 0`}}
                />
            </div>
            <div
                ref={scrollRef}
                className='members-list-scroller_container'
            >
                {renderList()}
            </div>
        </div>
    );
};

export default MembersManagementList;
