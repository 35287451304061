import React, {useState, useContext} from 'react';
import {Popover} from 'antd';

import GlobalContext from 'context/global_context';
import Icon from 'components/icon';
import {Role} from 'types/roles';
import {EnterpriseAll} from 'utils/constants/general';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
export interface RoleItemProps {
    role: Role
    handleEditRole: (roleId: number) => void
}

const RoleItem: React.FC<RoleItemProps> = (props) => {
    const {removeEnterpriseRole} = useContext(GlobalContext);
    const {role, handleEditRole} = props;
    const [operationVisible, setOperationVisible] = useState(false);

    const handleDeleteRole = (roleId: number, roleName: string) => {
        setOperationVisible(false);
        EventEmitter.emit(EventTypeEnum.DELETE_CONFIRM, {
            title: roleName,
            placeholder: roleName,
            successCallback: () => {
                return new Promise((resolve, reject) => {
                    removeEnterpriseRole(roleId, resolve, reject);
                });
            },
        });
    };

    let operations;
    if (role.enterprise_id !== EnterpriseAll) {
        const operationItemStyle: React.CSSProperties = {
            lineHeight: '32px',
            height: 32,
            width: 120,
            padding: '0 12px',
        };
        const iconSize = 16;
        operations = (
            <Popover
                overlayClassName='no-arrow-popover compact-popover'
                content={
                    <div onClick={(e) => e.stopPropagation()}>
                        <div
                            className='dropdown-option-item'
                            style={operationItemStyle}
                            onClick={() => {
                                handleEditRole(role.id);
                                setOperationVisible(false);
                            }}
                        >
                            <div className='dropdown-option-content'>
                                <Icon
                                    type='edit'
                                    size={iconSize}
                                />
                                <span className='dropdown-option-label'>编辑修改</span>
                            </div>
                        </div>
                        <div
                            className='dropdown-option-item'
                            style={{
                                ...operationItemStyle,
                                color: 'var(--danger-color)',
                            }}
                            onClick={() => handleDeleteRole(role.id, role.name)}
                        >
                            <div className='dropdown-option-content'>
                                <Icon
                                    type='delete'
                                    size={iconSize}
                                />
                                <span className='dropdown-option-label'>删除角色</span>
                            </div>
                        </div>
                    </div>
                }
                trigger='click'
                open={operationVisible}
                onOpenChange={(visible) => setOperationVisible(visible)}
                placement='bottomLeft'
            >
                <Icon
                    type='gengduo1'
                    size={16}
                    style={{marginLeft: '4px'}}
                />
            </Popover>
        );
    }

    return (
        <React.Fragment>
            <span className='second-menu-label'>{role.alias_name}</span>
            {operations}
        </React.Fragment>
    );
};

export default RoleItem;
