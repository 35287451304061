import React, {useContext, useEffect, useState} from 'react';

import {RoleSettingProps} from 'types/roles';
import {EnterpriseAll, RoleNames} from 'utils/constants/general';
import GlobalContext from 'context/global_context';

import MemberList from '../member/member_list';
import Switcher, {SwitcherTab} from '../settting/switcher';
import MemberGoTo from '../member/member_go_to';
import MemberTable from '../member/member_table';
import MemberSystemTable from '../member/member_system_table';
import Authorization from '../authorization/authorization';

const activeKeyMember = 'member';
const tabs: SwitcherTab[] = [
    {key: activeKeyMember, label: '成员管理'},
    {key: 'feature', label: '权限管理'},
];

const memberComponents: Map<string, React.FC<RoleSettingProps>> = new Map([
    [RoleNames.Admin, MemberList],
    [RoleNames.Manager, MemberSystemTable],
    [RoleNames.Hrg, MemberSystemTable],
    [RoleNames.Member, MemberGoTo],
]);

function getMemberComponent(enterpriseId: string, name: string): React.FC<RoleSettingProps> {
    if (enterpriseId !== EnterpriseAll) {
        return MemberTable;
    }

    let component = memberComponents.get(name);
    if (!component) {
        component = MemberTable;
    }

    return component;
}

const RoleSetting: React.FC<RoleSettingProps> = (props) => {
    const [activeKey, setActiveKey] = useState<string>(activeKeyMember);

    const {role, memberIds, onMemberSelected, onMemberRemove} = props;
    const {enterpriseFeatures, loadEnterpriseFeatures} = useContext(GlobalContext);

    // features 在这里加载
    useEffect(() => {
        if (!enterpriseFeatures) {
            loadEnterpriseFeatures();
        }
    }, [activeKey]);

    let content;
    if (activeKey === activeKeyMember) {
        const MemberComponent = getMemberComponent(role.enterprise_id, role.name);
        content = (
            <MemberComponent
                role={role}
                memberIds={memberIds}
                onMemberRemove={onMemberRemove}
                onMemberSelected={onMemberSelected}
            />
        );
    } else {
        content = (
            <Authorization
                roleId={role.id}
            />
        );
    }

    return (
        <div className={'backend-role-setting'}>
            <Switcher
                tabs={tabs}
                activeKey={activeKey}
                onChange={(key) => setActiveKey(key)}
            />
            {content}
        </div>
    );
};

export default RoleSetting;
