import React from 'react';
import {Form, Select} from 'antd';

import {
    CUSTOM_FIELD_DATE,
    CUSTOM_FIELD_DATE_OPTIONS,
} from 'utils/constants/custom_field';

import type {NormalRuleProps} from '../utils';

const DateRule: React.FC<NormalRuleProps> = ({
    fieldConfig,
    onModify,
    disabled,
}) => {
    let content;
    if (disabled) {
        content = (
            <span className='i-field-f-item-preview'>
                {CUSTOM_FIELD_DATE_OPTIONS.find((i) => i.value === fieldConfig.props?.format)?.label}
            </span>
        );
    } else {
        content = (
            <Select
                style={{width: '100%'}}
                options={CUSTOM_FIELD_DATE_OPTIONS}
                value={fieldConfig.props?.format}
                onChange={(value: CUSTOM_FIELD_DATE) => {
                    onModify({
                        ...fieldConfig,
                        props: {
                            ...fieldConfig.props,
                            format: value,
                        },
                    });
                }}
            />
        );
    }
    return (
        <Form.Item label='时间类型'>
            {content}
        </Form.Item>
    );
};

export default DateRule;
