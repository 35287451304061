export type IsvType = 'Teamwork' | 'Performance' | 'Salary';
export enum IsvTypeAppName {
    'Teamwork' = '互成协作',
    'Performance' = '智办事绩效',
    'Salary' = '薪酬系统',
}
export type TicketType = 'corp' | 'agent';
export type DingtalkSSOParams = {
    corp_id: string;
    auth_code: string;
    isv_app_type: IsvType;
};

export type WecomSSOParams = {
    corp_id: string;
    user_id: string;
    isv_app_type: IsvType;
};

export type KaixueBasicInfoParams = {
    code: string;
    redirect_url: string;
};

export type KaixueSSOParams = {
    user_id: string;
    enterprise_id: string;
    isv_app_type: IsvType;
};

export type TripartiteConfigParams = {
    corp_id: string;
    url: string;
    isv_app_type: IsvType;
};

export type WeWorkConfigParams = {
    corp_id: string;
    current_url: string;
    ticket_type: TicketType;
};

export type OSyncStatus = {
    id?: number
    status: 'Completed' | 'Sync' | 'Init'
};

export type UserPropsType = {
    corp_id?: string
    agent_id?: string
};

export type UserType = {
    create_at: number;
    id: string;
    locale: string;
    phone: string;
    phone_verified: number;
    props: UserPropsType;
    timezone: object;
    update_at: number;
};
