import React, {useMemo} from 'react';
import {Checkbox, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';

import CollapseItem from 'components/collapse/collapse_item';
import {AccessRuleType, CustomFieldConfigType, ModifyCollaborationDataMetaParams} from 'types/custom_field';
import {CUSTOM_FIELD_TYPES} from 'utils/constants/custom_field';

type Props = {
    title?: string
    editing: boolean
    collaborationData: ModifyCollaborationDataMetaParams
    onFieldsChange: (fields: CustomFieldConfigType[]) => void
    onDeleteAuthChange: (baseAccessRules: AccessRuleType[]) => void
};

type PrivateRole = {
    title: string
    memberRole: string
};

type PrivateRowData = {
    [key: string]: boolean | undefined | string
    id: string;
    name: string,
};

const deleteAuthRowId = 'delete';

const AuthContent: React.FC<Props> = ({
    title,
    editing,
    collaborationData,
    onFieldsChange,
    onDeleteAuthChange,
}) => {
    const {
        roles,
        baseAuth,
        baseAllowedCount,
        list,
        fieldAllowedCount,
        indexDictionary
    } = useMemo(() => {
        // 页面需要的渲染数据全部根据 collaborationData 生成
        const roles: PrivateRole[] = [];
        const baseAuth: PrivateRowData[] = [];
        const list: PrivateRowData[] = [];
        const indexDictionary: Record<string, number> = {};

        // 前端进行历史数据清理时使用
        const roleDictionary: Record<string, boolean | undefined> = {};

        collaborationData.fields.forEach((field, index) => {
            indexDictionary[field.id] = index;
            if (field.form_type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TYPE_STAFF && field.props?.member_role) {
                roles.push({title: field.name, memberRole: field.props.member_role});
                roleDictionary[field.props.member_role] = true;
            }
        });

        const calculateAuthValue = (
            dataItem: PrivateRowData,
            allowedCount: Record<string, number | undefined>,
            allowedIds?: string[]
        ) => {
            const countFunction = (allowedId: string) => {
                const count = allowedCount[allowedId];
                if (count) {
                    allowedCount[allowedId] = count + 1;
                } else {
                    allowedCount[allowedId] = 1;
                }
            };

            if (allowedIds) {
                allowedIds.forEach((allowedId) => {
                    if (roleDictionary[allowedId]) {
                        dataItem[allowedId] = true;
                        countFunction(allowedId);
                    }
                });
            } else {
                // allowed_ids 不存在时表示权限不受限
                roles.forEach(role => {
                    dataItem[role.memberRole] = true;
                    countFunction(role.memberRole);
                });
            }
        };

        const baseAllowedCount: Record<string, number> = {};
        const dataItem: PrivateRowData = {
            id: deleteAuthRowId,
            name: `删除${title}`,
        };

        calculateAuthValue(dataItem, baseAllowedCount, collaborationData.access_rules?.[0].allowed_ids);
        baseAuth.push(dataItem);

        const fieldAllowedCount: Record<string, number> = {};
        collaborationData.fields.forEach((field) => {
            if (field.systemic || (field.basic && field.access_rules_modifiable !== true)) {
                return;
            }
            const dataItem: PrivateRowData = {
                id: field.id,
                name: field.name
            };

            calculateAuthValue(dataItem, fieldAllowedCount, field.access_rules?.[0].allowed_ids);
            list.push(dataItem);
        });

        return {
            roles,
            baseAuth,
            baseAllowedCount,
            list,
            fieldAllowedCount,
            indexDictionary
        };
    }, [collaborationData, title]);

    const changeAllowedIds = (value: boolean, roleValue: string, allowedIds?: string[]) => {
        if (allowedIds) {
            const nextIds = new Set(allowedIds);
            if (value === true) {
                if (!nextIds.has(roleValue)) {
                    nextIds.add(roleValue);
                }
            } else {
                nextIds.delete(roleValue);
            }

            return [...nextIds];
        } else if (value === false) {
            // allowedIds 不存在时表示全选 -> 必是触发false的操作
            const nextAllowedIds: string[] = [];
            roles.forEach((role) => {
                if (role.memberRole !== roleValue) {
                    nextAllowedIds.push(role.memberRole);
                }
            });
            return nextAllowedIds;
        }
    };

    const changeFieldsAllowedIds = (value: boolean, roleValue: string, fieldIds: string[]) => {
        const nextFields = [...collaborationData.fields];
        fieldIds.forEach((fieldId) => {
            const fieldIndex = indexDictionary[fieldId];

            const originData = nextFields[fieldIndex].access_rules?.[0];
            if (originData) {
                const newAllowedIds = changeAllowedIds(value, roleValue, originData.allowed_ids);
                if (newAllowedIds) {
                    originData.allowed_ids = newAllowedIds;

                    nextFields[fieldIndex] = {
                        ...nextFields[fieldIndex],
                        access_rules: [originData]
                    };
                }
            } else {
                const newAllowedIds = changeAllowedIds(value, roleValue);
                nextFields[fieldIndex] = {
                    ...nextFields[fieldIndex],
                    access_rules: [{
                        type: 'MemberRole',
                        write: true,
                        allowed_ids: newAllowedIds,
                    }]
                };
            }
        });
        onFieldsChange(nextFields);
    };

    const onCheckedColumns = (value: boolean, roleValue: string, tableFlag?: 'base') => {
        // 注意 collaborationData 是个多层数据结构的数据，不要误改了某些层次的数据，出现bug比较难查
        if (tableFlag === 'base') {
            onCheckedItem(value, roleValue, deleteAuthRowId);
        } else {
            changeFieldsAllowedIds(value, roleValue, list.map((i => i.id)));
        }
    };

    const onCheckedItem = (value: boolean, roleValue: string, fieldId: string) => {
        // 注意 collaborationData 是个多层数据结构的数据，不要误改了某些层次的数据，出现bug比较难查
        if (fieldId === deleteAuthRowId) {
            const originData = collaborationData.access_rules?.[0];
            if (originData) {
                const newAllowedIds = changeAllowedIds(value, roleValue, originData.allowed_ids);
                if (newAllowedIds) {
                    onDeleteAuthChange([{
                        ...originData,
                        allowed_ids: newAllowedIds,
                    }]);
                }
            } else {
                const newAllowedIds = changeAllowedIds(value, roleValue);
                onDeleteAuthChange([{
                    type: 'MemberRole',
                    delete: true,
                    allowed_ids: newAllowedIds,
                }]);
            }
        } else {
            changeFieldsAllowedIds(value, roleValue, [fieldId]);
        }
    };

    const tableColumns = (
        rowCount: number,
        allowedCount: Record<string, number | undefined>,
        columnNames: string[],
        tableFlag?: 'base'
    ) => {
        const columns: ColumnsType<PrivateRowData> = [{
            title: columnNames[0] || '字段名称',
            dataIndex: 'name',
            key: 'name',
        }];
        roles.forEach((role) => {
            let headerChecked: boolean | undefined;
            let headerIndeterminate: boolean | undefined;

            if (allowedCount[role.memberRole]) {
                if (allowedCount[role.memberRole] === rowCount) {
                    headerChecked = true;
                } else {
                    headerIndeterminate = true;
                }
            }
            columns.push({
                title: (
                    <Checkbox
                        checked={headerChecked}
                        indeterminate={headerIndeterminate}
                        onChange={(e) => {
                            const value = e.target.checked;
                            onCheckedColumns(value, role.memberRole, tableFlag);
                        }}
                        disabled={!editing}
                        className='member-role-checkbox'
                    >{role.title}</Checkbox>
                ),
                dataIndex: role.memberRole,
                align: 'center',
                width: '95px',
                render: (value, record: PrivateRowData) => (
                    <Checkbox
                        checked={value}
                        onChange={(e) => {
                            const value = e.target.checked;
                            onCheckedItem(value, role.memberRole, record.id);
                        }}
                        disabled={!editing}
                    />
                ),
            });
        });
        return columns;
    };

    return (
        <React.Fragment>
            <CollapseItem
                title='功能权限'
                content={
                    <Table
                        className='properties-table'
                        bordered={false}
                        rowKey='id'
                        columns={tableColumns(1, baseAllowedCount, ['功能名称'], 'base')}
                        dataSource={baseAuth}
                        pagination={false}
                    />
                }
            />
            <CollapseItem
                title='修改数据权限'
                content={
                    <Table
                        className='properties-table'
                        bordered={false}
                        rowKey='id'
                        columns={tableColumns(list.length, fieldAllowedCount, ['列名称'])}
                        dataSource={list}
                        pagination={false}
                    />
                }
            />
        </React.Fragment>
    );
};

export default AuthContent;
