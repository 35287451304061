import React, {ReactNode, useEffect, useRef} from 'react';

import {Staff} from 'types/staffs';

import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
import PlusText from 'components/plus_text';
import {SelectItem, TypeOriginItem} from 'components/member_selects/member_select';

export interface SelectTriggerProps {
    holderComponent?: ReactNode, // holderComponent用作替换触发按钮的渲染，默认是一个‘+’ 和一个外圈
    extraText?: string, // extraText 用于对于触发按钮的补充说明, 优先级低于holderComponent

    title?: string, // Modal层标题
    onVisible?: (visible: boolean) => void, // 提供外部在开启/关闭时进行特殊处理

    singleChoice?: boolean, // 单选,多选
    departmentSelectable?: boolean, // 是否可以选择部门

    onSelected?: (members: Map<string, Staff>) => void, // 选中确认后回调, departmentSelectable 为 false 时使用
    originMemberIds?: string[], // 作为禁选项处理 departmentSelectable 为 false 时使用
    preprocessing?: boolean, // true 时显示加载, 此参数 departmentSelectable 为 false 时使用

    originItems?: TypeOriginItem[], // 作为禁选项处理 departmentSelectable 为 true 时使用
    onItemSelected?: (item: SelectItem[]) => void // 选中确认后回调, departmentSelectable 为 true 时使用
}

const SelectTrigger: React.FC<SelectTriggerProps> = (props) => {
    const {
        holderComponent,
        extraText,
        title,
        onVisible,
        singleChoice,
        departmentSelectable,
        onSelected,
        originMemberIds,
        preprocessing,
        originItems,
        onItemSelected,
    } = props;

    const visibleFlag = useRef(false);

    useEffect(() => {
        // 组件没有state，更新的触发主要来源于props，又由于其props基本透传给 'Modal', 所以这里判断Modal可见后将props重新emit
        if (visibleFlag.current === true) {
            triggerVisible();
        }
    });

    const triggerVisible = () => {
        if (departmentSelectable) {
            EventEmitter.emit(EventTypeEnum.OPEN_DEPARTMENT_MEMBER_SELECT_MODAL, {
                title, onVisible: visibleChanged, singleChoice, originItems, onSelected: onItemSelected,
            });
        } else {
            EventEmitter.emit(EventTypeEnum.OPEN_MEMBER_SELECT_MODAL, {
                title, onVisible: visibleChanged, singleChoice, originMemberIds, onSelected, preprocessing,
            });
        }
    };

    const visibleChanged = (visible: boolean) => {
        visibleFlag.current = visible;

        onVisible?.(visible);
    };

    const renderDefaultHolder = () => {
        return (
            <PlusText text={extraText}/>
        );
    };

    const holder = holderComponent || renderDefaultHolder();
    return (
        <div
            className='member_selector-trigger'
            onClick={triggerVisible}
        >
            {holder}
        </div>
    );
};

export default SelectTrigger;
