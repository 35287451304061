export const CUSTOM_FIELD_A_R_T_DEPARTMENT = 'Department';
export const CUSTOM_FIELD_A_R_T_STAFF = 'Staff';
export const CUSTOM_FIELD_A_R_T_ROLE = 'Role';

export enum CUSTOM_FIELD_TYPES {
    CUSTOM_FIELD_TYPE_NUMBER = 'Number',
    CUSTOM_FIELD_TYPE_TEXT = 'Text',
    CUSTOM_FIELD_TYPE_LONGTEXT = 'LongText',
    CUSTOM_FIELD_TYPE_SELECT = 'Select',
    CUSTOM_FIELD_TYPE_MULTIPLE_SELECT = 'MultiSelect',
    CUSTOM_FIELD_TYPE_FILES = 'Files',
    CUSTOM_FIELD_TYPE_DATE = 'Date',
    CUSTOM_FIELD_TYPE_STAFF = 'Staff',
    CUSTOM_FIELD_TYPE_STATUS = 'Status',
}

export const CUSTOM_FIELD_V_R_MAX_LENGTH = 'maxLength';
export const CUSTOM_FIELD_V_R_MIN_LENGTH = 'minLength';
export const CUSTOM_FIELD_V_R_MAX = 'max';
export const CUSTOM_FIELD_V_R_MIN = 'min';

export enum CUSTOM_FIELD_DATE {
    YEAR = 'YYYY',
    MONTH = 'YYYY/MM',
    DAY = 'YYYY/MM/DD',
    MINUTE = 'YYYY/MM/DD HH:mm',
    MM_DD = 'MM/DD',
    MM_DD_HH_MM = 'MM/DD HH:mm',
}

export const CUSTOM_FIELD_DATE_OPTIONS = [
    {label: '年', value: CUSTOM_FIELD_DATE.YEAR},
    {label: '年/月', value: CUSTOM_FIELD_DATE.MONTH},
    {label: '年/月/日', value: CUSTOM_FIELD_DATE.DAY},
    {label: '年/月/日 时:分', value: CUSTOM_FIELD_DATE.MINUTE},
    {label: '月/日', value: CUSTOM_FIELD_DATE.MM_DD},
    {label: '月/日 时:分', value: CUSTOM_FIELD_DATE.MM_DD_HH_MM},
];

export enum FIELD_MODULE {
    Projects = 'Projects',
    Tasks = 'Tasks'
}

export enum FIELD_TYPE {
    Project = "Project",
    Task = 'Task'
}

export const BASE_COLOR = [
    '#979797',
    '#FAD337',
    '#FFA503',
    '#F77234',
    '#FF3266',
    '#D91AD9',
    '#37BE71',
    '#0FC6C2',
    '#3AA0FF',
    '#0067FF',
    '#722ED1'
];
