import React, {ReactNode, useEffect, useState} from 'react';
import {Tree, TreeProps, Avatar} from 'antd';
import {DataNode, EventDataNode} from 'antd/lib/tree';

import Icon from 'components/icon';
import DepartmentInfo from 'components/organization_components/department_info';
import StaffInfo from 'components/organization_components/staff_info';
import {concatImagePrefix} from 'utils/image_utils';
import {DepartmentNode, EnterpriseInfo} from 'types/organization';
import {pickMemberIdsFromDepartment, isLeafDepartment} from 'utils/department';
import {Staff} from 'types/staffs';
import {DepartmentPath} from 'types/departments';

import {jointlyCheck, DEPARTMENT_KEY, getItemExisted, STAFF_KEY, independentlyCheck} from './tree_utils';

/**
 * TreeNode's key 定义规规则: '类型/Id标识/额外信息'
 * 普通部门  ${DEPARTMENT_KEY}/[departmentId]/[parentDepartmentId]-[departmentName]
 * 成员  ${STAFF_KEY}/[userId]/[parentDepartmentId]-[departmentRole]
 */
const {TreeNode} = Tree;

export interface OrganizationTreeProps {
    treeData?: DepartmentNode,
    staffs: {[key: string]: Staff | undefined},
    departmentFullPath: DepartmentPath,
    enterpriseInfo?: EnterpriseInfo,
    departmentSelectable?: boolean,
    checkable?: boolean,
    singleChoice?: boolean,
    itemIdsDisabled?: (string | number)[],
    itemIdsSelected?: (string | number)[],
    onSelect?: (selectedKey: string) => void,
    onItemSelected?: (param: {type: 'staff', id: string} | {type: 'department', id: number}, deleted: boolean) => void,
    loadStaffsIfNeeded: ((userIds: string[]) => Promise<{data?: any, error?: any}>),
}

const OrganizationTree: React.FC<OrganizationTreeProps> = (props): JSX.Element | null => {
    const {
        treeData,
        staffs,
        departmentFullPath,
        enterpriseInfo,
        loadStaffsIfNeeded,

        itemIdsDisabled,
        itemIdsSelected,
        departmentSelectable,
        checkable,
        singleChoice,
        onSelect,
        onItemSelected,
    } = props;
    const [checkedKeys, setCheckedKeys] = useState<{checked: string[], halfChecked: string[]} | undefined>();

    useEffect(() => {
        // 拉取根部门成员
        if (treeData) {
            const userIds = pickMemberIdsFromDepartment(treeData);
            loadStaffsIfNeeded(userIds);
        }
    }, [treeData, loadStaffsIfNeeded]);

    useEffect(() => {
        if (treeData && itemIdsSelected) {
            let calculateCheckedKeys;
            if (departmentSelectable) {
                calculateCheckedKeys = independentlyCheck(treeData, itemIdsSelected);
            } else {
                calculateCheckedKeys = jointlyCheck(treeData, itemIdsSelected as string[], itemIdsDisabled as string[]);
            }
            setCheckedKeys(calculateCheckedKeys);
        }
    }, [treeData, itemIdsSelected, itemIdsDisabled, departmentSelectable]);

    if (!treeData) {
        return null;
    }

    const getDepartmentData = (node: EventDataNode<DataNode>) => {
        const nodeKey = node.key as string;
        const departmentId = parseInt((nodeKey.split('/')[1]), 10);
        return departmentFullPath[departmentId];
    };

    const handleSelectMembers = (node: any) => {
        if (onItemSelected) {
            const nodeKey = node.key as string;
            const keySlices = nodeKey.split('/');
            const checked = checkedKeys ? checkedKeys.checked : [];
            const selected = checked.indexOf(nodeKey) > -1;
            if (keySlices[0] === DEPARTMENT_KEY) {
                onItemSelected({type: DEPARTMENT_KEY, id: parseInt(keySlices[1], 10)}, selected);
            } else if (keySlices[0] === STAFF_KEY) {
                onItemSelected({type: STAFF_KEY, id: keySlices[1]}, selected);
            }
        }
    };

    const handleSelectNode: TreeProps['onSelect'] = (selectedKeys, {node}) => {
        const selectedKey: string = node.key as string;
        if (checkable) {
            handleSelectMembers(node);
        } else if (onSelect) {
            onSelect(selectedKey);
        }
    };

    const handleCheck: TreeProps['onCheck'] = (checkedKeys, {node}) => {
        handleSelectMembers(node);
    };

    const onExpand: TreeProps['onExpand'] = (expandedKeys, {node}) => {
        const departmentData = getDepartmentData(node);
        if (departmentData) {
            const userIds = pickMemberIdsFromDepartment(departmentData.data);
            loadStaffsIfNeeded(userIds);
        }
    };

    // loadData在StrictMode模式下异步方法调用两次，借用onExpand进行数据拉取
    // https://github.com/ant-design/ant-design/issues/31683
    const loadData: TreeProps['loadData'] = () => {
        return Promise.resolve();
    };

    const renderManagerIdentification = () => {
        return (
            <span className='avatar-tag role-manager'>
                {'主管'}
            </span>
        );
    };

    const renderHrgIdentification = () => {
        return (
            <span className='avatar-tag role-hrg'>
                {'HRG'}
            </span>
        );
    };

    const renderTreeNodes = (node: DepartmentNode) => {
        const TreeNodes: ReactNode[] = [];

        // 渲染部门子部门节点
        if (node.children && node.children instanceof Array) {
            node.children.forEach((subDepartment) => {
                const leaf = isLeafDepartment(subDepartment);
                TreeNodes.push(
                    <TreeNode
                        className='single-department'
                        key={`${DEPARTMENT_KEY}/${subDepartment.id}/${node.id}-${subDepartment.name}`}
                        icon={<Icon type='bumenqianzhui'/>}
                        title={
                            <div className='tree-title-content'>
                                <DepartmentInfo departmentName={subDepartment.name}/>
                            </div>
                        }
                        disabled={getItemExisted(subDepartment.id, itemIdsDisabled)}
                        isLeaf={leaf}
                        checkable={singleChoice !== true}
                    >
                        {renderTreeNodes(subDepartment)}
                    </TreeNode>,
                );
            });
        }

        // 渲染部门成员节点（主管 + 成员）
        if (node.roles_map?.Manager) {
            const manager = staffs[node.roles_map.Manager];
            if (manager) {
                TreeNodes.push(
                    <TreeNode
                        className='single-staff single-normal-user-container'
                        key={`${STAFF_KEY}/${manager.user_id}/${node.id}-Manager`}
                        icon={
                            <span className='staff-avatar'>
                                <Avatar src={concatImagePrefix(manager.avatar?.small)}/>
                                {renderManagerIdentification()}
                            </span>
                        }
                        title={
                            <div className='tree-title-content'>
                                <div className='single-normal-user-info'>
                                    <div className='single-normal-user-name'><StaffInfo staff={manager}/></div>
                                    <div className='single-normal-user-extra'>{manager.duty}</div>
                                </div>
                            </div>
                        }
                        disabled={getItemExisted(manager.user_id, itemIdsDisabled)}
                        isLeaf={true}
                    />,
                );
            }
        }
        if (node.member_ids && node.member_ids.length) {
            node.member_ids.forEach((userId) => {
                const staff = staffs[userId];
                if (staff) {
                    let hrgIdentification;
                    if (staff.user_id === node.roles_map?.Hrg) {
                        hrgIdentification = renderHrgIdentification();
                    }
                    TreeNodes.push(
                        <TreeNode
                            className='single-staff single-normal-user-container'
                            key={`${STAFF_KEY}/${staff.user_id}/${node.id}-Member`}
                            icon={
                                <span className='staff-avatar'>
                                    <Avatar src={concatImagePrefix(staff.avatar?.small)}/>
                                    {hrgIdentification}
                                </span>
                            }
                            title={
                                <div className='tree-title-content'>
                                    <div className='single-normal-user-info'>
                                        <div className='single-normal-user-name'><StaffInfo staff={staff}/></div>
                                        <div className='single-normal-user-extra'>{staff.duty}</div>
                                    </div>
                                </div>
                            }
                            disabled={getItemExisted(staff.user_id, itemIdsDisabled)}
                            isLeaf={true}
                        />,
                    );
                }
            });
        }
        return TreeNodes;
    };

    return (
        <div className='organization-tree'>
            <Tree
                defaultExpandedKeys={[`${DEPARTMENT_KEY}/${treeData.id}/${treeData.parent_id}-${treeData.name}`]}
                blockNode={true}
                showIcon={true}
                checkedKeys={checkedKeys}
                checkStrictly={true}
                checkable={checkable}
                onSelect={handleSelectNode}
                onCheck={handleCheck}
                onExpand={onExpand}
                loadData={loadData}
            >
                <TreeNode
                    className='root-enterprise'
                    key={`${DEPARTMENT_KEY}/${treeData.id}/${treeData.parent_id}-${treeData.name}`}
                    icon={
                        <Avatar src={concatImagePrefix(enterpriseInfo?.logo.small)}/>
                    }
                    title={
                        <div className='tree-title-content'>
                            <DepartmentInfo departmentName={treeData.name}/>
                        </div>
                    }
                    disabled={getItemExisted(treeData.id, itemIdsDisabled)}

                    // Boolean是为将undefined处理为false
                    checkable={Boolean(departmentSelectable && singleChoice !== true)}
                >
                    {renderTreeNodes(treeData)}
                </TreeNode>
            </Tree>
        </div>
    );
};

OrganizationTree.displayName = 'PrueOrganizationTree';
export default OrganizationTree;
