import {DepartmentNode} from 'types/organization';

export function filterDepartment(targetId: number, nodes: (DepartmentNode | undefined)[]): DepartmentNode | undefined {
    const len = nodes.length;
    for (let i = 0; i < len; i++) {
        const departmentNode = nodes[i];
        if (departmentNode) {
            if (targetId === departmentNode.id) {
                return departmentNode;
            }
            if (departmentNode.children) {
                const result = filterDepartment(targetId, departmentNode.children);
                if (result) {
                    return result;
                }
            }
        }
    }
    return undefined;
}
