import React, {useCallback, useRef, useState} from 'react';
import {message} from 'antd';

import DepartmentInfo from 'components/organization_components/department_info';

import {loadDepartmentTree} from 'server/actions/organization';
import {loadSimpleStaffs} from 'server/actions/staffs';
import {DepartmentPath} from 'types/departments';
import {DepartmentNode} from 'types/organization';
import {Staff} from 'types/staffs';

const computeDepartmentList = (
    organizationData: DepartmentNode,
    departmentPath: DepartmentPath,
    path: React.ReactNode,
    userIds: Set<string>,
) => {
    let concatName: React.ReactNode;
    if (organizationData.parent_id === 0) {
        concatName = <DepartmentInfo departmentName={organizationData.name}/>;
    } else {
        concatName = (
            <React.Fragment>
                {path}
                {'-'}
                <DepartmentInfo departmentName={organizationData.name}/>
            </React.Fragment>
        );
    }
    Reflect.set(departmentPath, organizationData.id, {id: organizationData.id, fullpath: concatName, data: organizationData});

    if (organizationData.member_ids.length) {
        organizationData.member_ids.forEach((userId) => {
            userIds.add(userId);
        });
    }
    if (organizationData.roles_map?.Manager) {
        userIds.add(organizationData.roles_map.Manager);
    }
    if (organizationData.children && organizationData.children.length) {
        organizationData.children.forEach((department) => {
            computeDepartmentList(department, departmentPath, concatName, userIds);
        });
    }
};

export default function useOrganization() {
    const [organization, setOrganization] = useState<DepartmentNode>();

    // 钉钉环境内的特殊需求： [uxg5w87obpbffrwxagpbrxxqgh]排除“绩效应用外部”未授权用户 - 单独缓存一份“过滤”后的架构
    const [oExcludeNoIsv, setOExcludeNoIsv] = useState<DepartmentNode>();
    const [departmentFullPath, setDepartmentFullPath] = useState<DepartmentPath>({});
    const [totalUserIds, setTotalUserIds] = useState<string[]>([]);
    const [staffs, setStaffs] = useState<{[key: string]: Staff | undefined}>({});

    const loadingOrganization = useRef(false);
    const loadingOExcludeNoIsv = useRef(false);

    // 拉取组织架构树、同时计算出部门全路径、全员列表
    const loadOrganizationData = useCallback((onSuccess?: () => void, onFail?: () => void) => {
        if (!loadingOrganization.current) {
            loadingOrganization.current = true;
            loadDepartmentTree().then(({data, error}) => {
                if (error) {
                    message.error('团队架构信息获取失败');
                    if (onFail) {
                        onFail();
                    }
                } else if (data) {
                    setOrganization(data);
                    const computePath: DepartmentPath = {};
                    const userIds: Set<string> = new Set();

                    // 计算出部门全路径、全员列表 -> 授权管理需要使用到
                    computeDepartmentList(data, computePath, '', userIds);
                    setDepartmentFullPath(computePath);
                    setTotalUserIds([...userIds]);
                    if (onSuccess) {
                        onSuccess();
                    }
                }
                loadingOrganization.current = false;
            });
        }
    }, []);

    const loadOExcludeNoIsv = useCallback(() => {
        if (!loadingOExcludeNoIsv.current) {
            loadingOExcludeNoIsv.current = true;
            loadDepartmentTree(0, '?exclude_no_isv_auth=true').then(({data, error}) => {
                if (error) {
                    message.error('团队架构信息获取失败');
                } else if (data) {
                    setOExcludeNoIsv(data);
                }
                loadingOExcludeNoIsv.current = false;
            });
        }
    }, []);

    const loadStaffsIfNeeded = useCallback(async (userIds: string[]) => {
        if (!userIds || userIds.length === 0) {
            return {data: true};
        }
        const missingIds: string[] = [];
        userIds.forEach((userId) => {
            if (!staffs[userId]) {
                missingIds.push(userId);
            }
        });
        let response;
        if (missingIds.length) {
            response = await loadSimpleStaffs(missingIds);
            if (response.data) {
                const data = response.data;
                setStaffs((origin) => {
                    const newOnes = {...origin};
                    data.forEach((item) => {
                        newOnes[item.user_id] = item;
                    });
                    return newOnes;
                });
            }
        } else {
            return {data: true};
        }
        return response;
    }, [staffs]);

    return {
        organization,
        oExcludeNoIsv,
        departmentFullPath,
        totalUserIds,
        staffs,
        loadOrganizationData,
        loadOExcludeNoIsv,
        loadStaffsIfNeeded,
    };
}
