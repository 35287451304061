import React from 'react';

import {CallBackFunc} from 'types/general';

export interface SwitcherTab {
    key: string,
    label: string,
}

export interface SwitcherProps {
    tabs: SwitcherTab[],
    activeKey: string,
    onChange: (key: string) => void,
}

const Switcher: React.FC<SwitcherProps> = (props) => {
    const {tabs, activeKey, onChange} = props;

    return (
        <div className={'backend-switcher'}>
            {
                tabs.map((tab) => {
                    let className = 'tab';
                    let onClick: CallBackFunc | undefined;
                    if (tab.key === activeKey) {
                        className += ' tab-active';
                    } else {
                        onClick = () => onChange(tab.key);
                    }

                    return (
                        <div
                            className={className}
                            key={tab.key}
                            onClick={onClick}
                        >
                            {tab.label}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Switcher;
