export const BACKEND_MANAGEMENT = {
    ENTERPRISE_INFO: 'enterprise_info',
    ORGANIZATION_MANAGEMENT: 'organization_management',
    AUTHORISE_MANAGEMENT: 'members_management',
    ROLE_MANAGEMENT: 'role_management',
    ROLE_MANAGEMENT_WORK: 'role_management_work',
    ROLE_MANAGEMENT_PERF: 'role_management_perf',
    PROJECT_SETTINGS: 'project_settings',
    TASK_SETTINGS: 'task_settings',
    STAFF_SETTINGS: 'staff_settings',
};

export const BACKEND_MANAGEMENT_LABELS = {
    [BACKEND_MANAGEMENT.ENTERPRISE_INFO]: {name: '企业数据', icon: 'qiyequanyi'},
    [BACKEND_MANAGEMENT.ORGANIZATION_MANAGEMENT]: {name: '组织管理', icon: 'wodetuandui'},
    [BACKEND_MANAGEMENT.AUTHORISE_MANAGEMENT]: {name: '授权管理', icon: 'shouquanguanli'},
    [BACKEND_MANAGEMENT.ROLE_MANAGEMENT]: {name: '角色与权限', icon: 'wodejixiao'},
    [BACKEND_MANAGEMENT.ROLE_MANAGEMENT_WORK]: {name: '协作', icon: 'renwu'},
    [BACKEND_MANAGEMENT.ROLE_MANAGEMENT_PERF]: {name: '绩效', icon: 'jixiao'},
    [BACKEND_MANAGEMENT.PROJECT_SETTINGS]: {name: '项目类型', icon: 'xiangmuleixing'},
    [BACKEND_MANAGEMENT.TASK_SETTINGS]: {name: '任务类型', icon: 'renwuleixing'},
    [BACKEND_MANAGEMENT.STAFF_SETTINGS]: {name: '员工属性设置', icon: 'yuangongshuxingshezhi'},
};
