import React from 'react';

import {FIELD_MODULE} from 'utils/constants/custom_field';

import TypeDefinition from './components/type_definition';

const TaskProperty = () => {
    return (
        <TypeDefinition module={FIELD_MODULE.Tasks}/>
    );
};

export default TaskProperty;
