
import React, {useState, useEffect} from 'react';
import {Button, message} from 'antd';

import {loadEnterpriseAdmins} from 'server/actions/staffs';
import {Staff} from 'types/staffs';
import StaffInfo from 'components/organization_components/staff_info';

const AdminExists: React.FC = (): JSX.Element => {
    const [admins, setAdmins] = useState<Staff[]>();
    useEffect(() => {
        loadEnterpriseAdmins().then(({data, error}) => {
            if (error) {
                message.error('管理员信息获取失败');
            } else if (data) {
                setAdmins(data);
            }
        });
    }, []);

    const enterHome = () => {
        const url = localStorage.getItem('back_url');
        localStorage.removeItem('back_url');
        if (url) {
            window.location.replace(url);
        }
    };

    return (
        <div className='admin-setting-container'>
            <h1 className='admin-setting-title'>
                {'当前企业已设置管理员'}
                {admins?.map((admin) => (
                    <span key={admin.id}>
                        {'@'}
                        <StaffInfo staff={admin} nameOnly={true}/>
                    </span>
                ))}
                {'，快进入主页体验智办事绩效吧！'}
            </h1>
            <Button
                className='admin-setting-operation-btn'
                type='primary'
                onClick={enterHome}
            >{'进入主页'}</Button>
        </div>
    );
};

export default AdminExists;
