import {IAIClient} from 'server/client';
import {StatusOK} from 'server/types/client';
import {
    AuthorizationMenu,
    AuthorizationName,
    AuthorizationStatistic,
    StaffAuthorization,
    StaffAuthorizations,
} from 'types/authorise';

export async function loadAuthoriseStatistic(name: AuthorizationName | undefined, term: string | undefined) {
    let data: AuthorizationStatistic;
    if (name === undefined) {
        return {error: true};
    }

    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    try {
        data = await IAIClient.loadAuthoriseStatistic(enterpriseId, name, {term});
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadAuthoriseStaffs(name: AuthorizationName | undefined, params: any) {
    let data: StaffAuthorization[];
    if (name === undefined) {
        return {error: true};
    }

    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {data: undefined};
    }
    try {
        data = await IAIClient.loadAuthoriseStaffs(enterpriseId, name, params);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function turnOnAuthoriseStaffs(name: AuthorizationName | undefined, user_ids: string[]) {
    let data: StatusOK;
    if (name === undefined) {
        return {error: true};
    }

    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        data = await IAIClient.turnOnAuthoriseStaffs(enterpriseId, {name, user_ids});
    } catch (err) {
        // 暂时不知道这里类型断言怎么做
        const error: any = err;
        return {error};
    }
    return {data};
}

export async function turnOffAuthoriseStaffs(name: AuthorizationName | undefined, user_ids: string[]) {
    let data: StatusOK;
    if (name === undefined) {
        return {error: true};
    }

    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        data = await IAIClient.turnOffAuthoriseStaffs(enterpriseId, {name, user_ids});
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function queryAuthorizations(userIds: string[]) {
    let data: StaffAuthorizations[];
    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        data = await IAIClient.queryAuthorizations(enterpriseId, userIds);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadAuthorizationMenus() {
    let data: AuthorizationMenu[];
    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        data = await IAIClient.loadAuthorizationMenus(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function initEnterpriseAdmin(userId: string) {
    let data: StatusOK;
    const enterpriseId = IAIClient.getEnterpriseId();
    try {
        data = await IAIClient.initEnterpriseAdmin(enterpriseId, userId);
    } catch (error: any) {
        return {error};
    }

    return {data};
}
