import {EventEmitter2} from 'eventemitter2';

export const EventEmitter = new EventEmitter2();

export enum EventTypeEnum {
    OPEN_MEMBER_SELECT_MODAL = 'open_member_select_modal',
    OPEN_DEPARTMENT_MEMBER_SELECT_MODAL = 'open_department_member_select_modal',
    CLOSE_BACKEND = 'close_backend',
    DELETE_CONFIRM = 'delete_confirm',
}
