import React, {Dispatch, SetStateAction, useContext, useEffect, useState, useRef} from 'react';
import {Button, message} from 'antd';

import GlobalContext from 'context/global_context';
import Icon from 'components/icon';
import SelectTrigger from 'components/select_trigger/select_trigger';
import {AUTHORIZATION_TYPE_AUTHORISED, AUTHORIZATION_TYPE_TOBE} from 'utils/constants/authorise';
import {AuthorizationType, AuthorizationName} from 'types/authorise';
import {Staff} from 'types/staffs';
import {queryAuthorizations} from 'server/actions/authorise';
import {IAIClient} from 'server/client';

export interface BatchOperationProps {
    authorizationType: AuthorizationType,

    authorizedIds?: string[],
    unAuthorizedIds?: string[],
    setAuthorizedIds: Dispatch<SetStateAction<string[] | undefined>>,
    setUnAuthorizedIds: Dispatch<SetStateAction<string[] | undefined>>,
    handleAuthorizationChange: (members: Staff[], authorised: boolean) => void,
}

const BatchOperation: React.FC<BatchOperationProps> = (props): JSX.Element => {
    const {current: AuthName} = useRef(function() {
        let authName: AuthorizationName | '';
        switch (IAIClient.getAppType()) {
            case 'Performance':
                authName = 'Performance';
                break;
            case 'Teamwork':
                authName = 'Task';
                break;
            default:
                authName = '';
                break;
        }
        return authName;
    }());
    const {authorizedIds, unAuthorizedIds, setAuthorizedIds, setUnAuthorizedIds} = props;
    const [preprocessing, setPreprocessing] = useState(false);
    const {totalUserIds, loadOrganizationData} = useContext(GlobalContext);
    const [openFlag, setOpenFlag] = useState(false);

    useEffect(() => {
        if (totalUserIds.length === 0 || !authorizedIds) {
            setPreprocessing(true);
        } else {
            setPreprocessing(false);
        }
    }, [totalUserIds, authorizedIds]);

    // Todo此处对生命周期的应用比较混乱，有待优化
    useEffect(() => {
        const checkUsersAuthorization = () => {
            const idsGroup: string[][] = [];
            const copyIds = [...totalUserIds];
            while (copyIds.length > 0) {
                const group = copyIds.splice(0, 100);
                idsGroup.push(group);
            }

            const promises = idsGroup.map((ids) => {
                return queryAuthorizations(ids);
            });

            Promise.all(promises).then((responses) => {
                const authMap: {[user_id: string]: string[]} = {};
                for (const {data, error} of responses) {
                    if (error) {
                        message.error('加载失败');
                        return;
                    }

                    // 由于没有授权的用户Id不会返回staffAuthorizations，所以存在ids和data的长度不一致
                    data?.forEach((staffAuthorizations) => {
                        authMap[staffAuthorizations.user_id] = staffAuthorizations.names;
                    });
                }

                const authIds: string[] = [];
                const unAuthIds: string[] = [];
                totalUserIds.forEach((id) => {
                    if (authMap[id]?.indexOf(AuthName) > -1) {
                        authIds.push(id);
                    } else {
                        unAuthIds.push(id);
                    }
                });

                setAuthorizedIds(authIds);
                setUnAuthorizedIds(unAuthIds);
            });
        };

        if (openFlag) {
            if (totalUserIds.length > 0 && !authorizedIds) {
                checkUsersAuthorization();
            } else if (totalUserIds.length === 0) {
                /**
                 * 批量操作有一个比较特殊的preprocessing数据准备阶段，
                 * 且preprocessing会阻止架构树数据的拉取，所以此处主动拉取一次
                 */
                loadOrganizationData();
            }
        }
    }, [

        // 主要依靠openFlag标识（由用户触发）
        openFlag,

        // 其他值用作
        totalUserIds,
        loadOrganizationData,
        setAuthorizedIds,
        setUnAuthorizedIds,
        authorizedIds,
        AuthName,
    ]);

    const handleBatchAction = (members: Map<string, Staff>, authorised: boolean) => {
        const options = [...members.values()];
        props.handleAuthorizationChange(options, authorised);
    };

    const renderBatchAction = () => {
        const {authorizationType} = props;

        let disabledIds;
        let uDisabledIds;
        if (authorizedIds && unAuthorizedIds) {
            disabledIds = [
                ...authorizedIds,
            ];
            uDisabledIds = [
                ...unAuthorizedIds,
                IAIClient.getUserId(),
            ];
        }

        const batchOn = (
            <SelectTrigger
                key='on'
                preprocessing={preprocessing}
                onSelected={(members) => handleBatchAction(members, true)}
                holderComponent={
                    <Button
                        icon={<Icon type='unlock'/>}
                    >{'批量开启'}</Button>
                }
                originMemberIds={disabledIds}
                onVisible={setOpenFlag}
            />
        );
        const batchOff = (
            <SelectTrigger
                key='off'
                preprocessing={preprocessing}
                onSelected={(members) => handleBatchAction(members, false)}
                holderComponent={
                    <Button
                        icon={<Icon type='lock'/>}
                    >{'批量关闭'}</Button>
                }
                originMemberIds={uDisabledIds}
                onVisible={setOpenFlag}
            />
        );
        switch (authorizationType) {
            case AUTHORIZATION_TYPE_AUTHORISED:
                return batchOff;
            case AUTHORIZATION_TYPE_TOBE:
                return batchOn;
            default:
                return [batchOff, batchOn];
        }
    };

    return (
        <div className='members-management-header-operation'>
            {renderBatchAction()}
        </div>
    );
};

export default BatchOperation;
