import {useEffect} from 'react';
import {Button} from 'antd';
import {ready, biz} from 'dingtalk-jsapi';

import {loadDingtalkCustomerServiceConfig} from 'server/actions/platform';
import step1 from 'images/dingtalk/modify_organization1.png';
import step2 from 'images/dingtalk/modify_organization2.png';
import step3 from 'images/dingtalk/modify_organization3.png';
import {isDingtalkEnvironment} from 'utils/utils';

const DingtalkModifyGuide = (props) => {
    useEffect(() => {
        const wpkKey = '__wpk';
        const wpkScript = document.createElement('script');
        wpkScript.type = 'text/javascript';
        wpkScript.crossorigin = true;
        wpkScript.onload = () => {
            if (!window[wpkKey]) {
                window[wpkKey] = new window.wpkReporter({bid: 'dta_2_89309'});
            }
            window[wpkKey].installAll();
        };
        const n = document.getElementsByTagName('script')[0];
        n?.parentNode?.insertBefore(wpkScript, n);

        wpkScript.src = 'https://g.alicdn.com/woodpeckerx/jssdk??wpkReporter.js';
    }, []);

    const contactServices = () => {
        if (isDingtalkEnvironment()) {
            loadDingtalkCustomerServiceConfig().then(({data}) => {
                if (data) {
                    const {good_code, corp_id, service_conversation_token} = data;
                    if (good_code && corp_id && service_conversation_token) {
                        props.onClose();
                        const url = `https://page.dingtalk.com/wow/dingtalk/act/serviceconversation?wh_biz=tm&showmenu=false&goodsCode=${good_code}&corpId=${corp_id}&token=${service_conversation_token}`;
                        ready(() => {
                            biz.util.openModal({
                                url,
                            }).catch(() => {});
                        });
                    }
                }
            });
        }
    };

    return (
        <div className='platform-modify-organization-guide'>
            <div className='platform-modify-guide-content'>
                <p>{'1、“钉钉管理员”进入工作台，点击“管理”'}</p>
                <img
                    alt='step1'
                    src={step1}
                />
                <p>{'2、选择“应用管理 - 适用范围”'}</p>
                <img
                    alt='step2'
                    src={step2}
                />
                <p>{'3、选择“智办事绩效”，点击编辑'}</p>
                <img
                    alt='step3'
                    src={step3}
                />
            </div>
            <div className='platform-modify-guide-btns'>
                <Button onClick={props.onClose}>
                    {'我知道了'}
                </Button>
                <Button
                    type='primary'
                    onClick={contactServices}
                >
                    {'联系客服'}
                </Button>
            </div>
        </div>
    );
};

export default DingtalkModifyGuide;
