import {IAIClient} from 'server/client';
import {ModifyStaffDataMetaParams, ModifyCollaborationDataMetaParams} from 'types/custom_field';

export async function loadStaffDataMeta() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.loadStaffDataMeta(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function createStaffDataMeta(params: Omit<ModifyStaffDataMetaParams, 'version'>) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.createStaffDataMeta(enterpriseId, params);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function modifyStaffDataMeta(id: number, params: ModifyStaffDataMetaParams) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.modifyStaffDataMeta(enterpriseId, id, params);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadDefaultCollaboration() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.loadDefaultCollaboration(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadCollaborationDataMeta() {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.loadCollaborationDataMeta(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function createCollaborationDataMeta(params: Omit<ModifyCollaborationDataMetaParams, 'version'>) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.createCollaborationDataMeta(enterpriseId, params);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function modifyCollaborationDataMeta(id: number, params: ModifyCollaborationDataMetaParams) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.modifyCollaborationDataMeta(enterpriseId, id, params);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function deleteCollaborationDataMeta(id: number) {
    const enterpriseId = IAIClient.getEnterpriseId();
    if (!enterpriseId) {
        return {error: new Error()};
    }
    let data;
    try {
        data = await IAIClient.deleteCollaborationDataMeta(enterpriseId, id);
    } catch (error) {
        return {error};
    }

    return {data};
}
