import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {Avatar, message} from 'antd';

import {DepartmentNode} from 'types/organization';
import {Staff} from 'types/staffs';
import {filterDepartment} from 'utils/organization';
import {pickMemberIdsFromDepartment} from 'utils/department';
import {concatImagePrefix} from 'utils/image_utils';

import GlobalContext from 'context/global_context';

import Icon from 'components/icon';
import SelectTrigger from 'components/select_trigger/select_trigger';
import DepartmentInfo from 'components/organization_components/department_info';
import StaffInfo from 'components/organization_components/staff_info';
import {modifyDepartment, removeHrg} from 'server/actions/department';

export interface DepartmentViewProps {
    match: {
        params: {departmentId: string}
    }
}

const DepartmentView: React.FC<DepartmentViewProps> = (props) => {
    const {organization, loadOrganizationData, staffs, loadStaffsIfNeeded} = useContext(GlobalContext);
    const [currentDepartment, setCurrentDepartment] = useState<DepartmentNode | undefined>();
    useEffect(() => {
        const department = filterDepartment(parseInt(props.match.params.departmentId, 10), [organization]);
        if (department) {
            setCurrentDepartment(department);
            loadStaffsIfNeeded(pickMemberIdsFromDepartment(department));
        }
    }, [props.match.params.departmentId, organization]);

    if (!organization || !currentDepartment) {
        return null;
    }

    const managerId = currentDepartment.roles_map?.Manager;
    const hrgId = currentDepartment.roles_map?.Hrg;
    const manager = managerId && staffs[managerId];
    const hrg = hrgId && staffs[hrgId];

    const changeHrg = (membersMap: Map<string, Staff>) => {
        const member = [...membersMap.values()][0];

        modifyDepartment({hrg_id: member.user_id}, currentDepartment.id, 'single').then(({data, error}) => {
            if (error) {
                message.error('修改失败');
            } else {
                message.success('修改成功');
                loadOrganizationData();
            }
        });
    };

    const deleteHrg = () => {
        removeHrg(currentDepartment.id).then(({error}) => {
            if (error) {
                message.error('删除失败');
            } else {
                message.success('删除成功');
                loadOrganizationData();
            }
        });
    };

    const renderStaff = (staff: Staff | undefined, roleElement?: ReactNode, operations?: ReactNode) => {
        if (!staff) {
            return null;
        }

        return (
            <div
                key={staff.user_id}
                className='single-normal-user-container'
            >
                <span className='staff-avatar'>
                    <Avatar src={concatImagePrefix(staff.avatar?.small)}/>
                    {roleElement}
                </span>
                <div className='single_user-right-container'>
                    <span className='single-normal-user-info'>
                        <span className='single-normal-user-name'>
                            <StaffInfo staff={staff}/>
                        </span>
                        <div className='single-normal-user-extra'>{staff.duty}</div>
                    </span>
                    {operations}
                </div>
            </div>
        );
    };

    const renderMembers = () => {
        let managerDom: ReactNode;
        let hrgDom: ReactNode;
        const members: ReactNode[] = [];
        if (manager) {
            managerDom = renderStaff(
                manager,
                <span className={'avatar-tag role-manager'}>
                    {'主管'}
                </span>,
            );
            members.push(managerDom);
        }
        if (hrg) {
            hrgDom = renderStaff(
                hrg,
                <span className={'avatar-tag role-hrg'}>
                    {'HRG'}
                </span>,
                <span className='single_user-operation-container'>
                    <SelectTrigger
                        holderComponent={<span className='add-single_member'>{'修改'}</span>}
                        singleChoice={true}
                        originMemberIds={[hrg.user_id]}
                        onSelected={changeHrg}
                    />
                    <span
                        onClick={deleteHrg}
                        style={{
                            color: '#F54A45',
                            cursor: 'pointer',
                            marginLeft: 12,
                        }}
                    >{'删除'}</span>
                </span>,
            );
        }

        if (currentDepartment.member_ids && currentDepartment.member_ids.length) {
            currentDepartment.member_ids.forEach((userId) => {
                const member = staffs[userId];
                if (member) {
                    if (member.user_id === hrgId) {
                        members.push(renderStaff(
                            member,
                            <span className={'avatar-tag role-hrg'}>
                                {'HRG'}
                            </span>,
                        ));
                    } else {
                        members.push(renderStaff(member));
                    }
                }
            });
        }
        return {members, managerDom, hrgDom};
    };

    const renderDepartment = () => {
        const members = renderMembers();

        let managerRender;
        if (members.managerDom) {
            managerRender = (
                <div className='subordinate-list'>
                    {members.managerDom}
                </div>
            );
        } else {
            managerRender = (
                <p className='department-no_staff'>
                    {'暂无主管'}
                </p>
            );
        }

        let hrgRender;
        if (members.hrgDom) {
            hrgRender = (
                <div className='subordinate-list'>
                    {members.hrgDom}
                </div>
            );
        } else {
            const originMemberIds = [];
            if (hrgId) {
                originMemberIds.push(hrgId);
            }
            hrgRender = (
                <div className='department-no_staff'>
                    {'暂无人事专员'}
                    <SelectTrigger
                        holderComponent={<span className='add-single_member'>{'添加'}</span>}
                        singleChoice={true}
                        originMemberIds={originMemberIds}
                        onSelected={changeHrg}
                    />
                </div>
            );
        }

        return (
            <div className='department-info-container'>
                <div className='department-info-title'>
                    <DepartmentInfo departmentName={currentDepartment.name}/>
                </div>
                <div className='department-info-content'>
                    <div className='subordinate-info-block'>
                        <div className='subordinate-content-title'>
                            <Icon type='canyuren'/>
                            <span className='title-text'>{'部门主管'}</span>
                        </div>
                        {managerRender}
                    </div>
                    <div className='subordinate-info-block'>
                        <div className='subordinate-content-title'>
                            <Icon type='canyuren'/>
                            <span className='title-text'>{'人事专员'}</span>
                        </div>
                        {hrgRender}
                    </div>
                    <div className='subordinate-info-block'>
                        <div className='subordinate-content-title'>
                            <Icon type='canyuren'/>
                            <span className='title-text'>{`部门直属成员（${members.members.length}）`}</span>
                        </div>
                        {
                            members.members.length ? (
                                <div className='subordinate-list'>
                                    {members.members}
                                </div>
                            ) : <p className='department-no_staff'>{'暂无成员'}</p>
                        }
                    </div>
                </div>
            </div>
        );
    };

    return renderDepartment();
};

export default DepartmentView;
