import React, {useEffect, useContext, useState, useCallback} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import {Button} from 'antd';

import {Role} from 'types/roles';
import GlobalContext from 'context/global_context';
import Icon from 'components/icon';
import {BACKEND_MANAGEMENT, BACKEND_MANAGEMENT_LABELS} from 'utils/constants/permission';

import RoleController from './role_controller';
import RoleItem from './roles/role_item';
import EditRoleModal from './roles/edit_role_modal';
import LogsTrigger from './logs/logs_trigger';

export interface RoleManagementProps {
    match: {url: string};
    location: any;
}

const RoleKey = 'role';

const RoleManagement: React.FC<RoleManagementProps> = (props) => {
    const {enterpriseRoles, loadEnterpriseRoles} = useContext(GlobalContext);
    const history = useHistory();

    const {location} = props;
    const params = queryString.parse(location.search);
    const strRoleId = params[RoleKey];

    const [autoFocus, setAutoFocus] = useState<boolean>(!strRoleId);
    const [editId, setEditId] = useState<number>();
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (!enterpriseRoles) {
            loadEnterpriseRoles();
        }

        if (enterpriseRoles && enterpriseRoles.systemRoles && enterpriseRoles.systemRoles.length && autoFocus) {
            const id = enterpriseRoles.systemRoles[0].id;
            history.push(`${props.match.url}?${RoleKey}=${id}`);
            setAutoFocus(false);
        }
    }, [enterpriseRoles]);

    const handleEditRole = useCallback((roleId?: number) => {
        setEditId(roleId);
        setEditing(true);
    }, []);

    const handleCancelEdit = useCallback(() => {
        setEditing(false);
        setEditId(undefined);
    }, []);

    let activeRole: Role | undefined;
    if (strRoleId) {
        activeRole = enterpriseRoles?.rolesMap?.get(Number(strRoleId));
    }

    const renderRoleSettings = () => {
        if (!activeRole) {
            return null;
        }

        return (
            <RoleController
                role={activeRole}
            />
        );
    };

    const renderRoleItems = (roles: Array<Role> | undefined) => {
        return roles?.map((role) => {
            return (
                <NavLink
                    key={role.id}
                    to={`${props.match.url}?${RoleKey}=${role.id}`}
                    className='backend-menu_item second-menu-item'
                    isActive={() => activeRole?.id === role.id}
                >
                    <RoleItem
                        role={role}
                        handleEditRole={handleEditRole}
                    />
                </NavLink>
            );
        });
    };

    return (
        <div className='backend-role-management-container'>
            <div className='backend-role-management-header'>
                <span style={{color: '#1C212A', fontWeight: 500}}>{BACKEND_MANAGEMENT_LABELS[BACKEND_MANAGEMENT.ROLE_MANAGEMENT].name}</span>
                <LogsTrigger/>
            </div>
            <div className='backend-role-management-content'>
                <div className='second-menu-sidebar role-management_roles-sidebar'>
                    <div className='second-menu-list'>
                        <div className='backend-menu-item-group'>
                            <span>{'系统角色'}</span>
                        </div>
                        {renderRoleItems(enterpriseRoles?.systemRoles)}
                        <div className='backend-menu-item-group'>
                            <span>{'自定义角色'}</span>
                        </div>
                        {renderRoleItems(enterpriseRoles?.enterpriseRoles)}
                    </div>
                    <Button
                        className='add-new-btn'
                        onClick={() => handleEditRole()}
                    >
                        <Icon
                            type={'plus'}
                            size={16}
                        />
                        <span className={'message'}>{'添加角色'}</span>
                    </Button>
                </div>
                {renderRoleSettings()}
            </div>
            <EditRoleModal
                id={editId}
                visible={editing}
                onClose={handleCancelEdit}
            />
        </div>
    );
};

export default RoleManagement;
