import {IAIClient} from 'server/client';
import {StatusOK} from 'server/types/client';
import {Staff} from 'types/staffs';

export async function setDepartmentMain(userId: string, departmentId: number) {
    const enterpriseId = IAIClient.getEnterpriseId();
    let data: StatusOK;
    try {
        data = await IAIClient.setDepartmentMain(enterpriseId, userId, departmentId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadSimpleStaffs(userIds: string[]) {
    const enterpriseId = IAIClient.getEnterpriseId();
    let data: Staff[];
    try {
        data = await IAIClient.loadSimpleStaffs(enterpriseId, userIds);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function loadEnterpriseAdmins() {
    const enterpriseId = IAIClient.getEnterpriseId();
    let data: Staff[];
    try {
        data = await IAIClient.loadEnterpriseAdmins(enterpriseId);
    } catch (error) {
        return {error};
    }

    return {data};
}

export async function getStaffsByUserIds(staffIds: string[]) {
    let data: Staff[];
    try {
        data = await IAIClient.getStaffsByUserIds(staffIds);
    } catch (error) {
        return {error};
    }

    return {data};
}
