import React, {useContext, useEffect, useState} from 'react';
import {Checkbox, Tag} from 'antd';

import {CheckboxChangeEvent} from 'antd/lib/checkbox';

import Icon from 'components/icon';
import DepartmentInfo from 'components/organization_components/department_info';
import StaffInfo from 'components/organization_components/staff_info';
import {getFullname} from 'utils/staff_utils';
import GlobalContext from 'context/global_context';
import {highLightTerm} from 'utils/high_light';
import {RoleUserRange, SimpleRoleUserRange} from 'types/role_user_range';
import {RangeTypes} from 'utils/constants/general';
import SelectTrigger from 'components/select_trigger/select_trigger';
import {SelectItem, TypeOriginItem} from 'components/member_selects/member_select';

export interface MemberItemProps {
    memberId: string,
    onMemberRemove: (memberIds: string[]) => void,
    batchActive: boolean,
    checkedMemberIds: Set<string>,
    onItemChecked: (memberId: string, checked: boolean) => void,
    token: string,
    ranges: RoleUserRange[] | undefined,
    onRemoveRange: (memberId: string, range: RoleUserRange) => void,
    onAddRanges: (memberId: string, ranges: SimpleRoleUserRange[]) => void,
}

const MemberItem: React.FC<MemberItemProps> = (props) => {
    const [visible, setVisible] = useState<boolean>(true);

    const {memberId, onMemberRemove, batchActive, checkedMemberIds, onItemChecked, token, ranges, onRemoveRange, onAddRanges} = props;
    const {staffs, departmentFullPath} = useContext(GlobalContext);
    const member = staffs[memberId];

    useEffect(() => {
        if (token) {
            if (member) {
                const fullName = getFullname(member);
                const v = fullName.includes(token, 0);
                setVisible(v);
            } else {
                setVisible(false);
            }
        } else {
            setVisible(true);
        }
    }, [token, member]);

    if (!member) {
        return null;
    }

    if (!visible) {
        return null;
    }

    const handleAddRanges = (memberId: string, items: SelectItem[]) => {
        const data: SimpleRoleUserRange[] = [];

        for (const item of items) {
            data.push({
                range_type: item.type,
                range_id: item.id,
            });
        }

        onAddRanges(memberId, data);
    };

    const renderFullName = () => {
        if (token) {
            const fullName = getFullname(member);
            const term = new Set(token.split(''));
            return highLightTerm(fullName, term);
        }

        return <StaffInfo staff={member}/>;
    };

    const renderRanges = () => {
        const list = [];
        const originItems: TypeOriginItem[] = [];
        if (!ranges || ranges.length === 0) {
            list.push(
                <span key={'none'}>{'无'}</span>,
            );
        } else {
            for (const range of ranges) {
                let text;
                if (range.range_type === RangeTypes.Staff) {
                    const s = staffs[range.range_id];
                    if (s) {
                        text = (<StaffInfo
                            staff={s}
                            plain={true}
                        />);
                    }
                } else if (range.range_type === RangeTypes.Department) {
                    const departmentPath = departmentFullPath[range.range_id];
                    if (departmentPath) {
                        text = <DepartmentInfo departmentName={departmentPath.data.name}/>;
                    }
                }

                if (!text) {
                    continue;
                }

                list.push(
                    <Tag
                        key={`${range.id}`}
                        className={'tag'}
                        closable={true}
                        onClose={() => onRemoveRange(memberId, range)}
                    >
                        {text}
                    </Tag>,
                );

                originItems.push({
                    type: range.range_type,
                    id: range.range_id,
                    selected: true,
                    disabled: true,
                });
            }
        }

        list.push(
            <SelectTrigger
                key={'add'}
                holderComponent={
                    <span
                        className={'add'}
                    >
                        {'添加'}
                    </span>
                }
                departmentSelectable={true}
                originItems={originItems}
                onItemSelected={(items: SelectItem[]) => handleAddRanges(memberId, items)}
            />,
        );

        return list;
    };

    return (
        <div className={'member-table-item mx-16'}>
            <div className={'cell-member'}>
                {
                    batchActive ?
                        <Checkbox
                            className={'checked'}
                            checked={checkedMemberIds.has(memberId)}
                            onChange={(e: CheckboxChangeEvent) => onItemChecked(memberId, e.target.checked)}
                        /> : null
                }
                <span>{renderFullName()}</span>
            </div>
            <div className={'cell-ranges'}>
                {renderRanges()}
            </div>
            <div className={'cell-operation'}>
                <Icon
                    type={'delete'}
                    size={18}
                    color={'var(--danger-color)'}
                    title={'删除成员'}
                    onClick={() => onMemberRemove([memberId])}
                />
            </div>
        </div>
    );
};

export default MemberItem;
