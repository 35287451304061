import React from 'react';
import {Form, Switch} from 'antd';

import type {NormalRuleProps} from '../utils';

const MemberRule: React.FC<NormalRuleProps> = ({
    fieldConfig,
    onModify,
    disabled,
}) => {
    return (
        <React.Fragment>
            <Form.Item label='是否允许多人'>
                <Switch
                    checked={fieldConfig.props?.multiple}
                    disabled={fieldConfig.props?.multiple_modifiable === false || disabled}
                    onChange={(value) => {
                        onModify({
                            ...fieldConfig,
                            props: {
                                ...fieldConfig.props,
                                multiple: value,
                            },
                        });
                    }}
                />
            </Form.Item>
            <Form.Item label='是否接收消息'>
                <Switch
                    checked={fieldConfig.props?.messagable}
                    disabled={fieldConfig.basic || disabled}
                    onChange={(value) => {
                        onModify({
                            ...fieldConfig,
                            props: {
                                ...fieldConfig.props,
                                messagable: value,
                            },
                        });
                    }}
                />
            </Form.Item>
        </React.Fragment>
    );
};

export default MemberRule;
