import React, {useCallback, useState} from 'react';
import {Popover} from 'antd';

import Icon from 'components/icon';
import {EventEmitter, EventTypeEnum} from 'utils/constants/event_emitter';
import {FIELD_TYPE} from 'utils/constants/custom_field';
import type {CollaborationDataMeta} from 'types/custom_field';

type Props = {
    data: CollaborationDataMeta
    handleEdit: (id: number) => void
    handleDelete: (id: number) => void
};
const TypeItem: React.FC<Props> = ({
    data, handleEdit, handleDelete
}) => {
    const [operationVisible, setOperationVisible] = useState(false);

    const handleDeleteItem = useCallback((typeId: number, typeName: string) => {
        setOperationVisible(false);
        EventEmitter.emit(EventTypeEnum.DELETE_CONFIRM, {
            title: typeName,
            placeholder: typeName,
            successCallback: () => {
                handleDelete(typeId);
            },
        });
    }, [handleDelete]);


    const operationItemStyle: React.CSSProperties = {
        lineHeight: '32px',
        height: 32,
        width: 120,
        padding: '0 12px',
    };
    const iconSize = 16;

    let deleteOption: React.ReactNode = (
        <div
            className='dropdown-option-item'
            style={{
                ...operationItemStyle,
                color: '#FF3266',
            }}
            onClick={() => {
                handleDeleteItem(data.id, data.name);
            }}
        >
            <div className='dropdown-option-content'>
                <Icon
                    type='delete'
                    size={iconSize}
                    color='var(--danger-color)'
                />
                <span className='dropdown-option-label'>删除类型</span>
            </div>
        </div>
    );
    if (data.type === FIELD_TYPE.Project || data.type === FIELD_TYPE.Task) {
        deleteOption = null;
    }

    return (
        <React.Fragment>
            <Icon className='flex-none' type={data.icon} color='var(--gray-color)'/>
            <span className='second-menu-label'>{data.name}</span>
            <Popover
                overlayClassName='no-arrow-popover compact-popover'
                content={
                    <div onClick={(e) => e.stopPropagation()}>
                        <div
                            className='dropdown-option-item'
                            style={operationItemStyle}
                            onClick={() => {
                                setOperationVisible(false);
                                handleEdit(data.id);
                            }}
                        >
                            <div className='dropdown-option-content'>
                                <Icon
                                    type='edit'
                                    size={iconSize}
                                />
                                <span className='dropdown-option-label'>编辑类型</span>
                            </div>
                        </div>
                        {deleteOption}
                    </div>
                }
                trigger='click'
                open={operationVisible}
                onOpenChange={(visible) => setOperationVisible(visible)}
                placement='bottomLeft'
            >
                <span>
                    <Icon
                        className='flex-none'
                        type='gengduo1'
                    />
                </span>
            </Popover>
        </React.Fragment>
    );
};

export default TypeItem;
